import PoolApi from "@/services/api/Pool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const localStorage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        try {
            const url = pool.endpoints.wallet.replace('{address}', address);
            const response = await axios.get(url);
            
            if (response.data.error === undefined) {
                return this.parseWalletInfo(response);
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletInfo:', error);
            return { error: 'Failed to fetch wallet info' };
        }
    },

    async getStats(pool) {
        try {
            const url = pool.endpoints.stats;
            let response = await axios.get(url);
            response = await this.parseStats(response);
            response = await this.parseBlocks(pool, response);
            return await this.parsePayments(pool, response);
        } catch (error) {
            console.error('Error in getStats:', error);
            throw error;
        }
    },

    parseWalletInfo(response) {
        const stats = {};
        const workers = [];
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;

        Object.assign(stats, responseObj);

        stats.hashes = Format.getReadableHashRateString(responseObj.currentHashrate);
        stats.lastShare = Format.timeAgo(responseObj.stats.lastShare);
        stats.balance = Format.getReadableCoins(coin, responseObj.stats.balance, null, true, coin.coinUnits) + 
                       ' ' + PoolApi.getSelectedCoin();
        stats.paid = Format.getReadableCoins(coin, responseObj.stats.paid, null, true, coin.coinUnits) + 
                    ' ' + PoolApi.getSelectedCoin();

        // Parse workers
        if (responseObj.workers) {
            Object.entries(responseObj.workers).forEach(([key, parts]) => {
                workers.push({
                    name: key,
                    hashrate: Format.getReadableHashRateString(parts.hr),
                    diff: (parts.difficulty ? parts.difficulty / 1000000000 : 0) + 'b',
                    lastShare: Format.timeAgo(parts.lastBeat),
                    isSolo: 0,
                    status: false,
                });
            });
        }

        stats.workers = workers;

        // Parse rewards and payments
        const rewards = [];
        const payments = [];

        if (responseObj.sumrewards) {
            responseObj.sumrewards.slice(1).forEach(parts => {
                rewards.push({
                    time: parts.name,
                    hash: parts.offset,
                    amount: parts.reward.toLocaleString('fullwide', { useGrouping: false }) + 
                            ' ' + PoolApi.getSelectedCoin(),
                    fee: 0,
                    mixin: 0,
                    recipients: 1,
                    payees: 0,
                    rawtime: parseInt(parts.inverval)
                });
            });
        }

        if (responseObj.payments) {
            responseObj.payments.slice(1).forEach(parts => {
                const time = new Date(parts.timestamp * 1000);
                payments.push({
                    time: `${time.toDateString()}, ${time.toLocaleTimeString()}`,
                    hash: parts.tx,
                    amount: (parts.amount * 0.000000001).toFixed(8) + ' ' + PoolApi.getSelectedCoin(),
                    fee: 0,
                    mixin: 0,
                    recipients: 1,
                    payees: 0,
                    rawtime: parseInt(parts.timestamp)
                });
            });
        }

        stats.rewards = rewards;
        stats.payments = payments;

        return stats;
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;
        const hashrateHistory = [];

        if (responseObj.poolCharts) {
            responseObj.poolCharts.forEach(chart => {
                hashrateHistory.push(chart.y);
            });
        }

        stats.miners = responseObj.minersTotal;
        stats.soloMiners = 0;
        stats.workers = 0;
        stats.soloWorkers = 0;
        stats.poolHashRate = Format.getReadableHashRateString(responseObj.hashrate);
        stats.poolSoloHashRate = Format.getReadableHashRateString(0);
        stats.poolFee = coin.pool.fee;
        stats.poolMinimumPayout = coin.pool.minPayout;
        stats.networkDifficulty = responseObj.nodes[0].difficulty;
        stats.blockHeight = responseObj.nodes[0].height;
        stats.blockLastFound = parseInt(responseObj.nodes[0].lastBeat);
        stats.hashRateHistory = hashrateHistory;
        stats.blocks = [];
        stats.newblocks = [];
        stats.immature = [];
        stats.payments = [];
        stats.config = responseObj.config;
        stats.reward = 2;

        localStorage.set("config", stats.config);

        return stats;
    },

    async parseBlocks(pool, response) {
        try {
            const url = pool.endpoints.blocks;
            const blocks = await axios.get(url);
            const coin = PoolApi.coinConfig();
            
            response.blocks = this.parseBlocksString(blocks, response, coin, 'matured');
            response.immature = this.parseBlocksString(blocks, response, coin, 'immature');
            response.newblocks = this.parseBlocksString(blocks, response, coin, 'candidates');
            
            return response;
        } catch (error) {
            console.error('Error in parseBlocks:', error);
            throw error;
        }
    },

    _formatReward(amount, orphan, places) {
        if (!orphan) {
            const value = parseInt(amount) * 0.000000000000000001;
            return isNaN(value) ? 0 : value.toFixed(places);
        }
        return 0;
    },

    parseBlocksString(blocksResponse, response, coin, type) {
        const blocks = blocksResponse.data;
        const result = [];

        if (blocks[type]) {
            blocks[type].forEach(parts => {
                let reward = 0;
                
                switch (coin.name) {
                    case 'Zano':
                        reward = (parts.reward / (coin.coinUnits * coin.coinUnits)).toFixed(3);
                        break;
                    default:
                        reward = this._formatReward(parts.reward, parts.orphan, 8);
                }

                const block = {
                    height: parseInt(parts.height),
                    hash: parts.hash || '-',
                    time: Format.formatDate(parts.timestamp),
                    difficulty: parseInt(parts.difficulty),
                    shares: parseInt(parts.shares),
                    orphaned: parts.orphan,
                    reward,
                    luck: 0,
                    rawtime: parseInt(parts.timestamp),
                    status: parts.orphan ? 'orphaned' : 'pending'
                };

                if (block.reward > 0) {
                    block.reward = `${block.reward} ${PoolApi.getSelectedCoin()}`;
                }

                result.push(block);
            });

            result.sort((a, b) => b.rawtime - a.rawtime);
        }

        return result;
    },

    async parsePayments(pool, response) {
        try {
            const timestamp = Math.round(Date.now() / 1000);
            const paymentUrl = pool.endpoints.payments.replace('{time}', timestamp);
            const payments = await axios.get(paymentUrl);
            const coin = PoolApi.coinConfig();
            
            response.payments = this.parsePaymentsString(payments, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parsePayments:', error);
            throw error;
        }
    },

    parsePaymentsString(payments, response, coin) {
        const paymentsList = [];
        const responseObj = payments.data.payments;

        if (responseObj) {
            responseObj.forEach((parts, index) => {
                const payment = {
                    time: Format.formatDate(parseInt(parts.timestamp)),
                    hash: parts.tx,
                    amount: parts.amount.toLocaleString('fullwide', { useGrouping: false }),
                    fee: 0,
                    mixin: 0,
                    recipients: 1,
                    payees: parts.address,
                    address: parts.address,
                    rawtime: parseInt(parts.timestamp),
                    id: index
                };

                switch (coin.name) {
                    case 'Zano':
                        payment.amount = `${(payment.amount / coin.coinUnits).toFixed(3)} ${PoolApi.getSelectedCoin()}`;
                        payment.fee = `${this._formatReward(payment.fee, false, 11)} ${PoolApi.getSelectedCoin()}`;
                        break;
                    default:
                        payment.amount = `${(payment.amount * 0.000000001).toFixed(3)} ${PoolApi.getSelectedCoin()}`;
                        payment.fee = `${this._formatReward(payment.fee, false, 11)} ${PoolApi.getSelectedCoin()}`;
                }

                paymentsList.push(payment);
            });

            paymentsList.sort((a, b) => b.rawtime - a.rawtime);
        }

        return paymentsList;
    }
};