import PoolApi from "@/services/api/Pool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const localStorage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        try {
            const walletUrl = pool.endpoints.wallet.replace('{address}', address);
            const paymentsUrl = pool.endpoints.minerPayments.replace('{address}', address);

            const [walletResponse, paymentsResponse] = await Promise.all([
                axios.get(walletUrl),
                axios.get(paymentsUrl)
            ]);

            if (walletResponse.data.error === undefined) {
                const walletInfo = this.parseWalletInfo(walletResponse);
                const minerPayments = this.parsePaymentsString(paymentsResponse, walletResponse, PoolApi.coinConfig());
                walletInfo.payments = minerPayments;
                return walletInfo;
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletInfo:', error);
            return { error: 'Failed to fetch wallet info' };
        }
    },

    async getStats(pool) {
        try {
            const url = pool.endpoints.stats;
            let response = await axios.get(url);
            response = await this.parseStats(response);
            response = await this.parsePayments(pool, response);
            return await this.parseBlocks(pool, response);
        } catch (error) {
            console.error('Error in getStats:', error);
            throw error;
        }
    },

    parseWalletInfo(response) {
        const stats = {};
        const workers = [];
        const responseObj = response.data;

        stats.hashes = '';
        stats.lastShare = responseObj.pendingShares;
        stats.balance = `${responseObj.pendingBalance} ${PoolApi.getSelectedCoin()}`;
        stats.paid = `${responseObj.totalPaid} ${PoolApi.getSelectedCoin()}`;

        // Process performance workers
        if (responseObj.performance?.workers && Object.keys(responseObj.performance.workers).length > 0) {
            Object.entries(responseObj.performance.workers).forEach(([workerName, worker]) => {
                workers.push({
                    name: workerName,
                    hashrate: Format.formatHashrate(worker.hashrate),
                    lastShare: worker.sharesPerSecond,
                });
            });
        } 
        // Process performance samples
        else if (responseObj.performanceSamples?.length > 0) {
            const sortedSamples = [...responseObj.performanceSamples]
                .sort((a, b) => new Date(b.created) - new Date(a.created));
            
            const latestSample = sortedSamples[0];
            Object.entries(latestSample.workers).forEach(([workerName, worker]) => {
                workers.push({
                    name: workerName,
                    hashrate: Format.formatHashrate(worker.hashrate),
                    lastShare: `${worker.sharesPerSecond}S/s`,
                });
            });
        }

        stats.workers = workers;
        stats.payments = [];

        return stats;
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        const responseObj = response.data.pool;
        const hashrateHistory = [];

        stats.miners = responseObj.poolStats.connectedMiners;
        stats.soloMiners = 0;
        stats.workers = 0;
        stats.soloWorkers = 0;
        stats.poolHashRate = Format.getReadableHashRateString(responseObj.poolStats.poolHashrate);
        stats.poolSoloHashRate = 0;
        stats.poolFee = coin.pool.fee;
        stats.poolMinimumPayout = coin.pool.minPayout;
        stats.networkDifficulty = responseObj.networkStats.networkDifficulty;
        stats.networkHashrate = responseObj.networkStats.networkHashrate;
        stats.blockHeight = responseObj.networkStats.blockHeight;
        stats.blockLastFound = responseObj.networkStats.lastNetworkBlockTime;
        stats.hashRateHistory = hashrateHistory;
        stats.blocks = [];
        stats.payments = [];
        stats.config = responseObj;
        stats.reward = 0;

        localStorage.set("config", responseObj);

        return stats;
    },

    async parseBlocks(pool, response) {
        try {
            const blockUrl = pool.endpoints.blocks;
            const blocks = await axios.get(blockUrl);
            const coin = PoolApi.coinConfig();
            response.blocks = this.parseBlocksString(blocks, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parseBlocks:', error);
            throw error;
        }
    },

    parseBlocksString(blocks, response, coin) {
        const payments = [];
        const responseObj = blocks.data;
        const config = localStorage.get("config");

        if (responseObj) {
            responseObj.forEach((parts, index) => {
                const payment = {
                    time: Format.formatDate(parts.created),
                    hash: parts.hash,
                    height: parts.blockHeight,
                    amount: `${parts.reward} ${PoolApi.getSelectedCoin()}`,
                    effort: Format.formatEffort(parts.effort),
                    rawtime: index,
                    difficulty: parts.networkDifficulty,
                    shares: '',
                    orphaned: '',
                    reward: `${parts.reward.toLocaleString('fullwide', { useGrouping: false })} ${PoolApi.getSelectedCoin()}`,
                    luck: '',
                    status: parts.status
                };

                payment.fee = `${payment.fee ? Format.getReadableCoins(coin, payment.fee, null, true, config.coinUnits) : 0} ${PoolApi.getSelectedCoin()}`;
                payments.push(payment);
            });

            payments.sort((a, b) => (a.rawtime > b.rawtime ? 1 : -1));
        }

        return payments;
    },

    async parsePayments(pool, response) {
        try {
            const timestamp = Math.round(Date.now() / 1000);
            const paymentUrl = pool.endpoints.payments.replace('{time}', timestamp);
            const payments = await axios.get(paymentUrl);
            const coin = PoolApi.coinConfig();
            
            response.payments = this.parsePaymentsString(payments, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parsePayments:', error);
            throw error;
        }
    },

    parsePaymentsString(payments, response, coin) {
        const paymentsList = [];
        const responseObj = payments.data;

        if (responseObj) {
            responseObj.forEach((parts, index) => {
                const payment = {
                    time: Format.formatDate(parts.created),
                    hash: parts.transactionConfirmationData,
                    amount: `${parts.amount.toLocaleString('fullwide', { useGrouping: false })} ${PoolApi.getSelectedCoin()}`,
                    fee: coin.pool.fee,
                    mixin: '',
                    recipients: parts.address,
                    payees: '',
                    rawtime: index,
                    id: index
                };

                paymentsList.push(payment);
            });

            paymentsList.sort((a, b) => (a.rawtime > b.rawtime ? 1 : -1));
        }

        return paymentsList;
    }
};