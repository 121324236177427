export function useStorage() {
    return {
        get: (key, defaultValue = null) => {
            try {
                const value = window.localStorage.getItem(key);
                return value !== null ? JSON.parse(value) : defaultValue;
            } catch {
                return defaultValue;
            }
        },
        set: (key, value) => {
            try {
                window.localStorage.setItem(key, JSON.stringify(value));
                return true;
            } catch {
                return false;
            }
        }
    };
}