<template>
  <div class="chart-container">
    <div v-if="loading" class="chart-loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="chart-header">
        <h3 class="chart-title">{{ title }}</h3>
        <div class="chart-controls">
          <button 
            v-for="type in chartTypes" 
            :key="type.value" 
            class="btn btn-sm" 
            :class="selectedChartType === type.value ? 'btn-primary' : 'btn-outline-secondary'"
            @click="changeChartType(type.value)"
          >
            {{ type.label }}
          </button>
        </div>
      </div>
      <component 
        :is="chartComponent" 
        :chart-data="chartData" 
        :chart-options="chartOptions"
        :height="300"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { LineChart, BarChart, DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import Format from '@/utils/format';

// Register Chart.js components
Chart.register(...registerables);

// Props
const props = defineProps({
  title: {
    type: String,
    default: 'Worker Statistics'
  },
  workers: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  colorPalette: {
    type: Array,
    default: () => [
      '#206bc4', '#4299e1', '#38b2ac', '#48bb78', 
      '#f59e0b', '#ed8936', '#e53e3e', '#805ad5', 
      '#d53f8c', '#667eea', '#84cc16', '#14b8a6'
    ]
  }
});

// State
const selectedChartType = ref('hashrate');

// Chart type options
const chartTypes = [
  { label: 'Hashrate', value: 'hashrate' },
  { label: 'Shares', value: 'shares' },
  { label: 'Distribution', value: 'distribution' }
];

// Computed chart component based on selected type
const chartComponent = computed(() => {
  if (selectedChartType.value === 'distribution') {
    return DoughnutChart;
  } else if (selectedChartType.value === 'shares') {
    return BarChart;
  } else {
    return BarChart;
  }
});

// Chart options
const chartOptions = computed(() => {
  const baseOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: selectedChartType.value === 'distribution',
        position: 'right'
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            if (selectedChartType.value === 'hashrate') {
              return `${context.label}: ${Format.getReadableHashRateString(context.parsed.y)}`;
            } else if (selectedChartType.value === 'shares') {
              return `${context.label}: ${context.parsed.y} shares`;
            } else {
              return `${context.label}: ${context.parsed.y}%`;
            }
          }
        }
      }
    }
  };

  if (selectedChartType.value !== 'distribution') {
    return {
      ...baseOptions,
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value) => {
              if (selectedChartType.value === 'hashrate') {
                return Format.getReadableHashRateString(value);
              }
              return value;
            }
          },
          grid: {
            color: 'rgba(200, 200, 200, 0.1)'
          }
        }
      }
    };
  }

  return baseOptions;
});

// Computed chart data
const chartData = computed(() => {
  const workers = props.workers || [];
  console.log('Worker data in chart component:', workers);
  
  if (workers.length === 0) {
    return {
      labels: ['No Data'],
      datasets: [{
        data: [1],
        backgroundColor: ['#e2e8f0']
      }]
    };
  }

  if (selectedChartType.value === 'distribution') {
    // For distribution chart (doughnut)
    const totalHashrate = workers.reduce((sum, worker) => sum + (worker.hashrate || 0), 0);
    
    return {
      labels: workers.map(worker => worker.name || 'Unknown'),
      datasets: [{
        data: workers.map(worker => {
          const percentage = totalHashrate > 0 
            ? ((worker.hashrate || 0) / totalHashrate * 100).toFixed(1) 
            : 0;
          return parseFloat(percentage);
        }),
        backgroundColor: workers.map((_, index) => 
          props.colorPalette[index % props.colorPalette.length]
        ),
        borderWidth: 1
      }]
    };
  } else if (selectedChartType.value === 'shares') {
    // For shares chart (bar)
    console.log('Rendering shares chart with data:', workers);
    return {
      labels: workers.map(worker => worker.name || 'Unknown'),
      datasets: [{
        label: 'Valid Shares',
        data: workers.map(worker => {
          console.log(`Worker ${worker.name} valid shares:`, worker.validShares);
          return worker.validShares || 0;
        }),
        backgroundColor: '#48bb78',
        borderColor: '#38a169',
        borderWidth: 1
      }, {
        label: 'Invalid Shares',
        data: workers.map(worker => {
          console.log(`Worker ${worker.name} invalid shares:`, worker.invalidShares);
          return worker.invalidShares || 0;
        }),
        backgroundColor: '#e53e3e',
        borderColor: '#c53030',
        borderWidth: 1
      }]
    };
  } else {
    // For hashrate chart (bar)
    return {
      labels: workers.map(worker => worker.name || 'Unknown'),
      datasets: [{
        label: 'Hashrate',
        data: workers.map(worker => {
          console.log(`Worker ${worker.name} hashrate:`, worker.hashrate);
          return worker.hashrate || 0;
        }),
        backgroundColor: workers.map((_, index) => 
          props.colorPalette[index % props.colorPalette.length]
        ),
        borderColor: workers.map((_, index) => 
          props.colorPalette[index % props.colorPalette.length]
        ),
        borderWidth: 1
      }]
    };
  }
});

// Methods
const changeChartType = (type) => {
  selectedChartType.value = type;
};

// Watch for changes in workers prop
watch(() => props.workers, (newWorkers) => {
  console.log('Workers prop changed:', newWorkers);
}, { deep: true });

// Watch for changes in selected chart type
watch(() => selectedChartType.value, (newType) => {
  console.log('Chart type changed to:', newType);
});
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 350px;
  padding: 10px;
}

.chart-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.chart-title {
  font-size: 1.2rem;
  margin: 0;
  color: #206bc4;
}

.chart-controls {
  display: flex;
  gap: 5px;
}

.chart-controls .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}
</style> 