<template>
  <page title="">
    <div class="d-flex align-items-center mb-3">
      <Box class="me-2" /> <!-- Lucide Icon -->
      <h1 class="page-title">Pool blocks</h1>
    </div>
    
    <!-- Add Blocks Chart -->
    <div class="col-sm-12 col-lg-12 mb-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <span>Blocks Found</span>
          </h3>
        </div>
        <div class="card-body">
          <BlocksChart
            :data-fetch-function="fetchBlocksData"
            :loading="loading"
          />
        </div>
      </div>
    </div>
    
    <div v-if="coinConfig.type == 'et'" class="col-sm-12 col-lg-12 mb-3">
      <form>
        <div class="mb-3">
          <label @change="switchBlock" class="form-check form-check-inline">
            <input
              v-model="type"
              class="form-check-input"
              name="type"
              type="radio"
              value="blocks"
            />
            <span class="form-check-label">Blocks</span>
          </label>
          <label @change="switchBlock" class="form-check form-check-inline">
            <input
              class="form-check-input"
              v-model="type"
              name="type"
              type="radio"
              value="immature"
            />
            <span class="form-check-label">Immature</span>
          </label>
          <label @change="switchBlock" class="form-check form-check-inline">
            <input
              class="form-check-input"
              v-model="type"
              name="type"
              type="radio"
              value="newblocks"
            />
            <span class="form-check-label">New blocks</span>
          </label>
        </div>
      </form>
    </div>

    <div class="col-sm-12 col-lg-12 mb-3">
      <card>
        <div class="table-responsive">
          <t-table class="table card-table">
            <table-head>
              <table-row>
                <table-cel :header="true">Time Found</table-cel>
                <table-cel :header="true">Reward</table-cel>
                <table-cel :header="true">Height</table-cel>
                <table-cel :header="true">Block Hash</table-cel>
                <table-cel :header="true">Difficulty</table-cel>
                <table-cel :header="true">Effort</table-cel>
                <table-cel
                  title="How many more blocks network must mine before this block is matured"
                  :header="true"
                  v-if="coinConfig.type != 'et'"
                >
                  Status
                </table-cel>
              </table-row>
            </table-head>
            <table-body>
              <table-row
                :key="block.hash"
                v-for="block in blocks"
                :class="block.ourBlock ? 'bg-yellow-lt' : ''"
              >
                <table-cel>
                  {{ block.time }}
                </table-cel>

                <table-cel v-if="coinConfig.type != 'et'">
                  <span v-if="block.reward">
                    {{ block.reward }}
                  </span>
                  <span v-else>Waiting...</span>
                </table-cel>
                <table-cel v-else>
                  <span v-if="block.reward">
                    {{ block.reward }}
                  </span>
                  <span v-else>
                    <span v-if="type != 'newblocks'">Orphan</span>
                    <span v-else>Waiting...</span>
                  </span>
                </table-cel>

                <table-cel>
                  {{ block.height }}
                </table-cel>
                <table-cel>
                  <div v-if="coinConfig.explorerLinks">
                    <a :href="blockUrl(coinConfig, block)" target="_blank">
                      {{ block.hash }}
                    </a>
                  </div>
                  <div v-else>
                    {{ block.hash }}
                  </div>

                  <div class="text-muted" v-if="block.minerAddress">
                    mined by: {{ block.minerAddress }}
                  </div>
                </table-cel>
                <table-cel>
                  {{ block.difficulty }}
                </table-cel>

                <table-cel>
                  <span class="text-muted" v-if="block.effort || block.solo">
                    {{ block.effort }} %
                  </span>
                  <span v-if="block.solo" title="Solo mined">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M11 21l1 -5l-1 -4l-3 -4h4l3 -3"></path>
                      <path d="M6 16l-1 -4l3 -4"></path>
                      <circle cx="6" cy="5" r="1"></circle>
                      <path d="M13.5 12h2.5l4 2"></path>
                    </svg>
                  </span>
                </table-cel>
                <table-cel v-if="coinConfig.type != 'et'">
                  <small>
                    {{ block.status }}
                  </small>
                </table-cel>
              </table-row>
            </table-body>
          </t-table>
        </div>

        <Loader v-bind:loading="loading" />
      </card>
    </div>
  </page>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue'
import { useRoute } from 'vue-router'
import PoolConfig from "@/config/PoolConfig"
import PoolApi from "@/services/api/Pool"
import Loader from '@/components/sections/Loader.vue'
import Format from "@/utils/format"
import BlocksChart from '@/components/charts/BlocksChart.vue'
import { Box } from 'lucide-vue-next'

const route = useRoute()

// State
const type = ref("blocks")
const blocks = ref([])
const poolStats = ref({})
const coinConfig = ref(PoolApi.coinConfig())
const loading = ref(false)
let intervalId = null // Store the interval ID for cleanup

// Methods
const blockUrl = (coinConfig, block) => {
  let url = coinConfig.explorerLinks.block
  if (url.indexOf("{blockHeight}") !== -1) {
    return url.replace("{blockHeight}", block.height)
  }
  return url + block.hash
}

const switchBlock = (e) => {
  e.preventDefault()
  blocks.value = poolStats.value[type.value]
}

const fetchData = () => {
  loading.value = true
  PoolApi.callStats(
    (response) => {
      poolStats.value = response
      blocks.value = poolStats.value[type.value]
      loading.value = false
    },
    () => {
      loading.value = false
    }
  )
}

// Function to fetch blocks data for the chart
const fetchBlocksData = async (period = '24h') => {
  try {
    const selectedCoin = PoolApi.getSelectedCoin();
    if (!selectedCoin) {
      console.error("Selected coin is not defined in localStorage.");
      return [];
    }
    
    // Use existing blocks data if available
    if (blocks.value && blocks.value.length > 0) {
      return blocks.value.map(block => ({
        time: block.rawtime || Math.floor(new Date(block.time).getTime() / 1000),
        confirmed: block.status === 'confirmed' || block.status === 'unlocked',
        reward: block.reward
      }));
    }
    
    // Otherwise fetch new data
    loading.value = true;
    const response = await PoolApi.getBlocks(selectedCoin);
    loading.value = false;
    
    if (response && response.length > 0) {
      return response.map(block => ({
        time: block.rawtime || Math.floor(new Date(block.time).getTime() / 1000),
        confirmed: block.status === 'confirmed' || block.status === 'unlocked',
        reward: block.reward
      }));
    }
    
    return [];
  } catch (error) {
    console.error('Error fetching blocks data:', error);
    loading.value = false;
    return [];
  }
};

// Watch route changes
watch(() => route, fetchData)

// Initial fetch and periodic refresh
onMounted(() => {
  fetchData()
  intervalId = setInterval(fetchData, 60000) // Fetch data every 1 minute (60000ms)
})

// Cleanup interval on component unmount
onBeforeUnmount(() => {
  clearInterval(intervalId)
})
</script>