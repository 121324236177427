import mitt from 'mitt';

const emitter = mitt();

export function useEmitter() {
    const emit = (event, data) => {
        emitter.emit(event, data);
    };

    const on = (event, callback) => {
        emitter.on(event, callback);
    };

    const off = (event, callback) => {
        emitter.off(event, callback);
    };

    return {
        emit,
        on,
        off
    };
}