<template>
  <page title="How to mine">
    <div class="row row-deck row-cards">
      <div class="col-sm-12 col-lg-12">
        <MinerInfo></MinerInfo>
      </div>
      <div class="col-sm-12 col-lg-12">
        <Faq />
      </div>
    </div>
  </page>
</template>

<script>
import MinerInfo from "@/components/sections/MinerInfo.vue";
import Faq from "@/components/sections/Faq.vue";

export default {
  name: "howToMine",
  components: {
    MinerInfo,
    Faq,
  },
};
</script>