<template>
  <page title="Your stats">
    <div class="row">
      <div
        class="col-sm-12 col-lg-12 mb-3"
        v-if="coinConfig.endpoints.settings"
      >
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <span>Set your minimal payout level</span>
                </h3>
              </div>
              <div class="card-body">
                <form class="" v-on:submit="payout">
                  <div class="mb-2 text-muted">
                    In order to get a little more confidence that the wallet
                    address is yours we ask you to give one of the IP addresses
                    that is used by your miner.
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <span tkey="pendingBalance">Miner Address</span>:
                    </div>
                    <div class="col-10">
                      <input
                        class="form-control"
                        id="yourMinerAddress"
                        type="text"
                        tplaceholder="enterYourAddress"
                        placeholder="Enter Your Miner Address"
                        v-model="minerAddress"
                      />
                    </div>

                    <div class="col-2">
                      <span tkey="totalPaid">Miner IP address</span>:
                    </div>
                    <div class="col-10">
                      <input
                        class="form-control"
                        id="yourMinerIp"
                        type="text"
                        tplaceholder="enterYourIp"
                        placeholder="Enter Your Miner IP"
                        v-model="minerIp"
                      />
                    </div>

                    <div class="mt-2 mb-2 text-muted">
                      If you prefer a higher payout level than the pool's
                      default then this is where you can change it for your
                      miners. The amount you indicate here will become the
                      minimum amount for pool payments to your address.
                    </div>

                    <div class="col-2">
                      <span tkey="totalPaid">Minimum payout</span>:
                    </div>
                    <div class="col-10">
                      <input
                        class="form-control"
                        id="yourMinerPayout"
                        type="text"
                        tplaceholder="enterYourPayout"
                        placeholder="Enter Your Minimum Payout"
                        v-model="minerPayout"
                      />
                    </div>

                    <div class="col-2"></div>
                    <div class="col-10">
                      <button class="btn" type="submit" id="set">
                        <span> <span tkey="set">Set</span></span>
                      </button>
                    </div>

                    <div class="col-2"></div>
                    <div class="col-10 text-warning">
                      {{ walletSetting.status }}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-lg-12">
        <div id="workerStats">
          <h2 class="mb-3">Worker stats & Payment history</h2>
          <div class="row mb-3">
            <div class="col-xl-12">
              <form class="input-group" v-on:submit="search">
                <input
                  class="form-control"
                  id="yourStatsInput"
                  type="text"
                  tplaceholder="enterYourAddress"
                  placeholder="Enter Your Address"
                  v-model="address"
                />
                <span class="input-group-btn"
                  ><button class="btn" type="submit" id="lookUp">
                    <span> <span tkey="lookup">Lookup</span></span>
                  </button></span
                >
              </form>
              <div id="addressError"></div>
            </div>
          </div>

          <Loader v-bind:loading="loading" />
        </div>

        <div v-if="walletInfo.error">
          <div class="text-danger text-center">
            Not found
            <br />
            <small class="text-gray"
              >If you are looking for your account stats, you need to submit at
              least a single share.</small
            >
          </div>
        </div>
      </div>

      <div v-if="results && walletInfo.error === undefined">
        <div class="col-sm-12 col-lg-12 mb-1">
          <!-- Add Worker Stats Chart -->
          <div class="row">
            <div class="col-sm-12 col-lg-12 mb-3">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    <span>Worker Statistics</span>
                  </h3>
                </div>
                <div class="card-body">
                  <WorkerStatsChart
                    :workers="formattedWorkers"
                    :loading="loading"
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-sm-6 col-lg-6 mb-3" v-if="walletInfo.hashes">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    <span tkey="hashRate">Hash rate</span>
                  </h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <span tkey="currentHashRate">Current hash rate</span>:
                    </div>
                    <div class="col-7">
                      <span id="yourHashrateHolder">{{
                        walletInfo.hashes
                      }}</span>
                    </div>
                    <div class="col-5">
                      <span tkey="lastShare">Last share submitted</span>:
                    </div>
                    <div class="col-7">
                      <span id="yourHashrateHolder">{{
                        walletInfo.lastShare
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-6 mb-3" v-if="walletInfo.balance">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    <span tkey="payments">Payments</span>
                  </h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <span tkey="pendingBalance">Pending balance</span>:
                    </div>
                    <div class="col-7">
                      <span id="yourHashrateHolder">{{
                        walletInfo.balance
                      }}</span>
                    </div>
                    <div class="col-5">
                      <span tkey="totalPaid">Total earned</span>:
                    </div>
                    <div class="col-7">
                      <span id="yourHashrateHolder">{{ walletInfo.paid }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-lg-6 mb-3" v-if="walletInfo.payments">
              <div class="box" style="">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">
                      <span tkey="paymentsHistory">Payments history</span>
                    </h3>
                  </div>
                  <div id="workerPayments" class="table-responsive">
                    <table class="table card-table undefined">
                      <thead>
                        <tr>
                          <th class="col1">
                            <span tkey="timeSent">Time Sent</span>
                          </th>
                          <th class="col2">
                            <span tkey="transactionHash">Transaction Hash</span>
                          </th>
                          <th class="col3">
                            <span tkey="amount">Amount</span>
                          </th>
                          <th class="col4" v-if="coinConfig.type != 'et'">
                            <span tkey="mixin">Mixin</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <table-row
                          :key="block.name"
                          v-for="block in walletInfo.payments"
                        >
                          <table-cel>
                            {{ block.time }}
                          </table-cel>
                          <table-cel>
                            <div v-if="coinConfig.explorerLinks">
                              <a
                                :href="coinConfig.explorerLinks.tx + block.hash"
                                target="_blank"
                              >
                                {{ block.hash.substring(0, 8) }}...{{
                                  block.hash.substring(
                                    block.hash.length - 8,
                                    block.hash.length
                                  )
                                }}
                              </a>
                            </div>
                            <div v-else>
                              {{ block.hash }}
                            </div>
                          </table-cel>
                          <table-cel>
                            {{ block.amount }}
                          </table-cel>
                          <table-cel v-if="coinConfig.type != 'et'">
                            {{ block.mixin }}
                          </table-cel>
                        </table-row>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-6 mb-3">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    <span tkey="payments">Workers statistics</span>
                  </h3>
                </div>
                <div id="workersReport" class="table-responsive">
                  <table class="table card-table table undefined">
                    <thead>
                      <tr>
                        <th class="col2 sort">
                          <span tkey="workerName">Worker Name</span>
                        </th>
                        <th class="col3 sort">
                          <span tkey="hashRate">Hash Rate</span>
                        </th>
                        <th class="col3 sort">
                          <span tkey="difficulty">Difficulty</span>
                        </th>
                        <th class="col7 sort">
                          <span tkey="lastShare">Last Share Submitted</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <table-row
                        :key="block.name"
                        v-for="block in walletInfo.workers"
                      >
                        <table-cel>
                          {{ block.name }}
                        </table-cel>
                        <table-cel>
                          {{ block.hashrate }}
                        </table-cel>
                        <table-cel>
                          <span v-if="block.diff">
                            {{ block.diff }}
                          </span>
                          <span v-else>- </span>
                        </table-cel>
                        <table-cel>
                          {{ block.lastShare }}
                        </table-cel>
                      </table-row>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--
      <p class="yourStats text-center push-up-10">
        <button type="button" class="btn btn-default" id="loadMorePayments">
          <span tkey="loadMore">Load More</span>
        </button>
      </p>
    --></div>
  </page>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import PoolConfig from "@/config/PoolConfig"
import PoolApi from "@/services/api/Pool"
import Loader from '@/components/sections/Loader.vue'
import WorkerStatsChart from '@/components/charts/WorkerStatsChart.vue'
import Format from '@/utils/format'

const coinConfig = ref(PoolApi.coinConfig())
const walletInfo = ref({})
const walletSetting = ref({})
const loading = ref(false)
const address = ref("")
const results = ref(false)
const minerAddress = ref("")
const minerIp = ref("")
const minerPayout = ref("")
let intervalId = null // Store the interval ID for cleanup

// Methods
const payout = (e) => {
  e.preventDefault()
  fetchSettingData()
}

const search = (e) => {
  e.preventDefault()

  const selectedCoin = localStorage.getItem("coin")
  const storeAddress = localStorage.getItem("wallet" + selectedCoin)
  
  if (storeAddress !== address.value) {
    localStorage.removeItem("wallet" + selectedCoin)
  }

  localStorage.setItem("wallet" + selectedCoin, address.value)

  fetchData(address.value)
}

const fetchSettingData = () => {
  loading.value = true
  PoolApi.walletSettingsWrite(
    (response) => {
      walletSetting.value = response
    },
    () => {
      loading.value = false
    },
    minerAddress.value,
    minerIp.value,
    minerPayout.value
  )
}

const fetchData = (addr) => {
  if (addr.length) {
    loading.value = true
    PoolApi.walletInfo(
      (response) => {
        console.log('Worker data received:', response);
        walletInfo.value = response
        results.value = true
        loading.value = false
      },
      () => {
        loading.value = false
      },
      addr.replace("+", "%2b")
    )
  }
}

// Lifecycle
onMounted(() => {
  const selectedCoin = localStorage.getItem("coin")
  const addr = localStorage.getItem("wallet" + selectedCoin)
  if (addr) {
    address.value = addr
    fetchData(addr) // Initial fetch
  }

  // Start periodic refresh every 90 seconds (90,000 ms)
  intervalId = setInterval(() => {
    if (address.value) {
      fetchData(address.value)
    }
  }, 90000)
})

// Cleanup interval on component unmount
onBeforeUnmount(() => {
  clearInterval(intervalId)
})

// Computed property to format worker data for the chart
const formattedWorkers = computed(() => {
  if (!walletInfo.value || !walletInfo.value.workers) {
    console.log('No worker data available:', walletInfo.value);
    return [];
  }
  
  // Check if workers is an array or an object
  if (Array.isArray(walletInfo.value.workers)) {
    // If it's already an array, map it directly
    return walletInfo.value.workers.map(worker => {
      return {
        name: worker.name || 'Unknown',
        hashrate: parseFloat(worker.hashrate) || 0,
        validShares: worker.validShares || 0,
        invalidShares: worker.invalidShares || 0,
        lastShare: worker.lastShare || 0
      };
    });
  } else {
    // If it's an object, convert to array
    return Object.entries(walletInfo.value.workers).map(([name, data]) => {
      // Convert hashrate to a number if it's a string (e.g., "123 KH/s")
      let hashrateValue = 0;
      if (typeof data.hashrate === 'string') {
        // Extract numeric value from hashrate string
        const match = data.hashrate.match(/^[\d.]+/);
        if (match) {
          hashrateValue = parseFloat(match[0]);
          // Convert to base unit based on suffix
          if (data.hashrate.includes('KH/s')) hashrateValue *= 1000;
          else if (data.hashrate.includes('MH/s')) hashrateValue *= 1000000;
          else if (data.hashrate.includes('GH/s')) hashrateValue *= 1000000000;
          else if (data.hashrate.includes('TH/s')) hashrateValue *= 1000000000000;
        }
      } else {
        hashrateValue = data.hashrate || 0;
      }
      
      return {
        name: name,
        hashrate: hashrateValue,
        validShares: data.validShares || 0,
        invalidShares: data.invalidShares || 0,
        lastShare: data.lastShare || 0
      };
    });
  }
});
</script>