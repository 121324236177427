import PoolApi from "@/services/api/Pool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const localStorage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        try {
            const url = pool.endpoints.wallet.replace('{address}', address);
            const response = await axios.get(url);
            
            if (response.data.error === undefined) {
                return this.parseWalletInfo(response);
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletInfo:', error);
            return { error: 'Failed to fetch wallet info' };
        }
    },

    async setWalletSetting(pool, address, ip = 'any', amount) {
        try {
            const url = pool.endpoints.settings
                .replace('{method}', 'set')
                .replace('{address}', address)
                .replace('{ip}', ip)
                .replace('{amount}', amount);

            const response = await axios.get(url);
            
            if (response.data.error === undefined) {
                return { status: response.data.status };
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in setWalletSetting:', error);
            return { error: 'Failed to set wallet settings' };
        }
    },

    async getWalletSetting(pool, address) {
        try {
            const url = pool.endpoints.settings
                .replace('{method}', 'get')
                .replace('{address}', address)
                .replace('{ip}', '')
                .replace('{amount}', '');

            const response = await axios.get(url);
            
            if (response.data.status === 'done') {
                return {
                    address,
                    amount: response.data.level
                };
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletSetting:', error);
            return { error: 'Failed to get wallet settings' };
        }
    },

    async getStats(pool) {
        try {
            const url = pool.endpoints.stats;
            let response = await axios.get(url);
            response = await this.parseStats(response);
            response = await this.parsePayments(pool, response);
            return await this.getTop10(pool, response);
        } catch (error) {
            console.error('Error in getStats:', error);
            throw error;
        }
    },

    async getTop10(pool, response) {
        if (!pool.endpoints.top10) {
            return response;
        }

        try {
            const url = pool.endpoints.top10;
            const responseTop = await axios.get(url);
            return this.parseTop10(responseTop, response);
        } catch (error) {
            console.error('Error in getTop10:', error);
            return response;
        }
    },

    parseTop10(responseTop, response) {
        const stats = responseTop.data.map(item => ({
            miner: item.miner,
            hashrate: Format.getReadableHashRateString(item.hashrate),
            lastShare: Format.timeAgo(item.lastShare),
            hashes: item.hashes
        }));

        response.top10 = stats;
        return response;
    },

    parseWalletInfo(response) {
        const stats = {};
        const workers = [];
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;
        const config = localStorage.get("config");

        stats.hashes = Format.getReadableHashRateString(responseObj.stats.hashrate);
        stats.lastShare = Format.timeAgo(responseObj.stats.lastShare);
        stats.balance = Format.getReadableCoins(coin, responseObj.stats.balance, null, true, config.coinUnits);
        stats.paid = Format.getReadableCoins(coin, responseObj.stats.paid, null, true, config.coinUnits);

        responseObj.workers.forEach(worker => {
            workers.push({
                name: worker.name,
                hashrate: Format.getReadableHashRateString(worker.hashrate),
                lastShare: Format.timeAgo(worker.lastShare),
            });
        });

        stats.workers = workers;
        stats.payments = this.parsePaymentsString({ data: responseObj.payments }, response, coin);

        return stats;
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;
        const hashrateHistory = [];
        const hashrateHistorySolo = [];
        const blocks = [];

        if (responseObj.charts) {
            if (responseObj.charts.hashrate) {
                responseObj.charts.hashrate.forEach(item => {
                    hashrateHistory.push(item[1]);
                });
            }

            if (responseObj.charts.hashrateSolo) {
                responseObj.charts.hashrateSolo.forEach(item => {
                    hashrateHistorySolo.push(item[1]);
                });
            }
        }

        if (responseObj.pool?.blocks?.length > 0) {
            const parseBlock = (height, parts) => {
                const luck = Format.formatLuck(parseInt(parts[4]), parseInt(parts[5]));
                const effort = (responseObj.pool.roundHashes / responseObj.network.difficulty * 100).toFixed(1) + '%';
                
                const block = {
                    height: parseInt(height),
                    hash: parts[1],
                    time: Format.formatDate(parts[2]),
                    difficulty: parseInt(parts[3]),
                    shares: parseInt(parts[5]),
                    orphaned: parts[9],
                    reward: parts[6],
                    luck,
                    rawtime: parseInt(parts[2]),
                    solo: parts[8] !== 'props',
                    minerAddress: parts[7],
                    effort: Math.round(parseInt(parts[5]) / parseInt(parts[4]) * 100)
                };

                block.status = block.orphaned === '0' ? 'unlocked' : 
                              block.orphaned === '1' ? 'orphaned' : 'pending';

                if (block.orphaned === '1') {
                    block.reward = 0;
                }

                if (block.reward > 0) {
                    block.reward = Format.getReadableCoins(coin, block.reward, null, true, responseObj.config.coinUnits) + 
                                 ' ' + PoolApi.getSelectedCoin();
                }

                return block;
            };

            if (responseObj.pool.blocks[1].length > 10) {
                responseObj.pool.blocks.forEach(blockData => {
                    const parts = blockData.split(':');
                    blocks.push(parseBlock(parts[0], parts));
                });
            } else {
                for (let i = 0; i < responseObj.pool.blocks.length; i += 2) {
                    const parts = responseObj.pool.blocks[i].split(':');
                    const height = responseObj.pool.blocks[i + 1];
                    blocks.push(parseBlock(height, parts));
                }
            }

            blocks.sort((a, b) => b.rawtime - a.rawtime);
        }

        if (responseObj.pool) {
            stats.miners = responseObj.pool.miners || 0;
            stats.soloMiners = responseObj.pool.minersSolo || 0;
            stats.workers = responseObj.pool.workers || 0;
            stats.soloWorkers = responseObj.pool.workersSolo || 0;
            stats.poolHashRate = Format.getReadableHashRateString(responseObj.pool.hashrate || 0);
            stats.poolSoloHashRate = Format.getReadableHashRateString(responseObj.pool.hashrateSolo || 0);
            stats.poolFee = coin.pool.fee;
            stats.poolMinimumPayout = coin.pool.minPayout;
            stats.networkDifficulty = responseObj.network.difficulty || 0;
            stats.blockHeight = responseObj.network.height || 0;
            stats.blockLastFound = responseObj.pool.lastBlockFound || 0;
            stats.hashRateHistory = hashrateHistory;
            stats.hashrateHistorySolo = hashrateHistorySolo;
            stats.blocks = blocks;
            stats.payments = [];
            stats.config = responseObj.config;
            stats.reward = responseObj.lastblock.reward;
        } else if (responseObj.totalPoolMiners) {
            stats.miners = responseObj.totalPoolMiners;
            stats.soloMiners = responseObj.totalSoloMiners;
            stats.workers = 0;
            stats.soloWorkers = responseObj.pool.workersSolo || 0;
            stats.poolHashRate = Format.getReadableHashRateString(responseObj.poolHashrate);
            stats.poolSoloHashRate = Format.getReadableHashRateString(responseObj.soloHashrate);
            stats.poolFee = coin.pool.fee;
            stats.poolMinimumPayout = coin.pool.minPayout;
            stats.networkDifficulty = responseObj.lastblock.Difficulty;
            stats.blockHeight = responseObj.lastblock.Height;
            stats.blockLastFound = responseObj.lastblock.Timestamp;
            stats.hashRateHistory = hashrateHistory;
            stats.hashrateHistorySolo = hashrateHistorySolo;
            stats.blocks = blocks;
            stats.payments = [];
            stats.config = responseObj.config;
            stats.reward = responseObj.lastblock.reward;
        }

        localStorage.set("config", stats.config);
        return stats;
    },

    async parsePayments(pool, response) {
        try {
            const timestamp = Math.round(Date.now() / 1000);
            const paymentUrl = pool.endpoints.payments.replace('{time}', timestamp);
            const payments = await axios.get(paymentUrl);
            const coin = PoolApi.coinConfig();
            
            response.payments = this.parsePaymentsString(payments, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parsePayments:', error);
            throw error;
        }
    },

    parsePaymentsString(payments, response, coin) {
        const paymentsList = [];
        const responseObj = payments.data;

        if (responseObj) {
            const config = localStorage.get("config");
            
            for (let i = 0; i < responseObj.length; i += 2) {
                const parts = responseObj[i].split(':');
                const time = responseObj[i + 1];
                const part4 = parts[4] || '';

                const payment = {
                    time: Format.formatDate(parseInt(time)),
                    hash: parts[0],
                    amount: parts[1].toLocaleString('fullwide', { useGrouping: false }),
                    fee: parts[2].toLocaleString('fullwide', { useGrouping: false }),
                    mixin: parts[3],
                    recipients: part4,
                    payees: part4.length,
                    rawtime: parseInt(time),
                    id: i
                };

                payment.amount = Format.getReadableCoins(coin, parseInt(payment.amount), null, true, config.coinUnits) + 
                                ' ' + PoolApi.getSelectedCoin();
                payment.fee = Format.getReadableCoins(coin, payment.fee, null, true, config.coinUnits) + 
                             ' ' + PoolApi.getSelectedCoin();

                paymentsList.push(payment);
            }

            paymentsList.sort((a, b) => b.rawtime - a.rawtime);
        }

        return paymentsList;
    }
};