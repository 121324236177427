<template>
  <!-- This is an invisible component that manages meta tags -->
</template>

<script setup>
import { watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { trackPageView } from '@/utils/analytics';

const route = useRoute();

// Function to update meta tags based on current route
const updateMetaTags = () => {
  // Update document title
  const title = route.meta.title || 'letshash.it - Cryptocurrency Mining Pool';
  document.title = title;
  
  // Update meta description
  let metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', route.meta.description || 'letshash.it - A reliable cryptocurrency mining pool for various coins. Start mining with us today!');
  }
  
  // Update meta keywords
  let metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', route.meta.keywords || 'cryptocurrency, mining pool, crypto mining, blockchain, letshash');
  }
  
  // Update Open Graph tags
  let ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute('content', title);
  }
  
  let ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute('content', route.meta.description || 'A reliable cryptocurrency mining pool for various coins. Start mining with us today!');
  }
  
  // Track page view in Google Analytics
  trackPageView(route.fullPath, title);
};

// Watch for route changes
watch(() => route.path, updateMetaTags, { immediate: true });
watch(() => route.params, updateMetaTags, { immediate: true });
watch(() => route.meta, updateMetaTags, { deep: true, immediate: true });

// Initial update on component mount
onMounted(updateMetaTags);
</script> 