import PoolApi from "@/services/api/Pool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const localStorage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        try {
            const url = pool.endpoints.wallet.replace('{address}', address);
            const response = await axios.get(url);
            
            if (response.data.error === undefined) {
                return this.parseWalletInfo(pool, response, address);
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletInfo:', error);
            return { error: 'Failed to fetch wallet info' };
        }
    },

    async getStats(pool) {
        try {
            const url = pool.endpoints.stats;
            let response = await axios.get(url);
            response = await this.parseStats(response);
            response = await this.parsePayments(pool, response);
            return await this.parseBlocks(pool, response);
        } catch (error) {
            console.error('Error in getStats:', error);
            throw error;
        }
    },

    async parseWalletInfo(pool, response, address) {
        const stats = {};
        const workers = [];
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;

        stats.hashes = `${responseObj.miner_hashrate}H/s`;

        try {
            const sharesResponse = await axios.get(pool.endpoints.walletShares.replace('{address}', address));
            const date = new Date(sharesResponse.data.results[0].time * 1000);
            
            stats.lastShare = date.toLocaleDateString("en-US");
            stats.balance = `N/A ${PoolApi.getSelectedCoin()}`;
            stats.paid = `N/A ${PoolApi.getSelectedCoin()}`;

            if (responseObj.miners?.length > 0) {
                responseObj.miners.forEach(miner => {
                    workers.push({
                        name: `${miner.ID} ${miner.version}`,
                        hashrate: Format.getReadableHashRateString(miner.accepted),
                        lastShare: '',
                        diff: miner.difficulty
                    });
                });
            }
            stats.workers = [];

            return stats;
        } catch (error) {
            console.error('Error in parseWalletInfo shares:', error);
            throw error;
        }
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;
        const hashrateHistory = [];

        stats.miners = responseObj.pool.miner_count;
        stats.soloMiners = 0;
        stats.workers = responseObj.pool.worker_count;
        stats.soloWorkers = 0;
        stats.poolHashRate = Format.getReadableHashRateString(responseObj.pool.hashes_per_second);
        stats.poolHashRateRaw = responseObj.pool.hashes_per_second;
        stats.poolSoloHashRate = 0;
        stats.poolFee = coin.pool.fee;
        stats.poolMinimumPayout = coin.pool.minPayout;
        stats.networkDifficulty = responseObj.network.difficulty;
        stats.networkHashrate = responseObj.network.hashes_per_second;
        stats.blockHeight = responseObj.network.height;
        stats.blockLastFound = responseObj.network.last_block;
        stats.hashRateHistory = hashrateHistory;
        stats.blocks = responseObj.pool.blocks;
        stats.payments = responseObj.pool.payouts;
        stats.config = '';
        stats.reward = responseObj.network.reward;

        localStorage.set("config", responseObj);

        return stats;
    },

    async parseHistoryStats(pool, response) {
        if (!pool.endpoints.hashrateHistory) {
            return response;
        }

        try {
            const hashrates = await axios.get(pool.endpoints.hashrateHistory);
            const hashrateHistory = hashrates.data[0].map(item => item[1]);
            response.hashRateHistory = hashrateHistory;
            return response;
        } catch (error) {
            console.error('Error in parseHistoryStats:', error);
            throw error;
        }
    },

    async parseBlocks(pool, response) {
        try {
            const blockUrl = pool.endpoints.blocks;
            const blocks = await axios.get(blockUrl);
            const coin = PoolApi.coinConfig();
            response.blocks = this.parseBlocksString(blocks, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parseBlocks:', error);
            throw error;
        }
    },

    parseBlocksString(blocks, response, coin) {
        const payments = [];
        const responseObj = blocks.data.pool.blocks;
        const config = localStorage.get("config");

        responseObj.forEach(parts => {
            const payment = {
                time: Format.formatDate(parseInt(parts.time)),
                hash: parts.hash,
                height: parts.height || '',
                amount: parts.amount || '',
                rawtime: parseInt(parts.rawtime),
                difficulty: parts.difficulty || '',
                shares: parts.share_diff || '',
                orphaned: '',
                reward: `50 ${PoolApi.getSelectedCoin()}`,
                luck: '',
                status: '',
                solo: false,
                effort: '',
                minerAddress: ''
            };

            payment.amount = `${payment.amount} ${PoolApi.getSelectedCoin()}`;
            payment.fee = `${payment.fee ? Format.getReadableCoins(coin, payment.fee, null, true, config.coinUnits) : 0} ${PoolApi.getSelectedCoin()}`;

            payments.push(payment);
        });

        return payments.sort((a, b) => b.rawtime - a.rawtime);
    },

    async parsePayments(pool, response) {
        try {
            const timestamp = Math.round(Date.now() / 1000);
            const paymentUrl = pool.endpoints.payments.replace('{time}', timestamp);
            const payments = await axios.get(paymentUrl);
            const coin = PoolApi.coinConfig();
            
            response.payments = this.parsePaymentsString(payments, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parsePayments:', error);
            throw error;
        }
    },

    parsePaymentsString(payments, response, coin) {
        const paymentsList = [];
        const responseObj = payments.data?.pool?.payouts;

        if (responseObj) {
            responseObj.forEach((block, blockIndex) => {
                const txn = block.txn;
                const time = txn.rawtime || txn.time;

                txn.outputs.forEach((output, outputIndex) => {
                    const payment = {
                        time: Format.formatDate(parseInt(time)),
                        hash: txn.tx || txn.hash,
                        amount: output.value.toFixed(4),
                        fee: parseFloat(txn.fee),
                        mixin: '',
                        recipients: 1,
                        payees: 1,
                        rawtime: parseInt(time),
                        id: parseInt(`${Date.now()}${outputIndex}${blockIndex}`)
                    };

                    payment.amount = `${payment.amount} ${PoolApi.getSelectedCoin()}`;
                    payment.fee = payment.fee ? `${payment.fee} ${PoolApi.getSelectedCoin()}` : '';
                    paymentsList.push(payment);
                });
            });
        }

        return paymentsList.sort((a, b) => b.rawtime - a.rawtime);
    }
};