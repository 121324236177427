<template>
  <div>
    <div class="footer border-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-6 col-md-3">
                <ul class="list-unstyled mb-0">
                  <li class="d-flex align-items-center">
                    <a
                      href="https://github.com/letshash/letshash.it/discussions/categories/general"
                      >Contact us</a
                    >
                  </li>
                  <li class="d-flex align-items-center">
                    <a href="/#/terms-of-use">Terms of Use</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <a href="/#/privacy-policy">Privacy policy</a>
                  </li>
                </ul>
              </div>
              <div class="col-6 col-md-3">
                <ul class="list-unstyled mb-0">
                  <li class="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon text-blue"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="9" cy="12" r="1" />
                      <circle cx="15" cy="12" r="1" />
                      <path d="M7.5 7.5c3.5 -1 5.5 -1 9 0" />
                      <path d="M7 16.5c3.5 1 6.5 1 10 0" />
                      <path
                        d="M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-1 2.5"
                      />
                      <path
                        d="M8.5 17c0 1 -1.356 3 -1.832 3c-1.429 0 -2.698 -1.667 -3.333 -3c-.635 -1.667 -.476 -5.833 1.428 -11.5c1.388 -1.015 2.782 -1.34 4.237 -1.5l1 2.5"
                      />
                    </svg>
                    <a href="https://discordapp.com/invite/BmU6HdK">Discord</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon text-blue"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
                    </svg>
                    <a href="https://t.me/letshashit">Telegram</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon text-blue"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"
                      />
                    </svg>
                    <a href="https://twitter.com/letshashit">Twitter</a>
                  </li>
                </ul>
              </div>
              <div class="col-6 col-md-3">
                <ul class="list-unstyled mb-0">
                  <li class="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-sm text-blue ms-1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="4" y="4" width="16" height="16" rx="2" />
                      <path d="M8 9h1l3 3l3 -3h1" />
                      <line x1="8" y1="15" x2="10" y2="15" />
                      <line x1="14" y1="15" x2="16" y2="15" />
                      <line x1="9" y1="9" x2="9" y2="15" />
                      <line x1="15" y1="9" x2="15" y2="15" />
                    </svg>
                    <a href="https://medium.com/@letshashit">Blog</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon text-blue"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="9" />
                    </svg>
                    <a class="" href="https://status.letshash.it/">
                      Status page</a
                    >
                  </li>
                  <li class="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon text-blue"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    <a
                      href="https://forms.gle/7nPzmhzmRXnFw37FA"
                      target="_blank"
                      class="font-bold"
                      >Coin listing</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-6 col-md-3"></div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-md-12">
                Donate us
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class=""
                  width="15"
                  height="20"
                  viewBox="0 0 20 30"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M6 6h8a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-8"></path>
                  <line x1="8" y1="6" x2="8" y2="18"></line>
                  <line x1="8" y1="12" x2="14" y2="12"></line>
                  <line x1="9" y1="3" x2="9" y2="6"></line>
                  <line x1="13" y1="3" x2="13" y2="6"></line>
                  <line x1="9" y1="18" x2="9" y2="21"></line>
                  <line x1="13" y1="18" x2="13" y2="21"></line>
                </svg>
                <code class="mark text-dark"
                  >bc1qkg5hd68xtlfrq03stp7xzxufdktetvvs8fpqgn</code
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-4 mt-lg-0">
            Cryptocurrency mining pool for beginners and professionals. Regular
            payments, tutorials, reliable servers, rig monitoring. Solo mining
            is also available for all.
            <br />

            <a href="https://github.com/letshash/letshash.it/discussions/categories/q-a"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class=""
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M9 9v-1a3 3 0 0 1 6 0v1"></path>
                <path
                  d="M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1 -10 0v-3a6 6 0 0 1 1 -3"
                ></path>
                <line x1="3" y1="13" x2="7" y2="13"></line>
                <line x1="17" y1="13" x2="21" y2="13"></line>
                <line x1="12" y1="20" x2="12" y2="14"></line>
                <line x1="4" y1="19" x2="7.35" y2="17"></line>
                <line x1="20" y1="19" x2="16.65" y2="17"></line>
                <line x1="4" y1="7" x2="7.75" y2="9.4"></line>
                <line x1="20" y1="7" x2="16.25" y2="9.4"></line></svg
              >Found an error or have a question?</a
            >
          </div>
        </div>
      </div>
    </div>
    <footer class="footer border-0">
      <div class="container">
        <div class="row align-items-center flex-row-reverse">
          <div class="col-auto ml-lg-auto">
            <div class="row align-items-center text-muted">
              <small v-if="gitHash">rev. {{ gitHash }}</small>
            </div>
          </div>
          <div class="col-12 col-lg-auto mt-3 mt-lg-0 text-center">
            Copyright © 2019-{{ currentYear }} <a href="/">Letshash.it</a>. All rights
            reserved.
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

// Reactive state
const gitHash = ref(import.meta.env.VITE_GIT_VERSION)
const currentYear = new Date().getFullYear()
</script>