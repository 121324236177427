import timeAgo from 'time-ago';

export default {

    timeAgo: function (value) {
        if (value === null) {
            return 'N/A';
        }

        return timeAgo.ago(new Date(parseInt(value) * 1000).toISOString());
    },

    // Convert float to string
    floatToString: function (float) {
        return float.toFixed(6).replace(/\.0+$|0+$/, '');
    },

    // Format number
    formatNumber: function (number, delimiter) {
        if (number != '') {
            number = number.split(delimiter).join('');

            var formatted = '';
            var sign = '';

            if (number < 0) {
                number = -number;
                sign = '-';
            }

            while (number >= 1000) {
                var mod = number % 1000;

                if (formatted != '') formatted = delimiter + formatted;
                if (mod == 0) formatted = '000' + formatted;
                else if (mod < 10) formatted = '00' + mod + formatted;
                else if (mod < 100) formatted = '0' + mod + formatted;
                else formatted = mod + formatted;

                number = parseInt(number / 1000);
            }

            if (formatted != '') formatted = sign + number + delimiter + formatted;
            else formatted = sign + number;
            return formatted;
        }
        return '';
    },

    formatDate: function (time) {
        if (!time) return '';
        return new Date(time).toLocaleString();
    },

    // Format percentage
    formatPercent: function (percent) {
        if (!percent && percent !== 0) return '';
        return percent + '%';
    },

    formatEffort(effort) {
        return (effort * 100).toFixed(0);
    },

    // Get readable time
    getReadableTime: function (seconds) {
        var units = [[60, 'second'], [60, 'minute'], [24, 'hour'],
        [7, 'day'], [4, 'week'], [12, 'month'], [1, 'year']];

        function formatAmounts(amount, unit) {
            var rounded = Math.round(amount);
            var unit = unit + (rounded > 1 ? 's' : '');
            return '' + rounded + ' ' + unit;
        }

        var amount = seconds;
        for (var i = 0; i < units.length; i++) {
            if (amount < units[i][0]) {
                return formatAmounts(amount, units[i][1]);
            }
            amount = amount / units[i][0];
        }
        return formatAmounts(amount, units[units.length - 1][1]);
    },

    // Get readable hashrate
    getReadableHashRateString: function (hashrate, coinConfig) {
        if (!hashrate) {
            return '0 H/s';
        }

        // Use the more modern formatHashrate function for consistency
        return this.formatHashrate(hashrate);
    },

    formatDifficulty: function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    // Format luck / current effort
    formatLuck: function (difficulty, shares) {
        var percent = Math.round(shares / difficulty);

        if (!percent) {
            return {
                class: 'text-success',
                percent: ''
            }
        }
        else if (percent <= 100) {
            return {
                class: 'text-success',
                percent: percent
            }
        }
        else if (percent >= 101 && percent <= 150) {
            return {
                class: 'text-warning',
                percent: percent
            }
        }
        else {
            return {
                class: 'text-danger',
                percent: percent
            }
        }
    },

    getArchIcon: function (coinConfig) {
        const unique = [...new Set(coinConfig.minerLinks.map(item => item.arch))];
        return unique.join('/');
    },

    // Get coin decimal places
    getCoinDecimalPlaces: function (coinInfo) {
        if (typeof coinDecimalPlaces != "undefined") return coinDecimalPlaces;
        else if (coinInfo.unitPlaces) return coinInfo.unitPlaces;
        else coinInfo.unitPlaces.toString().length - 1;
    },

    // Get readable coins
    getReadableCoins: function (coinInfo, coins, digits, withoutSymbol, coinUnits) {
        var coinDecimalPlaces = coinInfo.unitPlaces;
        if (coinInfo.decimalPlaces || coinInfo.decimalPlaces === 0) {
            coinDecimalPlaces = coinInfo.decimalPlaces
        }

        var _coinUnits = coinInfo.coinUnits || coinUnits;

        var v = new Number(parseInt(coins || 0) / _coinUnits)

        var amount = v.toFixed(coinDecimalPlaces);
        return amount.toString() + (withoutSymbol ? '' : (' ' + coinInfo.name));
    },

    // Return transaction URL
    getTransactionUrl: function (id) {
        return transactionExplorer.replace(new RegExp('{symbol}', 'g'), lastStats.config.symbol.toLowerCase()).replace(new RegExp('{id}', 'g'), id);
    },

    // Return blockchain explorer URL
    getBlockchainUrl: function (id) {
        return blockchainExplorer.replace(new RegExp('{symbol}', 'g'), lastStats.config.symbol.toLowerCase()).replace(new RegExp('{id}', 'g'), id);
    },

    formatHashrate (hashrate) {
        if (!hashrate || isNaN(hashrate)) {
            return '0 H/s';
        }
        
        // For very large numbers, ensure we're working with a number
        hashrate = Number(hashrate);
        
        const thresholds = [
            { limit: 1e15, unit: 'PH/s' },
            { limit: 1e12, unit: 'TH/s' },
            { limit: 1e9, unit: 'GH/s' },
            { limit: 1e6, unit: 'MH/s' },
            { limit: 1e3, unit: 'KH/s' },
            { limit: 1, unit: 'H/s' }
        ];
    
        for (const { limit, unit } of thresholds) {
            if (hashrate >= limit) {
                // Format with appropriate precision based on size
                const value = hashrate / limit;
                if (value >= 100) {
                    return Math.round(value) + ' ' + unit;
                } else if (value >= 10) {
                    return value.toFixed(1) + ' ' + unit;
                } else {
                    return value.toFixed(2) + ' ' + unit;
                }
            }
        }
        return hashrate.toFixed(2) + ' H/s';
    }

}