<template>
  <div class="page" :class="{ 'theme-dark': themeDark }">
    <!-- Meta Manager component for handling dynamic meta tags -->
    <meta-manager />
    
    <site-header
      imageUrl="/static/logo.png"
      alt="letshash.it"
      logoText="letshash.it"
      @toggle-menu="toggleMenu"
      class="header-bg"
      :showToggleMenuClass="showToggleMenuClass"
    >
      <!-- Coin listing link -->
      <div class="m-auto d-flex">
        <div class="mr-2">
          <a
            href="https://forms.gle/7nPzmhzmRXnFw37FA"
            target="_blank"
            class="nav-link pr-2 px-0"
            data-toggle="dropdown"
            tabindex="-1"
            aria-expanded="false"
            title="Coin listing"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </a>
        </div>
      </div>

      <!-- Theme toggle -->
      <div class="m-auto d-flex">
        <div class="mr-2">
          <!-- Dark theme button -->
          <a
            href="#"
            class="nav-link pr-2 px-0"
            data-toggle="dropdown"
            tabindex="-1"
            aria-expanded="false"
            title="Dark"
            :class="{ 'd-none': themeDark }"
            @click.prevent="toggleDark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 3c0.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"></path>
            </svg>
          </a>

          <!-- Light theme button -->
          <a
            href="#"
            class="nav-link pr-2 px-0"
            data-toggle="dropdown"
            tabindex="-1"
            aria-expanded="false"
            title="Light"
            :class="{ 'd-none': !themeDark }"
            @click.prevent="toggleDark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="3"></circle>
              <line x1="12" y1="5" x2="12" y2="3"></line>
              <line x1="17" y1="7" x2="18.4" y2="5.6"></line>
              <line x1="19" y1="12" x2="21" y2="12"></line>
              <line x1="17" y1="17" x2="18.4" y2="18.4"></line>
              <line x1="12" y1="19" x2="12" y2="21"></line>
              <line x1="7" y1="17" x2="5.6" y2="18.4"></line>
              <line x1="6" y1="12" x2="4" y2="12"></line>
              <line x1="7" y1="7" x2="5.6" y2="5.6"></line>
            </svg>
          </a>
        </div>
      </div>

      <!-- Notifications -->
      <div class="m-auto">
        <notifications v-if="notifications.length">
          <dropdown-menu-item
            class="d-flex"
            :key="notify.label"
            v-for="notify in notifications"
          >
            <div>
              {{ notify.label }}
              <div class="small">{{ notify.time }}</div>
            </div>
          </dropdown-menu-item>
        </notifications>
      </div>
    </site-header>

    <!-- Navigation menu -->
    <div class="navbar-expand-md" v-if="poolSelected">
      <div
        class="collapse navbar-collapse"
        :class="{ show: !menuCollapsed }"
        id="navbar-menu"
      >
        <div class="navbar navbar-bg">
          <div class="container-xl">
            <ul class="navbar-nav">
              <!-- Home -->
              <li class="nav-item">
                <router-link 
                  :to="currentCoin ? `/${currentCoin.toLowerCase()}` : '/pools'" 
                  class="nav-link" 
                  @click="toggleMenu"
                >
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                      <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                      <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                    </svg>
                  </span>
                  <span class="nav-link-title">Home</span>
                </router-link>
              </li>

              <!-- How to mine -->
              <li class="nav-item">
                <router-link 
                  :to="currentCoin ? `/how-to-mine/${currentCoin.toLowerCase()}` : '/pools'" 
                  class="nav-link" 
                  @click="toggleMenu"
                >
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <rect x="4" y="4" width="6" height="5" rx="2"></rect>
                      <rect x="4" y="13" width="6" height="7" rx="2"></rect>
                      <rect x="14" y="4" width="6" height="7" rx="2"></rect>
                      <rect x="14" y="15" width="6" height="5" rx="2"></rect>
                    </svg>
                  </span>
                  <span class="nav-link-title">How to mine</span>
                </router-link>
              </li>

              <!-- Your stats -->
              <li class="nav-item">
                <router-link 
                  :to="currentCoin ? `/worker/${currentCoin.toLowerCase()}` : '/pools'" 
                  class="nav-link" 
                  @click="toggleMenu"
                >
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <polyline points="9 11 12 14 20 6"></polyline>
                      <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"></path>
                    </svg>
                  </span>
                  <span class="nav-link-title">Your stats</span>
                </router-link>
              </li>

              <!-- Blocks -->
              <li class="nav-item">
                <router-link 
                  :to="currentCoin ? `/blocks/${currentCoin.toLowerCase()}` : '/pools'" 
                  class="nav-link" 
                  @click="toggleMenu"
                >
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                      <line x1="12" y1="12" x2="20" y2="7.5"></line>
                      <line x1="12" y1="12" x2="12" y2="21"></line>
                      <line x1="12" y1="12" x2="4" y2="7.5"></line>
                      <line x1="16" y1="5.25" x2="8" y2="9.75"></line>
                    </svg>
                  </span>
                  <span class="nav-link-title">Blocks</span>
                </router-link>
              </li>

              <!-- Payments -->
              <li class="nav-item">
                <router-link 
                  :to="currentCoin ? `/payments/${currentCoin.toLowerCase()}` : '/pools'" 
                  class="nav-link" 
                  @click="toggleMenu"
                >
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                    </svg>
                  </span>
                  <span class="nav-link-title">Payments</span>
                </router-link>
              </li>

              <!-- Paper wallet -->
              <li class="nav-item" v-if="paperWallet">
                <router-link 
                  to="/paper-wallet" 
                  class="nav-link" 
                  @click="toggleMenu"
                >
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3"></path>
                      <path d="M8 11a4 4 0 0 1 8 0v1a10 10 0 0 0 2 6"></path>
                      <path d="M12 11v2a14 14 0 0 0 2.5 8"></path>
                      <path d="M8 15a18 18 0 0 0 1.8 6"></path>
                      <path d="M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 12 -6.95"></path>
                    </svg>
                  </span>
                  <span class="nav-link-title">Paper wallet</span>
                </router-link>
              </li>
              
              <!-- Profit calculator -->
              <li class="nav-item" v-if="profitCalculator">
                <router-link 
                  :to="currentCoin ? `/profit-calculator/${currentCoin.toLowerCase()}` : '/pools'" 
                  class="nav-link" 
                  @click="toggleMenu"
                >
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1"></path>
                      <path d="M12 6v2m0 8v2"></path>
                    </svg>
                  </span>
                  <span class="nav-link-title">
                    Profit calculator <sup class="text-lime">beta</sup>
                  </span>
                </router-link>
              </li>
            </ul>

            <!-- Coin switch button -->
            <div class="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
              <router-link to="/pools" class="btn btn-sm btn-info">
                coin switch
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <router-view :poolStats="poolStats" />

    <!-- Footer -->
    <Footer />
  </div>
</template>


<script setup>
import { ref, markRaw, shallowRef, onMounted, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useStorage } from '@/composables/useStorage';
import PoolConfig from "@/config/PoolConfig"
import PoolApi from "@/services/api/Pool"
//import EventBus from "@/services/EventBus"
import Footer from "@/components/sections/Footer.vue"
import MetaManager from "@/components/MetaManager.vue"


// Setup router and route
const router = useRouter()
const route = useRoute()
const localStorage = useStorage();

// Reactive refs
const poolStats = shallowRef({})
const menuCollapsed = ref(true)
const notifications = shallowRef(markRaw(PoolConfig.getNotifications()))
const paperWallet = ref(false)
const profitCalculator = ref(false)
const showToggleMenuClass = ref("")

// Safely get localStorage values with fallback
const selectedCoin = localStorage?.get(PoolConfig.KEY_COIN, false) ?? false
const poolCoin = PoolConfig.getCoin(selectedCoin)
const poolConfig = ref(PoolConfig.getConfig())
const themeDark = ref(localStorage?.get(PoolConfig.KEY_THEME) ?? false)

// Computed properties
const currentCoin = computed(() => {
  const routeCoin = route.params.coin
  const storedCoin = localStorage?.get(PoolConfig.KEY_COIN, false)
  
  // If we're on the pools page, don't return any coin
  if (route.path === '/pools') {
    return ''
  }
  
  return (routeCoin || storedCoin || '').toUpperCase()
})

const poolSelected = computed(() => !!currentCoin.value)

// Methods
const toggleMenu = () => {
  menuCollapsed.value = !menuCollapsed.value
}

const onChange = (event) => {
  localStorage?.set(PoolConfig.KEY_COIN, event.target.value)
  router.push(`/${event.target.value.toLowerCase()}`)
}

const toggleDark = () => {
  const newThemeValue = !themeDark.value
  localStorage?.set(PoolConfig.KEY_THEME, newThemeValue)
  themeDark.value = newThemeValue
}

// Setup on component creation
onMounted(() => {
  const selectedCoin = localStorage?.get(PoolConfig.KEY_COIN, false) ?? false
  
  // Only redirect to pools if no coin is selected and we're not already there
  if (!selectedCoin && route.name !== 'pools') {
    router.push("/pools")
    showToggleMenuClass.value = "d-none"
    return
  }

  // Set up features based on coin config
  const poolCoin = PoolConfig.getCoin(selectedCoin)
  if (poolCoin && poolCoin.unitPlaces > 0 && poolCoin.addressPrefix > 0) {
    paperWallet.value = true
  }

  if (poolCoin && poolCoin.coingeckoID) {
    profitCalculator.value = true
  }

  // Handle navigation when coin exists
  if (selectedCoin) {
    // Only redirect if we're not on a valid route already
    if (!route.params.coin && route.name !== 'pools') {
      router.push(`/${selectedCoin.toLowerCase()}`)
    }
  }
})
</script>

<style>
@import "assets/css/tabler-1.0.css";
@import "assets/css/demo-1.0.css";

.header-bg {
  background-color: #ecf2fa;
}
.navbar-bg {
  background-color: #206bc4;
}
.header-brand-img {
  height: 30px;
  background-color: white;
  border-radius: 15px;
  margin-right: 5px;
}
</style>