import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue'
import HowToMine from '../components/HowToMine.vue'
import Blocks from '../components/Blocks.vue'
import Payments from '../components/Payments.vue'
import Worker from '../components/Worker.vue'
import SwitchCoin from '../components/SwitchCoin.vue'
import PrivacyPolicy from '../components/PrivacyPolicy.vue'
import TermsOfUse from '../components/TermsOfUse.vue'
import PaperWallet from '../components/PaperWallet.vue'
import ProfitCalculator from '../components/ProfitCalculator.vue'
import PoolConfig from "@/config/PoolConfig"
import { trackPageView } from '@/utils/analytics'

const routes = [
  {
    path: '/',
    redirect: '/pools'
  },
  {
    path: '/pools',
    name: 'pools',
    component: SwitchCoin,
    meta: {
      title: 'Available Mining Pools - letshash.it',
      description: 'Choose from our selection of cryptocurrency mining pools. Start mining your favorite coins today!',
      keywords: 'mining pools, cryptocurrency pools, crypto mining'
    }
  },
  {
    path: '/how-to-mine/:coin?',
    name: 'howToMine',
    component: HowToMine,
    props: true,
    meta: {
      title: 'How to Mine - letshash.it',
      description: 'Learn how to start mining cryptocurrencies with our step-by-step guide.',
      keywords: 'how to mine, mining guide, crypto mining tutorial'
    }
  },
  {
    path: '/blocks/:coin?',
    name: 'blocks',
    component: Blocks,
    props: true,
    meta: {
      title: 'Blocks Explorer - letshash.it',
      description: 'Explore the latest blocks mined on our pool. View block details, rewards, and more.',
      keywords: 'blocks explorer, mining blocks, blockchain explorer'
    }
  },
  {
    path: '/payments/:coin?',
    name: 'payments',
    component: Payments,
    props: true,
    meta: {
      title: 'Payment History - letshash.it',
      description: 'View your mining payment history and transaction details.',
      keywords: 'mining payments, payment history, mining rewards'
    }
  },
  {
    path: '/worker/:coin?',
    name: 'worker',
    component: Worker,
    props: true,
    meta: {
      title: 'Worker Statistics - letshash.it',
      description: 'Monitor your mining workers performance, hashrate, and earnings.',
      keywords: 'mining workers, worker stats, hashrate monitor'
    }
  },
  {
    path: '/paper-wallet',
    name: 'paperWallet',
    component: PaperWallet,
    props: true,
    meta: {
      title: 'Paper Wallet Generator - letshash.it',
      description: 'Generate secure paper wallets for your cryptocurrencies.',
      keywords: 'paper wallet, crypto wallet, secure storage'
    }
  },
  {
    path: '/profit-calculator/:coin?',
    name: 'profitCalculator',
    component: ProfitCalculator,
    props: true,
    meta: {
      title: 'Mining Profit Calculator - letshash.it',
      description: 'Calculate your potential mining profits based on your hardware and current market conditions.',
      keywords: 'mining calculator, profit calculator, mining profitability'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy - letshash.it',
      description: 'Read our privacy policy to understand how we collect and use your data.',
      keywords: 'privacy policy, data protection, user privacy'
    }
  },
  {
    path: '/terms-of-use',
    name: 'termsOfUse',
    component: TermsOfUse,
    meta: {
      title: 'Terms of Use - letshash.it',
      description: 'Read our terms of use to understand the rules and guidelines for using our services.',
      keywords: 'terms of use, terms of service, user agreement'
    }
  },
  {
    path: '/:coin',
    name: 'home',
    component: HomePage,
    props: true,
    meta: {
      title: 'Mining Pool - letshash.it',
      description: 'Join our mining pool and start earning cryptocurrency rewards today.',
      keywords: 'mining pool, crypto mining, blockchain mining'
    },
    beforeEnter: (to, from, next) => {
      const config = PoolConfig.getConfig()
      const coin = to.params.coin.toUpperCase()
      
      if (config[coin]) {
        // Create a completely new meta object to avoid reference issues
        to.meta = Object.assign({}, {
          title: `${coin} Mining Pool - letshash.it`,
          description: `Mine ${coin} with our reliable mining pool. Get started today and earn ${coin} rewards.`,
          keywords: `${coin} mining, ${coin} pool, cryptocurrency mining`
        })
        
        // Force update document title immediately
        document.title = to.meta.title
        
        next()
      } else {
        next('/pools')
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/pools'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Update document title and meta tags based on route
router.beforeEach((to, from, next) => {
  // Update document title
  const title = to.meta.title || 'letshash.it - Cryptocurrency Mining Pool'
  document.title = title
  
  // Update meta description
  let metaDescription = document.querySelector('meta[name="description"]')
  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || 'letshash.it - A reliable cryptocurrency mining pool for various coins. Start mining with us today!')
  }
  
  // Update meta keywords
  let metaKeywords = document.querySelector('meta[name="keywords"]')
  if (metaKeywords) {
    metaKeywords.setAttribute('content', to.meta.keywords || 'cryptocurrency, mining pool, crypto mining, blockchain, letshash')
  }
  
  // Update Open Graph tags
  let ogTitle = document.querySelector('meta[property="og:title"]')
  if (ogTitle) {
    ogTitle.setAttribute('content', to.meta.title || 'letshash.it - Cryptocurrency Mining Pool')
  }
  
  let ogDescription = document.querySelector('meta[property="og:description"]')
  if (ogDescription) {
    ogDescription.setAttribute('content', to.meta.description || 'A reliable cryptocurrency mining pool for various coins. Start mining with us today!')
  }
  
  // Update Twitter tags
  let twitterTitle = document.querySelector('meta[property="twitter:title"]')
  if (twitterTitle) {
    twitterTitle.setAttribute('content', to.meta.title || 'letshash.it - Cryptocurrency Mining Pool')
  }
  
  let twitterDescription = document.querySelector('meta[property="twitter:description"]')
  if (twitterDescription) {
    twitterDescription.setAttribute('content', to.meta.description || 'A reliable cryptocurrency mining pool for various coins. Start mining with us today!')
  }
  
  // Update canonical URL
  let canonicalLink = document.querySelector('link[rel="canonical"]')
  if (canonicalLink) {
    // Remove hash from path for canonical URL
    const path = to.path === '/' ? '' : to.path
    canonicalLink.setAttribute('href', `https://letshash.it${path}`)
  }
  
  // Update og:url
  let ogUrl = document.querySelector('meta[property="og:url"]')
  if (ogUrl) {
    const path = to.path === '/' ? '' : to.path
    ogUrl.setAttribute('content', `https://letshash.it${path}`)
  }
  
  // Update twitter:url
  let twitterUrl = document.querySelector('meta[property="twitter:url"]')
  if (twitterUrl) {
    const path = to.path === '/' ? '' : to.path
    twitterUrl.setAttribute('content', `https://letshash.it${path}`)
  }
  
  // Update dynamic structured data
  updateStructuredData(to)
  
  next()
})

// Track page views in Google Analytics after navigation is complete
router.afterEach((to) => {
  // Track page view in Google Analytics
  trackPageView(to.fullPath, document.title)
})

// Function to update structured data based on current route
function updateStructuredData(route) {
  // Remove any existing dynamic structured data
  const existingScript = document.getElementById('dynamic-structured-data')
  if (existingScript) {
    existingScript.remove()
  }
  
  // Create new structured data based on the current route
  let structuredData = {}
  
  // Base path for URLs
  const basePath = 'https://letshash.it'
  const path = route.path === '/' ? '' : route.path
  const fullUrl = `${basePath}${path}`
  
  // Default structured data for all pages
  structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": fullUrl,
    "name": route.meta.title || 'letshash.it - Cryptocurrency Mining Pool',
    "description": route.meta.description || 'A reliable cryptocurrency mining pool for various coins. Start mining with us today!',
    "isPartOf": {
      "@type": "WebSite",
      "url": basePath,
      "name": "letshash.it - Cryptocurrency Mining Pool"
    }
  }
  
  // Add specific structured data based on route
  if (route.name === 'pools') {
    structuredData = {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      "url": fullUrl,
      "name": route.meta.title,
      "description": route.meta.description,
      "isPartOf": {
        "@type": "WebSite",
        "url": basePath,
        "name": "letshash.it - Cryptocurrency Mining Pool"
      }
    }
  } else if (route.name === 'howToMine') {
    structuredData = {
      "@context": "https://schema.org",
      "@type": "HowTo",
      "name": route.meta.title,
      "description": route.meta.description,
      "url": fullUrl,
      "mainEntity": {
        "@type": "HowTo",
        "name": "How to Mine Cryptocurrency",
        "description": "Step-by-step guide to start mining cryptocurrency"
      }
    }
  } else if (route.name === 'home' && route.params.coin) {
    structuredData = {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": route.meta.title,
      "description": route.meta.description,
      "url": fullUrl,
      "brand": {
        "@type": "Brand",
        "name": "letshash.it"
      },
      "category": "Cryptocurrency Mining Pool"
    }
  }
  
  // Create and append the new script element
  const script = document.createElement('script')
  script.id = 'dynamic-structured-data'
  script.type = 'application/ld+json'
  script.textContent = JSON.stringify(structuredData)
  document.head.appendChild(script)
}

export default router