var config = {

    DEFAULT_MINING_HOST: "eu.letshash.it",
    DEFAULT_MINING_HOST_ASIA: "asia.letshash.it",
    DEFAULT_MINING_HOST_US: "us.letshash.it",

    getHost() {
        return this.DEFAULT_MINING_HOST;
    },

    getHostAsia() {
        return this.DEFAULT_MINING_HOST_ASIA;
    },

    getHostUs() {
        return this.DEFAULT_MINING_HOST_US;
    },

    getConfig() {
        return {
            "0DYNC": {
                "pool": " -a argon2d -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p x",
            },
            "CHTA": {
                "pool": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CHTA",
                "solo": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CHTA,m=solo"
            },
            "DPC": {
                "pool": " -a dpowhash -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p x",
            },
            "BLACK": {
                "pool": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=BLACK",
                "solo": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=BLACK,m=solo"
            },
            "OBTC": {
                "pool": " -a heavyhash -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=OBTC",
                "solo": " -a heavyhash -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=OBTC,m=solo",

                "pool_asia": " -a heavyhash -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=OBTC",
                "solo_asia": " -a heavyhash -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=OBTC,m=solo",
            },
            "RTM": {
                "pool": " -a gr -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=RTM -d=[difficulty]",
                "solo": " -a gr -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=RTM,m=solo -d=[difficulty]"
            },
            "CURVE": {
                "pool": " -a curvehash -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CURVE",
                "solo": " -a curvehash -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CURVE,m=solo"
            },
            "BLAS": {
                "pool": " -a x16s -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=BLAS",
                "solo": " -a x16s -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=BLAS,m=solo"
            },
            "GXX": {
                "pool": " -a lyra2z330 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=GXX",
                "solo": " -a lyra2z330 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=GXX,m=solo"
            },
            "FOXD": {
                "pool": " -a x16rv2 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=FOXD -d=[difficulty]",
                "solo": " -a x16rv2 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=FOXD,m=solo -d=[difficulty]",

                "pool_asia": " -a x16rv2 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=FOXD -d=[difficulty]",
                "solo_asia": " -a x16rv2 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=FOXD,m=solo -d=[difficulty]"
            },
            "ATMN": {
                "pool": " -a phi -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ATMN -d=[difficulty]",
                "solo": " -a phi1612 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ATMN,m=solo -d=[difficulty]",

                "pool_asia": " -a phi -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ATMN -d=[difficulty]",
                "solo_asia": " -a phi -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ATMN,m=solo -d=[difficulty]"
            },
            "XBTX": {
                "pool": " -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=XBTX",
                "solo": " -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=XBTX,m=solo"
            },
            "CHIP": {
                "pool": " -a X17 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CHIP",
                "solo": " -a x17 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CHIP,m=solo"
            },
            "LNC": {
                "pool": " -a yespowerLTNCG -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=LNC",
                "solo": " -a yespowerLTNCG -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=LNC,m=solo"
            },
            "TDC": {
                "pool": " -a yespowerTIDE -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=TDC",
                "solo": " -a yespowerTIDE -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=TDC,m=solo",

                "pool_asia": " -a yespowerTIDE -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=TDC",
                "solo_asia": " -a yespowerTIDE -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=TDC,m=solo"
            },
            "ESP": {
                "pool": " -a hmq1725 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ESP",
                "solo": " -a hmq1725 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ESP,m=solo",

                "pool_asia": " -a hmq1725 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ESP",
                "solo_asia": " -a hmq1725 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ESP,m=solo",

                "pool_us": " -a hmq1725 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=ESP",
                "solo_us": " -a hmq1725 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=ESP,m=solo"
            },
            "WATC": {
                "pool": " -a gr -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=WATC",
                "solo": " -a gr -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=WATC,m=solo",

                "pool_asia": " -a gr -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=WATC",
                "solo_asia": " -a gr -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=WATC,m=solo",

                "pool_us": " -a gr -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=WATC",
                "solo_us": " -a gr -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=WATC,m=solo"
            },
            "MYNT": {
                "pool": " -a x16s -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=MYNT",
                "solo": " -a x16s -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=MYNT,m=solo",

                "pool_asia": " -a x16s -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=MYNT",
                "solo_asia": " -a x16s -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=MYNT,m=solo",

                "pool_us": " -a x16s -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=MYNT",
                "solo_us": " -a x16s -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=MYNT,m=solo"
            },
            "PPCN": {
                "pool": " -a bmw512 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=PPCN",
                "solo": " -a bmw512 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=PPCN,m=solo",

                "pool_asia": " -a bmw512 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=PPCN",
                "solo_asia": " -a bmw512 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=PPCN,m=solo"
            },
            "XDN": {
                "pool": " -a bmw512 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=XDN",
                "solo": " -a bmw512 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=XDN,m=solo",

                "pool_asia": " -a bmw512 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=XDN",
                "solo_asia": " -a bmw512 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=XDN,m=solo",

                "pool_us": " -a bmw512 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=XDN",
                "solo_us": " -a bmw512 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=XDN,m=solo"
            },
            "ADOT": {
                "pool": " -a argon2d16000 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ADOT",
                "solo": " -a argon2d16000 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ADOT,m=solo",

                "pool_asia": " -a argon2d16000 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ADOT",
                "solo_asia": " -a argon2d16000 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ADOT,m=solo",

                "pool_us": " -a argon2d16000 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=ADOT",
                "solo_us": " -a argon2d16000 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=ADOT,m=solo"
            },
            "COSA": {
                "pool": " -a cosa -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=COSA",
                "solo": " -a cosa -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=COSA,m=solo",

                "pool_asia": " -a cosa -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=COSA",
                "solo_asia": " -a cosa -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=COSA,m=solo",

                "pool_us": " -a cosa -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=COSA",
                "solo_us": " -a cosa -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=COSA,m=solo"
            },
            "CCASH": {
                "pool": " -a bmw512 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=CCASH",
                "solo": " -a bmw512 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=CCASH,m=solo",

                "pool_asia": " -a bmw512 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=CCASH",
                "solo_asia": " -a bmw512 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=CCASH,m=solo",

                "pool_us": " -a bmw512 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=CCASH",
                "solo_us": " -a bmw512 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=CCASH,m=solo"
            },
            "HCC": {
                "pool": " -a x11 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=HCC",
                "solo": " -a x11 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=HCC,m=solo",

                "pool_asia": " -a x11 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=HCC",
                "solo_asia": " -a x11 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=HCC,m=solo",

                "pool_us": " -a x11 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=HCC",
                "solo_us": " -a x11 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=HCC,m=solo"
            },
            "ZANO": {
                "pool": " -a progpowz -o stratum+tcp://[host]:[port] -u [walletaddress] --worker [workername] -p ",

                "pool_asia": " -a progpowz -o stratum+tcp://[host_asia]:[port] -u [walletaddress]--worker [workername] -p "
            },
            "SUGAR": {
                "pool": " -a yespowerSUGAR -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=SUGAR -d=[difficulty]",
                "solo": " -a yespowerSUGAR -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=SUGAR,m=solo -d=[difficulty]",

                "pool_asia": " -a yespowerSUGAR -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=SUGAR -d=[difficulty]",
                "solo_asia": " -a yespowerSUGAR -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=SUGAR,m=solo -d=[difficulty]"
            },
            "LTFN": {
                "pool": " -a yespowerr16 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=LTFN -d=[difficulty]",
                "solo": " -a yespowerr16 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=LTFN,m=solo -d=[difficulty]",

                "pool_asia": " -a yespowerr16 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=LTFN -d=[difficulty]",
                "solo_asia": " -a yespowerr16 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=LTFN,m=solo -d=[difficulty]"
            },
            "CPU": {
                "pool": " -a cpupower -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=CPU -d=[difficulty]",
                "solo": " -a cpupower -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=CPU,m=solo -d=[difficulty]",

                "pool_asia": " -a cpupower -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=CPU -d=[difficulty]",
                "solo_asia": " -a cpupower -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=CPU,m=solo -d=[difficulty]"
            },
            "PYRK": {
                "pool": " -a lyra2z330 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=PYRK -d=[difficulty]",
                "solo": " -a lyra2z330 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=PYRK,m=solo -d=[difficulty]"
            },
            "CBE": {
                "pool": " -a phi5 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CBE -d=[difficulty]",
                "solo": " -a phi5 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CBE,m=solo -d=[difficulty]"
            },
            "AGM": {
                "pool": " -a phi2 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p x",
            },
            "LUX": {
                "pool": " -a rx2 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=LUX -d=[difficulty]",
                "solo": " -a rx2 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=LUX,m=solo -d=[difficulty]",

                "pool_asia": " -a rx2 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=LUX -d=[difficulty]",
                "solo_asia": " -a rx2 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=LUX,m=solo -d=[difficulty]"
            },
            "PASC": {
                "pool": "-s stratum+tcp://[host]:[port] -su ACCOUNT.0.WORKER"
            },
            "GOLD": {
                "pool": " -a yescryptR16 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=GOLD",
                "solo": " -a yescryptR16 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=GOLD,m=solo"
            },
            "QAC": {
                "pool": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=QAC -d=[difficulty]",
                "solo": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=QAC,m=solo -d=[difficulty]"
            },
            "VPC": {
                "pool": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=VPC -d=[difficulty]",
                "solo": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=VPC,m=solo -d=[difficulty]",

                "pool_asia": " -a scrypt -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=VPC -d=[difficulty]",
                "solo_asia": " -a scrypt -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=VPC,m=solo -d=[difficulty]"
            },
            "BCC": {
                "pool": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=BCC -d=[difficulty]",
                "solo": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=BCC,m=solo -d=[difficulty]",

                "pool_asia": " -a scrypt -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=BCC -d=[difficulty]",
                "solo_asia": " -a scrypt -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=BCC,m=solo -d=[difficulty]"
            },
            "EHS": {
                "pool": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=EHS -d=[difficulty]",
                "solo": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=EHS,m=solo -d=[difficulty]",

                "pool_asia": " -a scrypt -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=EHS -d=[difficulty]",
                "solo_asia": " -a scrypt -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=EHS,m=solo -d=[difficulty]"
            },
            "WEAC": {
                "pool": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=WEAC -d=[difficulty]",
                "solo": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=WEAC,m=solo -d=[difficulty]"
            },
            "REA": {
                "pool": " -a sha256csm -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=REA -d=[difficulty]",
                "solo": " -a sha256csm -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=REA,m=solo -d=[difficulty]",

                "pool_asia": " -a sha256csm -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=REA -d=[difficulty]",
                "solo_asia": " -a sha256csm -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=REA,m=solo -d=[difficulty]"
            },
            "PA": {
                "pool": " -a sha256csm -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=PA -d=[difficulty]",
                "solo": " -a sha256csm -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=PA,m=solo -d=[difficulty]",

                "pool_asia": " -a sha256csm -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=PA -d=[difficulty]",
                "solo_asia": " -a sha256csm -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=PA,m=solo -d=[difficulty]"
            },
            "MGPC": {
                "pool": " -a yespowerMGPC -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=MGPC -d=[difficulty]",
                "solo": " -a yespowerMGPC -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=MGPC,m=solo -d=[difficulty]",

                "pool_asia": " -a yespowerMGPC -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=MGPC -d=[difficulty]",
                "solo_asia": " -a yespowerMGPC -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=MGPC,m=solo -d=[difficulty]"
            },
            "ARWN": {
                "pool": " -a yespowerARWN -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ARWN -d=[difficulty]",
                "solo": " -a yespowerARWN -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ARWN,m=solo -d=[difficulty]",

                "pool_asia": " -a yespowerARWN -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ARWN -d=[difficulty]",
                "solo_asia": " -a yespowerARWN -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ARWN,m=solo -d=[difficulty]"
            },
            "UQB": {
                "pool": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=UQB -d=[difficulty]",
                "solo": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=UQB,m=solo -d=[difficulty]"
            },
            "CTC": {
                "pool": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CTC",
                "solo": " -a scrypt -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=CTC,m=solo"
            },
            "KYAN": {
                "pool": " -a x11k -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=KYAN -d=[difficulty]",
                "solo": " -a x11k -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=KYAN,m=solo -d=[difficulty]",

                "pool_asia": " -a x11k -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=KYAN -d=[difficulty]",
                "solo_asia": " -a x11k -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=KYAN,m=solo -d=[difficulty]"
            },
            "ZENX": {
                "pool": " -a balloon -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=ZENX",
                "solo": " -a balloon -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=ZENX,m=solo"
            },
            "SCC": {
                "pool": " -a x11 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=SCC",
                "solo": " -a x11 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=SCC,m=solo"
            },
            "MOTA": {
                "pool": " -a x13 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=MOTA",
                "solo": " -a x13 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=MOTA,m=solo"
            },
            "BGEM": {
                "pool": " -a x11 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=BGEM",
                "solo": " -a x11 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=BGEM,m=solo"
            },
            "BVG": {
                "pool": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=BVG",
                "solo": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress] -p c=BVG,m=solo"
            },
            "WCN": {
                "pool": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=WCN",
                "solo": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=WCN,m=solo",

                "pool_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=WCN",
                "solo_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=WCN,m=solo"
            },
            "IBTC": {
                "pool": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=IBTC",
                "solo": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=IBTC,m=solo",

                "pool_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=IBTC",
                "solo_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=IBTC,m=solo"
            },
            "BTCV": {
                "pool": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=BTCV",
                "solo": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=BTCV,m=solo",

                "pool_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=BTCV",
                "solo_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=BTCV,m=solo"
            },
            "XRG": {
                "pool": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=XRG",
                "solo": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=XRG,m=solo",

                "pool_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=XRG",
                "solo_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=XRG,m=solo"
            },
            "TTN": {
                "pool": " -a x21s -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=TTN",
                "solo": " -a x21s -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=TTN,m=solo",

                "pool_asia": " -a x21s -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=TTN",
                "solo_asia": " -a x21s -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=TTN,m=solo",

                "pool_us": " -a x21s -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=TTN",
                "solo_us": " -a x21s -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=TTN,m=solo"
            },
            "ZUR": {
                "pool": " -a quark -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ZUR",
                "solo": " -a quark -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=ZUR,m=solo",

                "pool_asia": " -a quark -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ZUR",
                "solo_asia": " -a quark -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=ZUR,m=solo"
            },
            "BUTK": {
                "pool": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=BUTK",
                "solo": " -a sha256 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=BUTK,m=solo",

                "pool_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=BUTK",
                "solo_asia": " -a sha256 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=BUTK,m=solo"
            },
            "CRIBS": {
                "pool": " -a quark -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=CRIBS",
                "solo": " -a quark -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=CRIBS,m=solo",

                "pool_asia": " -a quark -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=CRIBS",
                "solo_asia": " -a quark -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=CRIBS,m=solo"
            },
            "SFC": {
                "pool": " -a qubit -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=SFC",
                "solo": " -a qubit -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=SFC,m=solo",

                "pool_asia": " -a qubit -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=SFC",
                "solo_asia": " -a qubit -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=SFC,m=solo",

                "pool_us": " -a qubit -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=SFC",
                "solo_us": " -a qubit -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=SFC,m=solo"
            },
            "GPBS": {
                "pool": " -a phi -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=GPBS",
                "solo": " -a phi -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=GPBS,m=solo"
            },
            "PESP": {
                "pool": " -a x11 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=PESP",
                "solo": " -a x11 -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=PESP,m=solo",

                "pool_asia": " -a x11 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=PESP",
                "solo_asia": " -a x11 -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=PESP,m=solo",

                "pool_us": " -a x11 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=PESP",
                "solo_us": " -a x11 -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=PESP,m=solo"
            },
            "RVL": {
                "pool": " -a x16r -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=RVL",
                "solo": " -a x16r -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=RVL,m=solo",
              
                "pool_asia": " -a x16r -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=RVL",
                "solo_asia": " -a x16r -o stratum+tcp://[host_asia]:[port] -u [walletaddress].[workername] -p c=RVL,m=solo",

                "pool_us": " -a x16r -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=RVL",
                "solo_us": " -a x16r -o stratum+tcp://[host_us]:[port] -u [walletaddress].[workername] -p c=RVL,m=solo"
            },
            "DYNAMO": {
                "pool": " dynminer2 -mode stratum -server eu.letshash.it -port 5966 -user [walletaddress].[workername] -pass d=2 -miner GPU",
                "solo": " dynminer2 -mode stratum -server eu.letshash.it -port 5966 -user [walletaddress].[workername] -pass d=2,m=solo -miner GPU"
            },
            "PBTC": {
                "pool": " -a heavyhash -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=pBTC",
                "solo": " -a heavyhash -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=pBTC,m=solo"
            },
            "KCN": {
                "pool": " -a sha3d -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=KCN",
                "solo": " -a sha3d -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=KCN,m=solo"
            },
            "BTM": {
                "pool": " -a ghostrider -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=BTM",
                "solo": " -a ghostrider -o stratum+tcp://[host]:[port] -u [walletaddress].[workername] -p c=BTM,m=solo"
            },
            "CXCHE": {
                "pool": " -a cn/cache_hash -o [host]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo": " -a cn/cache_hash -o [host]:[port] -u solo:[walletaddress].[difficulty] -p [workername]"
            },
            "CCX": {
                "pool": " -a cn/gpu -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/gpu -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "UPX": {
                "pool": " -a cn-upx/2 -o [host]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo": " -a cn-upx/2 -o [host]:[port] -u solo:[walletaddress].[difficulty] -p [workername]",

                "pool_asia": " -a cn-upx/2 -o [host_asia]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo_asia": " -a cn-upx/2 -o [host_asia]:[port] -u solo:[walletaddress].[difficulty] -p [workername]"
            },
            "CIRQ": {
                "pool": " -a cn-pico/trtl -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn-pico/trtl -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "CRYPTO": {
                "pool": " -a cryptonight_superfast -o [host]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo": " -a cryptonight_superfast -o [host]:[port] -u solo:[walletaddress].[difficulty] -p [workername]",

                "pool_asia": " -a cryptonight_superfast -o [host_asia]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo_asia": " -a cryptonight_superfast -o [host_asia]:[port] -u solo:[walletaddress].[difficulty] -p [workername]"
            },
            "NIMBO": {
                "pool": "-a cn-pico/trtl -o [host]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo": "-a cn-pico/trtl -o [host]:[port] -u solo:[walletaddress].[difficulty] -p [workername]"
            },
            "CRFI": {
                "pool": " -a cn/fast-o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/fast-o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "DMCH": {
                "pool": " -a cn/r -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/r -o [host]:[port] -u solo~[walletaddress] -p [workername]"
            },
            "AMX": {
                "pool": " -a cn/zls -o [host]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo": " -a cn/zls -o [host]:[port] -u solo:[walletaddress].[difficulty] -p [workername]",

                "pool_asia": " -a cn/zls -o [host_asia]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo_asia": " -a cn/zls -o [host_asia]:[port] -u solo:[walletaddress].[difficulty] -p [workername]"
            },
            "DERO": {
                "pool": "-a astrobwt -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "-a astrobwt -o [host]:[port] -u solo~[walletaddress] -p [workername]"
            },
            "ETNXP": {
                "pool": " -a cn/fast -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/fast -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "EXCC": {
                "pool": "lolMiner --coin EXCC --pool [host] --port [port] --user [walletaddress].[workername]"
            },
            "ETC": {
                "pool": "-epool stratum+tcp://[host]:[port] -esm 0 -ewal [walletaddress] -eworker [workername] -allcoins 1 -allpools 1",
                "solo": ""
            },
            "ETH": {
                "pool": "-epool stratum+tcp://[host]:[port] -esm 0 -ewal [walletaddress] -eworker [workername] -allcoins 1 -allpools 1",
                "solo": ""
            },
            "CLO": {
                "pool": "-epool stratum+tcp://[host]:[port] -esm 0 -ewal [walletaddress] -eworker [workername] -allcoins 1 -allpools 1",
                "solo": ""
            },
            "ETHO": {
                "pool": "-epool stratum+tcp://[host]:[port] -esm 0 -ewal [walletaddress] -eworker [workername] -allcoins 1 -allpools 1",
                "solo": "",

                "pool_asia": "-epool stratum+tcp://[host_asia]:[port] -esm 0 -ewal [walletaddress] -eworker [workername] -allcoins 1 -allpools 1",
                "solo_asia": ""
            },
            "MINTME": {
                "pool": "-o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "",

                "pool_asia": "-o [host_asia]:[port] -u [walletaddress] -p [workername]",
                "solo_asia": ""
            },
            "GLDX": {
                "pool": " -a cn/fast -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/fast -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "KLR": {
                "pool": " -a rx/klaro -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a rx/klaro -o [host]:[port] -u solo[walletaddress] -p [workername]"
            },

            "KVA": {
                "pool": " -a rx/keva -o [host]:15[port]24 -u [walletaddress] -p [workername]",
                "solo": " -a rx/keva -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "LTNX": {
                "pool": " -a cn/fast -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/fast -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "MSR": {
                "pool": " -a cn/half -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/half -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "NBXC": {
                "pool": " -a cn/ccx -o [host]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo": " -a cn/ccx -o [host]:[port] -u solo:[walletaddress].[difficulty] -p [workername]"
            },
            "NINJA": {
                "pool": "-a ninja -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "-a ninja -o [host]:[port] -u solo:[walletaddress] -p [workername]",

                "pool_asia": "-a ninja -o [host_asia]:[port] -u [walletaddress] -p [workername]",
                "solo_asia": "-a ninja -o [host_asia]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "XCC": {
                "pool": "-a argon2/chukwa -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "-a argon2/chukwa -o [host]:[port] -u solo:[walletaddress] -p [workername]",

                "pool_asia": "-a argon2/chukwa -o [host_asia]:[port] -u [walletaddress] -p [workername]",
                "solo_asia": "-a argon2/chukwa -o [host_asia]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "SPWN": {
                "pool": " -a cn-pico/trtl -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn-pico/trtl -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "WSBC": {
                "pool": " -a rx/arq -o [host]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo": " -a rx/arq -o [host]:[port] -u solo:[walletaddress].[difficulty] -p [workername]",

                "pool_asia": " -a rx/arq -o [host_asia]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo_asia": " -a rx/arq -o [host_asia]:[port] -u solo:[walletaddress].[difficulty] -p [workername]",

                "pool_us": " -a rx/arq -o [host_us]:[port] -u [walletaddress].[difficulty] -p [workername]",
                "solo_us": " -a rx/arq -o [host_us]:[port] -u solo:[walletaddress].[difficulty] -p [workername]"
            },
            "XEQ": {
                "pool": " -a cryptonight_gpu -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cryptonight_gpu -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "SFX": {
                "pool": " -a rx/sfx -o web.letshash.it:[port] -u [walletaddress] -p [workername]",
                "solo": " -a rx/sfx -o web.letshash.it:[port] -u solo:[walletaddress] -p [workername]"
            },
            "RYO": {
                "pool": " -a cryptonight_gpu -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cryptonight_gpu -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "XFG": {
                "pool": " -a cn/2 -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/2 -o [host]:[port] -u solo:[walletaddress] -p [workername]",
            },
            "QUE": {
                "pool": " -a rx/0 -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a rx/0 -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "XKR": {
                "pool": " -a cn-pico/trtl -o web.letshash.it:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn-pico/trtl -o web.letshash.it:[port] -u solo:[walletaddress] -p [workername]"
            },
            "DEGO": {
                "pool": " -a cryptonight-upx/2 -o web.letshash.it:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cryptonight-upx/2 -o web.letshash.it:[port] -u solo:[walletaddress] -p [workername]"
            },
            "TLO": {
                "pool": " -a cn/ultra -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/ultra -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "XLA": {
                "pool": " -a pantera -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "",

                "pool_asia": " -a pantera -o [host_asia]:[port] -u [walletaddress] -p [workername]",
                "solo_asia": ""
            },
            "XMR": {
                "pool": "-a rx/0 -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "-a rx/0 -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "WOW": {
                "pool": "-a rx/wow -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "-a rx/wow -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "XOL": {
                "pool": "-a rx/0 -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "-a rx/0 -o [host]:[port] -u solo[walletaddress] -p [workername]"
            },
            "XUNI": {
                "pool": " -a cn/ccx -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/ccx -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "ZUM": {
                "pool": " -a cn-pico/trtl -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn-pico/trtl -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "INF": {
                "pool": " -a cn/0 -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/0 -o [host]:[port] -u solo:[walletaddress] -p [workername]",

                "pool_asia": " -a cn/0 -o [host_asia]:[port] -u [walletaddress] -p [workername]",
                "solo_asia": " -a cn/0 -o [host_asia]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "SCTS": {
                "pool": " -a cn/0 -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": " -a cn/0 -o [host]:[port] -u solo:[walletaddress] -p [workername]"
            },
            "SPACE": {
                "pool": "miner --server [host] --user [walletaddress] --pass rigname --port [port]",
                "solo": ""
            },
            "VRSC": {
                "pool": " -c stratum+tcp://[host]:[port]#xnsub -u [walletaddress] -p x --cpu 1",
                "solo": ""
            },
            "RVN": {
                "pool": " -a kawpow -o stratum+tcp://[host]:[port] -u [walletaddress] -p x",
                "solo": ""
            },
            "SATO": {
                "pool": " -a kawpow -o stratum+tcp://[host]:[port] -u [walletaddress] -p x",
                "solo": ""
            },
            "HAC": {
                "pool": "pool = hacash.letshash.it:[port] <nl>\
rewards = [walletaddress] <nl>\
supervene = 8",
                "solo": "",
                "configName": "poolworker.config"
            },
            "YDA": {
                "pool": " -a rx/yada -o [host]:[port] -u [walletaddress] -p [workername]",
                "solo": "",
              
                "pool_asia": " -a rx/yada -o [host_asia]:[port] -u [walletaddress] -p [workername]",
                "solo_asia": ""
            }
        };
    }

}

export default config;
