<script setup>
import { ref, onMounted, computed } from 'vue'
import PoolApi from "@/services/api/Pool"

// Initialize coinConfig with complete structure to prevent null reference errors
const coinConfig = ref({
  ports: [],
  minerLinks: [],
  pool: {
    hasAsiaServer: false,
    hasUsServer: false
  }
})

// Loading state
const isLoading = ref(true)

// Computed properties with null checks
const ports = computed(() => coinConfig.value?.ports || [])
const minerLinks = computed(() => coinConfig.value?.minerLinks || [])

// Reactive state
const genAddress = ref("")
const genPort = ref(null)
const genType = ref("pool")
const genWorkerName = ref("")
const genCommand = ref("")
const genHashSolo = ref(false)
const genHost = ref("")
const genRegion = ref("eu")
const genHasAsia = ref(false)
const genHasUs = ref(false)
const genConfigName = ref("pool_letshash_it.bat")
const stepThree = ref(true)

// Methods
const generator = (e) => {
  e.preventDefault()
  if (!genPort.value) return

  let cmd = PoolApi.generatorConfig()?.[genType.value]
  if (genRegion.value !== "eu") {
    cmd = PoolApi.generatorConfig()?.[`${genType.value}_${genRegion.value}`]
  }

  if (cmd) {
    genCommand.value = cmd
      .replace("[host]", PoolApi.defaultHost())
      .replace("[host_asia]", PoolApi.defaultHostAsia())
      .replace("[host_us]", PoolApi.defaultHostUs())
      .replace("[port]", genPort.value.port)
      .replace("[difficulty]", genPort.value.difficulty)
      .replace("[walletaddress]", genAddress.value)
      .replace("[workername]", genWorkerName.value)
      .replace(/<nl>/g, `<br>`)
  }
}

// Setup on component creation
onMounted(async () => {
  try {
    const config = await PoolApi.coinConfig()
    coinConfig.value = {
      ...coinConfig.value,
      ...config
    }
    
    const genConfigData = PoolApi.generatorConfig()
    if (ports.value?.length > 0) {
      genPort.value = ports.value[0]
    }
    
    genHashSolo.value = !!genConfigData?.["solo"]
    genHasAsia.value = coinConfig.value.pool?.hasAsiaServer || false
    genHasUs.value = coinConfig.value.pool?.hasUsServer || false
    
    if (genConfigData?.["pool"]) {
      genCommand.value = genConfigData["pool"]
        .replace("[host]", PoolApi.defaultHost())
        .replace(/<nl>/g, `<br>`)
    }
    
    genHost.value = PoolApi.defaultHost()
    genConfigName.value = genConfigData?.["configName"] || "pool_letshash_it.bat"
    stepThree.value = !genConfigData?.["configName"]
  } catch (error) {
    console.error('Error initializing component:', error)
  } finally {
    isLoading.value = false
  }
})
</script>

<template>
  <div class="row row-cards">
    <div class="col-sm-12 col-lg-12">
      <div class="box">
        <div class="row">
          <!-- Mining Configuration Form -->
          <div class="col-sm-6 col-lg-6">
            <div class="card">
              <div class="card-status-top bg-orange"></div>
              <div class="card-header">
                <span>Generate your configuration to mine</span>
              </div>

              <form @submit.prevent="generator" class="p-3">
                <div class="input-group push-down-5">
                  <select required class="form-select" v-model="genRegion">
                    <option value="eu">Server location: Europe</option>
                    <option v-if="genHasAsia" value="asia">Server location: Asia</option>
                    <option v-if="genHasUs" value="us">Server location: US</option>
                  </select>
                </div>

                <div class="col-sm-12 col-xs-12 mb-1">
                  <div class="input-group push-down-5">
                    <select required class="form-select" v-model="genPort">
                      <option v-for="port in ports" :key="port.port" :value="port">
                        {{ port.port }} - {{ port.desc }} (starting difficulty: {{ port.difficulty }})
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12 col-xs-12 mb-1">
                  <div class="input-group push-down-5">
                    <select required class="form-select" v-model="genType">
                      <option value="pool">Pool mining</option>
                      <option v-if="genHashSolo" value="solo">Solo mining</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12 col-xs-12 mb-1">
                  <div class="input-group push-down-5">
                    <span class="input-group-addon"><i class="fa fa-key"></i></span>
                    <input
                      class="form-control"
                      v-model="genAddress"
                      type="text"
                      placeholder="Wallet address"
                      required
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-xs-12 mb-1">
                  <div class="input-group push-down-5">
                    <input
                      class="form-control"
                      v-model="genWorkerName"
                      type="text"
                      placeholder="Worker name (optional)"
                    />
                  </div>
                </div>

                <div class="col-md-12 mt-2 text-right">
                  <input
                    type="submit"
                    value="Generate configuration"
                    class="btn btn-yellow text-dark"
                  />
                </div>
              </form>
            </div>
          </div>

          <!-- Configuration Display -->
          <div class="col-sm-6 col-lg-6">
            <div class="card">
              <div class="card-status-top bg-orange"></div>
              <div class="col-md-12 mt-0">
                <div class="card-header">Miner configuration</div>
                <div class="card-body">
                  <div v-if="isLoading" class="text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <ul v-else class="list-unstyled space-y-1">
                    <li class="mb-1">
                      1.
                      <strong>Create new file with name <em>{{ genConfigName }}</em> and fill it with:</strong>
                    </li>
                    <li class="mb-3">
                      <div class="mark text-dark" v-html="genCommand"></div>
                    </li>
                    <li class="mb-3">
                      2. <strong>Download and unpack mining application</strong>
                      <ul>
                        <li v-for="config in minerLinks" :key="config.name" class="mb-1 mt-1">
                          {{ config.name }} ({{ config.arch }})
                          <a class="btn btn-sm btn-outline-primary" :href="config.url" target="_blank">download</a>
                        </li>
                      </ul>
                    </li>
                    <li class="" v-if="stepThree">
                      3.
                      <strong>Launch miner via created file <em>{{ genConfigName }}</em></strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>