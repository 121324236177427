<template>
  <page>
    <!-- Header Section -->
    <div class="row mb-5">
      <div class="col-md-12 text-center mb-4">
        <h1 class="mb-0 display-title">Welcome to Let's Hash</h1>
        <h3 class="text-muted font-weight-light subtitle">
          Cryptocurrency mining pool for beginners and professionals.
        </h3>
        <div v-if="totalMiners > 0" class="text-muted">
          <small>
            (<em>Total miners {{ totalMiners.toLocaleString() }}</em>)
          </small>
        </div>
      </div>

      <div class="col-md-5 mx-auto">
        <div class="input-icon search-wrapper">
          <input
            type="search"
            class="form-control form-control-lg text-center custom-search"
            v-model="searchQuery"
            placeholder="Looking for a coin?"
          />
          <span class="input-icon-addon">
            <i class="fe fe-search"></i>
          </span>
        </div>
      </div>
    </div>

    <!-- Coins Grid -->
    <div class="container-fluid p-0">
      <div class="row g-4">
        <template v-for="(poolData, coinSymbol) in filteredCoins" :key="coinSymbol">
          <div v-if="poolData" class="col-xl-4">
            <div class="card custom-card">
              <div class="card-body p-4">
                <div class="row g-3 align-items-center">
                  <div class="col-auto">
                    <span
                      class="avatar avatar-lg avatar-rounded custom-avatar"
                      :style="{
                      backgroundImage: `url(/coins/${PoolConfig.getCoin(coinSymbol)?.img || 'default.png'})`
                      }"
                    ></span>
                  </div>
                  <div class="col">
                    <h4 class="card-title m-0 coin-title">
                      {{ poolData.name || coinSymbol }}
                    </h4>
                    <div class="small mt-3 row g-2">
                      <div class="col-5 text-muted">
                        <small title="Pool hashrate">Hashrate:</small>
                      </div>
                      <div class="col-7">
                        <small>{{ getHashrate(coinSymbol) }}</small>
                      </div>

                      <div class="col-5 text-muted">
                        <small>Miners:</small>
                      </div>
                      <div class="col-7">
                        <small>{{ getMinersCount(coinSymbol) }}</small>
                      </div>

                      <div class="col-5 text-muted">
                        <small>Algorithm:</small>
                      </div>
                      <div class="col-7">
                        <small>{{ getAlgoIcon(poolData) }}</small>
                      </div>

                      <div class="col-5 text-muted">
                        <small>Profit:</small>
                      </div>
                      <div class="col-7">
                        <small>{{ getProfit(coinSymbol) }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-center mt-3">
                    <button
                      @click="selectCoin(coinSymbol)"
                      class="btn btn-outline-success btn-sm custom-button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon me-1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24V0H0z" fill="none"></path>
                        <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3"></path>
                        <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3"></path>
                        <circle cx="15" cy="9" r="1"></circle>
                      </svg>
                      connect
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </page>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStorage } from '@/composables/useStorage'; // Import custom storage composable
import PoolConfig from "@/config/PoolConfig";
import Format from "@/utils/format";

// Initialize storage
const storage = useStorage();

// Reactive state
const searchQuery = ref('');
const poolStats = ref({});
const totalMiners = ref(0);
const interval = ref(null);

// Access route and router
const route = useRoute();
const router = useRouter();

// Fetch API Data
const fetchPoolStats = async () => {
  try {
    const response = await fetch('https://www.letshash.it/api/live');
    if (!response.ok) throw new Error('Failed to fetch pool stats');
    const data = await response.json();
    poolStats.value = data;
    totalMiners.value = calculateTotalMiners(data);
  } catch (error) {
    console.error('Error fetching pool stats:', error);
  }
};

// Calculate Total Miners
const calculateTotalMiners = (stats) => {
  if (!stats) return 0;
  return Object.values(stats).reduce((total, poolData) => {
    return total + (poolData?.status?.miners || 0);
  }, 0);
};

// Computed: Filtered Coins
const filteredCoins = computed(() => {
  if (!poolStats.value) return {};
  const search = searchQuery.value.toLowerCase().trim();
  return Object.fromEntries(
    Object.entries(poolStats.value).filter(
      ([coinSymbol, coinData]) =>
        coinData.name.toLowerCase().includes(search) ||
        coinSymbol.toLowerCase().includes(search)
    )
  );
});

// Helper Methods
const getHashrate = (coinSymbol) => {
  const stats = poolStats.value[coinSymbol]?.status;
  const hashrate = stats?.poolHashrate || 0;
  return Format.formatHashrate(hashrate); // Use Format object
};

const getMinersCount = (coinSymbol) => {
  const stats = poolStats.value[coinSymbol]?.status;
  return stats ? stats.miners : 0;
};

const getProfit = (coinSymbol) => {
  return 'Not available'; // Placeholder, can be updated if API provides profit data
};

const getAlgoIcon = (poolData) => {
  return poolData?.algo || 'Unknown';
};

// Select a coin and navigate to its page
const selectCoin = (coinSymbol) => {
  storage.set(PoolConfig.KEY_COIN, coinSymbol); // Store the selected coin
  router.push(`/${coinSymbol.toLowerCase()}`); // Navigate to the selected coin's page
};

// Clear coin from storage on pools page
onMounted(() => {
  if (route.path === '/pools') {
    storage.set(PoolConfig.KEY_COIN, null);
  } else {
    const selectedCoin = storage.get(PoolConfig.KEY_COIN);
    if (selectedCoin) {
      router.push(`/${selectedCoin.toLowerCase()}`);
      return;
    }
  }

  fetchPoolStats();
  interval.value = setInterval(fetchPoolStats, 10000); // Update every 10 seconds
});

onUnmounted(() => {
  if (interval.value) clearInterval(interval.value);
});
</script>

<style scoped>
.display-title {
  font-weight: 600;
  letter-spacing: -0.5px;
}

.subtitle {
  font-size: 1.1rem;
  opacity: 0.8;
}

.search-wrapper {
  position: relative;
}

/* Theme-aware search styles */
.custom-search {
  border-radius: 12px;
  transition: all 0.3s ease;
}

.theme-dark .custom-search {
  background: rgba(30, 41, 59, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.theme-light .custom-search {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.theme-dark .custom-search:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1);
  background: rgba(30, 41, 59, 0.7);
}

.theme-light .custom-search:focus {
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 1);
}

/* Theme-aware card styles */
.custom-card {
  transition: all 0.3s ease;
  height: 100%;
  backdrop-filter: blur(10px);
  border-radius: 12px;
}

.theme-dark .custom-card {
  background: linear-gradient(145deg, rgba(30, 41, 59, 0.6), rgba(30, 41, 59, 0.4));
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.theme-light .custom-card {
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-card:hover {
  transform: translateY(-6px);
}

.theme-dark .custom-card:hover {
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.2);
  background: linear-gradient(145deg, rgba(30, 41, 59, 0.7), rgba(30, 41, 59, 0.5));
}

.theme-light .custom-card:hover {
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(145deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.95));
}

/* Theme-aware avatar styles */
.theme-dark .custom-avatar {
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.theme-light .custom-avatar {
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

/* Theme-aware title styles */
.theme-dark .coin-title {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.theme-light .coin-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
}

/* Theme-aware button styles */
.custom-button {
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.theme-dark .custom-button {
  background: rgba(25, 135, 84, 0.1);
  border-color: rgba(25, 135, 84, 0.3);
}

.theme-light .custom-button {
  background: rgba(25, 135, 84, 0.05);
  border-color: rgba(25, 135, 84, 0.2);
}

.theme-dark .custom-button:hover {
  background: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 0.5);
  transform: translateY(-2px);
}

.theme-light .custom-button:hover {
  background: rgba(25, 135, 84, 0.1);
  border-color: rgba(25, 135, 84, 0.4);
  transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .col-md-5 {
    width: 80%;
  }
}

/* Optional: Theme transition */
.custom-card,
.custom-search,
.coin-title,
.custom-button {
  transition: background-color 0.3s ease, 
              border-color 0.3s ease, 
              color 0.3s ease, 
              box-shadow 0.3s ease, 
              transform 0.3s ease;
}
</style>