<template>
  <page title="Profit calculator">
    <div class="card">
      <keep-alive>
        <form @submit.prevent="calculateProfits">
          <div class="card-body">
            <div class="form-group mb-1 row">
              <label class="form-label col-2 col-form-label">Hashing Power</label>
              <div class="col">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    v-model="hashPower"
                    required="required"
                  />
                  <span class="">
                    <select
                      class="form-select"
                      v-model="selectedHashUnit"
                    >
                      <option
                        v-for="unit in hashUnits"
                        :key="unit.value"
                        :value="unit.value"
                      >
                        {{ unit.name }}
                      </option>
                    </select>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group mb-1 row">
              <label class="form-label col-2 col-form-label">Power consumption (w)</label>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="(optional)"
                  autocomplete="off"
                  v-model="powerConsumption"
                />
              </div>
            </div>

            <div class="form-group mb-1 row">
              <label class="form-label col-2 col-form-label">Cost per KWh ($)</label>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="(optional)"
                  autocomplete="off"
                  v-model="powerCost"
                />
                <small class="form-hint mb-1 mt-0">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.globalpetrolprices.com/electricity_prices/"
                  >
                    The latest business and household electricity price data
                  </a>
                </small>
              </div>
            </div>

            <div class="form-group mb-1 row">
              <label class="form-label col-2 col-form-label"></label>
              <div class="col">
                <button type="submit" class="btn btn-primary m-auto">
                  Calculate
                </button>
              </div>
            </div>
          </div>
        </form>
      </keep-alive>
    </div>

    <Loader :loading="isLoading" />

    <div class="card mt-2" v-if="Object.keys(coinData).length > 0">
      <div class="card-body">
        <table class="table table-responsive table-vcenter card-table table-lg table-hover">
          <tr class="bg-dark text-white">
            <th class="bg-dark pb-3 pt-3"></th>
            <th class="pl-2">{{ coinData.symbol || 'Coin' }}</th>
            <th v-if="hasPriceData">USD</th>
            <th v-if="hasPriceData">GBP</th>
            <th v-if="hasPriceData">EUR</th>
            <th>Power Cost <small>(KWh)</small></th>
            <th>Profit ({{ coinData.symbol || 'Coin' }})</th>
          </tr>
          <tr class="bg-azure-lt">
            <td class="pt-2 pb-2">Per Day</td>
            <td>{{ formatNumber(calculatedData.coinsPerDay, 8) }}</td>
            <td v-if="hasPriceData">$ {{ formatNumber(calculatedData.usdPerDay, 2) }}</td>
            <td v-if="hasPriceData">£ {{ formatNumber(calculatedData.gbpPerDay, 2) }}</td>
            <td v-if="hasPriceData">€ {{ formatNumber(calculatedData.eurPerDay, 2) }}</td>
            <td>{{ formatNumber(calculatedData.powerCostPerDay, 2) }}</td>
            <td>{{ formatNumber(calculatedData.profitInCoins, 8) }}</td>
          </tr>
          <tr class="bg-gray">
            <td class="pt-1 pb-2">Per Week</td>
            <td>{{ formatNumber(calculatedData.coinsPerDay * 7, 8) }}</td>
            <td v-if="hasPriceData">$ {{ formatNumber(calculatedData.usdPerDay * 7, 2) }}</td>
            <td v-if="hasPriceData">£ {{ formatNumber(calculatedData.gbpPerDay * 7, 2) }}</td>
            <td v-if="hasPriceData">€ {{ formatNumber(calculatedData.eurPerDay * 7, 2) }}</td>
            <td>{{ formatNumber(calculatedData.powerCostPerDay * 7, 2) }}</td>
            <td>{{ formatNumber(calculatedData.profitInCoins * 7, 8) }}</td>
          </tr>
          <tr class="bg-azure-lt">
            <td class="pt-1 pb-2">Per Month</td>
            <td>{{ formatNumber(calculatedData.coinsPerDay * 30, 8) }}</td>
            <td v-if="hasPriceData">$ {{ formatNumber(calculatedData.usdPerDay * 30, 2) }}</td>
            <td v-if="hasPriceData">£ {{ formatNumber(calculatedData.gbpPerDay * 30, 2) }}</td>
            <td v-if="hasPriceData">€ {{ formatNumber(calculatedData.eurPerDay * 30, 2) }}</td>
            <td>{{ formatNumber(calculatedData.powerCostPerDay * 30, 2) }}</td>
            <td>{{ formatNumber(calculatedData.profitInCoins * 30, 8) }}</td>
          </tr>
          <tr class="bg-gray">
            <td class="pt-1 pb-2">Per Year</td>
            <td>{{ formatNumber(calculatedData.coinsPerDay * 365, 8) }}</td>
            <td v-if="hasPriceData">$ {{ formatNumber(calculatedData.usdPerDay * 365, 2) }}</td>
            <td v-if="hasPriceData">£ {{ formatNumber(calculatedData.gbpPerDay * 365, 2) }}</td>
            <td v-if="hasPriceData">€ {{ formatNumber(calculatedData.eurPerDay * 365, 2) }}</td>
            <td>{{ formatNumber(calculatedData.powerCostPerDay * 365, 2) }}</td>
            <td>{{ formatNumber(calculatedData.profitInCoins * 365, 8) }}</td>
          </tr>
        </table>
      </div>
    </div>
  </page>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import Loader from '@/components/sections/Loader.vue'
import Pool from '@/services/api/Pool'

const COINGECKO_API_KEY = 'M8CSHCZymRNXWdT1xH6NoFqi'
const COINGECKO_API_URL = 'https://api.coingecko.com/api/v3'

// State
const isLoading = ref(false)
const hashPower = ref('')
const powerConsumption = ref('')
const powerCost = ref('')
const selectedHashUnit = ref(1)
const coinData = ref({})
const exchangeRates = ref({
  usd: 0,
  gbp: 0,
  eur: 0
})
const hasPriceData = ref(false)

const calculatedData = ref({
  coinsPerDay: 0,
  usdPerDay: 0,
  gbpPerDay: 0,
  eurPerDay: 0,
  powerCostPerDay: 0,
  profitPerDay: 0,
  profitInCoins: 0
})

const hashUnits = [
  { value: 1, name: 'H/s' },
  { value: 1000, name: 'KH/s' },
  { value: 1000000, name: 'MH/s' },
  { value: 1000000000, name: 'GH/s' },
  { value: 1000000000000, name: 'TH/s' }
]

// Methods
const formatNumber = (value, decimals = 2) => {
  return Number(value).toFixed(decimals)
}

const fetchCoinData = async () => {
  try {
    const selectedCoin = Pool.getSelectedCoin()
    
    // Fetch mining pool data
    const poolResponse = await axios.get(`https://www.letshash.it/api/live?coin=${selectedCoin}`)
    coinData.value = poolResponse.data[selectedCoin]
    
    // Reset price data status
    hasPriceData.value = false
    exchangeRates.value = { usd: 0, gbp: 0, eur: 0 }

    // Map coin symbols to CoinGecko IDs (add more as needed)
    const coingeckoMap = {
      'BONC': 'bonk',
      'BTC': 'bitcoin',
      // Add other coins
    }

    const coingeckoId = coingeckoMap[selectedCoin]
    console.log('CoinGecko ID:', coingeckoId)

    // Try to fetch price data if we have a mapping
    if (coingeckoId) {
      try {
        const cgResponse = await axios.get(
          `${COINGECKO_API_URL}/simple/price?ids=${coingeckoId}&vs_currencies=usd,gbp,eur`,
          {
            headers: {
              'x-cg-api-key': COINGECKO_API_KEY
            }
          }
        )
        
        // Check if we have valid price data
        if (cgResponse?.data && 
            cgResponse.data[coinData.value.coingeckoId] && 
            cgResponse.data[coinData.value.coingeckoId].usd) {
          
          const prices = cgResponse.data[coinData.value.coingeckoId]
          exchangeRates.value = {
            usd: prices.usd || 0,
            gbp: prices.gbp || 0,
            eur: prices.eur || 0
          }
          hasPriceData.value = true
        }
      } catch (error) {
        console.log('Price data not available:', error)
        // Price data status remains false
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    if (error.response) {
      console.error('API Error:', error.response.data)
    }
  }
}

const calculateProfits = async () => {
  isLoading.value = true
  
  try {
    const selectedCoin = Pool.getSelectedCoin()
    console.log('Selected coin:', selectedCoin)
    
    // Fetch mining pool data
    const poolResponse = await axios.get(`https://www.letshash.it/api/live?coin=${selectedCoin}`)
    console.log('Pool API response:', poolResponse.data)
    
    if (!poolResponse.data || !poolResponse.data[selectedCoin]) {
      throw new Error('Invalid pool data response')
    }
    
    coinData.value = poolResponse.data[selectedCoin]
    console.log('Coin data:', coinData.value)
    
    // Try to get price data
    if (coinData.value?.coingeckoId) {
      try {
        const cgResponse = await axios.get(
          `${COINGECKO_API_URL}/simple/price?ids=${coinData.value.coingeckoId}&vs_currencies=usd,gbp,eur`,
          {
            headers: {
              'x-cg-api-key': COINGECKO_API_KEY
            }
          }
        )
        console.log('CoinGecko response:', cgResponse.data)
        
        if (cgResponse?.data && cgResponse.data[coinData.value.coingeckoId]) {
          const prices = cgResponse.data[coinData.value.coingeckoId]
          exchangeRates.value = {
            usd: prices.usd || 0,
            gbp: prices.gbp || 0,
            eur: prices.eur || 0
          }
          hasPriceData.value = true
        }
      } catch (error) {
        console.log('Price data not available:', error)
        hasPriceData.value = false
      }
    }
    
    // Calculate mining rewards
    const hashrate = parseFloat(hashPower.value) * selectedHashUnit.value
    console.log('Calculated hashrate:', hashrate)
    
    const networkDiff = coinData.value.status.networkDiff
    console.log('Network difficulty:', networkDiff)
    
    const blockReward = coinData.value.pool.reward || 0
    console.log('Block reward:', blockReward)
    
    const poolFee = coinData.value.pool.fees / 100 // Divide by 100 at initial assignment
    console.log('Pool fee:', poolFee)
    
    // Calculate daily coins
    const secondsInDay = 86400
    const dailyCoins = (hashrate * secondsInDay * blockReward) / (networkDiff * Math.pow(2, 32))
    console.log('Daily coins before fee:', dailyCoins)
    
    const dailyCoinsAfterFee = dailyCoins * (1 - poolFee)
    console.log('Daily coins after fee:', dailyCoinsAfterFee)
    
    // Calculate power costs if provided
    const powerCostPerDay = powerConsumption.value && powerCost.value
      ? (parseFloat(powerConsumption.value) * 24 * parseFloat(powerCost.value)) / 1000
      : 0
    console.log('Power cost per day:', powerCostPerDay)
    
    // Update calculated data
    if (hasPriceData.value) {
      calculatedData.value = {
        coinsPerDay: dailyCoinsAfterFee,
        usdPerDay: dailyCoinsAfterFee * exchangeRates.value.usd,
        gbpPerDay: dailyCoinsAfterFee * exchangeRates.value.gbp,
        eurPerDay: dailyCoinsAfterFee * exchangeRates.value.eur,
        powerCostPerDay: powerCostPerDay,
        profitInCoins: dailyCoinsAfterFee,
        profitPerDay: (dailyCoinsAfterFee * exchangeRates.value.usd) - powerCostPerDay
      }
    } else {
      calculatedData.value = {
        coinsPerDay: dailyCoinsAfterFee,
        powerCostPerDay: powerCostPerDay,
        profitInCoins: dailyCoinsAfterFee
      }
    }
    
    console.log('Final calculated data:', calculatedData.value)
    
  } catch (error) {
    console.error('Error in calculation:', error)
    if (error.response) {
      console.error('API Error response:', error.response.data)
    }
  } finally {
    isLoading.value = false
  }
}

  // Lifecycle
onMounted(async () => {
  try {
    console.log('Component mounted')
    const selectedCoin = Pool.getSelectedCoin()
    console.log('Selected coin on mount:', selectedCoin)
    
    // Fetch initial coin data
    if (selectedCoin) {
      const response = await axios.get(`https://www.letshash.it/api/live?coin=${selectedCoin}`)
      console.log('Initial pool data:', response.data)
      coinData.value = response.data[selectedCoin]
    }
  } catch (error) {
    console.error('Error during component mount:', error)
  }
})
</script>