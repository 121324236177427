import PoolApi from "@/services/api/Pool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const localStorage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        try {
            const url = pool.endpoints.wallet.replace('{address}', address);
            const response = await axios.get(url);
            
            if (response.data.error === undefined) {
                return this.parseWalletInfo(response);
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletInfo:', error);
            return { error: 'Failed to fetch wallet info' };
        }
    },

    async getStats(pool) {
        try {
            const url = pool.endpoints.stats;
            let response = await axios.get(url);
            response = await this.parseStats(response);
            response = await this.parsePayments(pool, response);
            return await this.parseBlocks(pool, response);
        } catch (error) {
            console.error('Error in getStats:', error);
            throw error;
        }
    },

    parseWalletInfo(response) {
        const stats = {};
        const workers = [];
        const responseObj = response.data;

        stats.lastShare = responseObj.totalShares;
        stats.balance = `${responseObj.balance} ${PoolApi.getSelectedCoin()}`;
        stats.paid = `${responseObj.paid} ${PoolApi.getSelectedCoin()}`;

        if (responseObj.workers) {
            Object.values(responseObj.workers).forEach(worker => {
                workers.push({
                    name: worker.name,
                    hashrate: worker.hashrateString,
                    lastShare: worker.currRoundShares,
                });
            });
        }

        stats.workers = workers;
        stats.hashes = workers.length > 0 ? workers[0].hashrate : 0;
        stats.payments = [];

        return stats;
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        const responseObj = response.data.pools[coin.apikey];
        const hashrateHistory = [];

        stats.miners = responseObj.minerCount || 0;
        stats.soloMiners = 0;
        stats.workers = responseObj.workerCount;
        stats.soloWorkers = 0;
        stats.poolHashRate = responseObj.hashrateString;
        stats.poolSoloHashRate = 0;
        stats.poolFee = coin.pool.fee;
        stats.poolMinimumPayout = coin.pool.minPayout;
        stats.networkDifficulty = responseObj.poolStats.networkDiff;
        stats.blockHeight = responseObj.poolStats.networkBlocks;
        stats.blockLastFound = responseObj.lastblock;
        stats.hashRateHistory = hashrateHistory;
        stats.blocks = [];
        stats.payments = [];
        stats.config = responseObj;
        stats.reward = 0;

        localStorage.set("config", responseObj);

        return stats;
    },

    async parseBlocks(pool, response) {
        try {
            const blockUrl = pool.endpoints.blocks;
            const blocks = await axios.get(blockUrl);
            const coin = PoolApi.coinConfig();
            response.blocks = this.parseBlocksString(blocks, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parseBlocks:', error);
            throw error;
        }
    },

    parseBlocksString(blocks, response, coin) {
        const payments = [];
        const responseObj = blocks.data?.pools?.[coin.apikey];

        if (responseObj) {
            // Handle pending blocks
            responseObj.pending.blocks.forEach((block, index) => {
                const parts = block.split(':');
                payments.push({
                    time: index,
                    hash: parts[0],
                    height: parts[2],
                    amount: '',
                    rawtime: index,
                    difficulty: '-',
                    shares: '',
                    orphaned: '',
                    reward: '-',
                    luck: '',
                    status: 'pending',
                    solo: '',
                    effort: '',
                    minerAddress: ''
                });
            });

            // Handle confirmed blocks
            responseObj.confirmed.blocks.forEach((block, index) => {
                const parts = block.split(':');
                payments.push({
                    time: index + 15,
                    hash: parts[0],
                    height: parts[2],
                    amount: '',
                    rawtime: index,
                    difficulty: '-',
                    shares: '',
                    orphaned: '',
                    reward: 'PAID',
                    luck: '',
                    status: 'confirmed',
                    solo: '',
                    effort: '',
                    minerAddress: ''
                });
            });
        }

        return payments;
    },

    async parsePayments(pool, response) {
        try {
            const timestamp = Math.round(Date.now() / 1000);
            const paymentUrl = pool.endpoints.payments.replace('{time}', timestamp);
            const payments = await axios.get(paymentUrl);
            const coin = PoolApi.coinConfig();
            
            response.payments = this.parsePaymentsString(payments, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parsePayments:', error);
            throw error;
        }
    },

    parsePaymentsString(payments, response, coin) {
        const paymentsList = [];
        const responseObj = payments.data;
        const config = localStorage.get("config");

        if (responseObj) {
            responseObj.forEach(poolData => {
                if (poolData.name === config.name && poolData.payments) {
                    poolData.payments.forEach((parts, index) => {
                        const payment = {
                            time: Format.formatDate(parseInt(parts.time) / 1000),
                            hash: parts.txid,
                            amount: `${parts.paid.toLocaleString('fullwide', { useGrouping: false })} ${PoolApi.getSelectedCoin()}`,
                            fee: coin.pool.fee,
                            mixin: '-',
                            recipients: Object.keys(parts.amounts).length,
                            payees: parts.amounts.length,
                            rawtime: parseInt(parts.time) / 1000,
                            id: index
                        };

                        paymentsList.push(payment);
                    });

                    paymentsList.sort((a, b) => b.rawtime - a.rawtime);
                }
            });
        }

        return paymentsList;
    }
};