import PoolApi from "@/services/api/Pool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const localStorage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        try {
            const url = pool.endpoints.wallet.replace('{address}', address);
            const response = await axios.get(url);
            
            if (response.data.error === undefined) {
                return this.parseWalletInfo(response);
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletInfo:', error);
            return { error: 'Failed to fetch wallet info' };
        }
    },

    async getStats(pool) {
        try {
            const url = pool.endpoints.stats;
            let response = await axios.get(url);
            response = await this.parseStats(response);
            return await this.parsePayments(pool, response);
        } catch (error) {
            console.error('Error in getStats:', error);
            throw error;
        }
    },

    parseWalletInfo(response) {
        const stats = {};
        const workers = [];
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;

        Object.assign(stats, responseObj);

        // Convert miners array to workers array
        responseObj.miners?.forEach(miner => {
            workers.push({
                name: miner.Id,
                hashrate: Format.getReadableHashRateString(miner.Hashrate),
                lastShare: Format.timeAgo(miner.LastBeat),
                isSolo: miner.IsSolo,
                status: miner.Offline,
            });
        });

        stats.workers = workers;
        return stats;
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;
        const hashrateHistory = [];
        const blocks = [];

        // Parse hashrate history
        if (responseObj.charts?.hashrate) {
            responseObj.charts.hashrate.forEach(item => {
                hashrateHistory.push(item[1]);
            });
        }

        // Parse blocks
        if (responseObj.matured) {
            responseObj.matured.forEach(parts => {
                const block = {
                    height: parseInt(parts.Height),
                    hash: parts.Hash,
                    time: Format.formatDate(parts.Timestamp),
                    difficulty: parseInt(parts.Difficulty),
                    shares: parseInt(parts.TotalShares),
                    orphaned: parts.Orphan,
                    reward: parts.Reward,
                    luck: 0,
                    rawtime: parseInt(parts.Timestamp)
                };

                block.status = block.orphaned ? 'orphaned' : 'pending';
                if (block.orphaned) {
                    block.reward = 0;
                }

                if (block.reward > 0) {
                    block.reward = Format.getReadableCoins(coin, block.reward, null, true, responseObj.config.coinUnits) + 
                                 ' ' + PoolApi.getSelectedCoin();
                }

                blocks.push(block);
            });

            blocks.sort((a, b) => b.rawtime - a.rawtime);
        }

        // Set stats
        stats.miners = responseObj.totalPoolMiners;
        stats.soloMiners = responseObj.totalSoloMiners;
        stats.workers = 0;
        stats.soloWorkers = 0;
        stats.poolHashRate = Format.getReadableHashRateString(responseObj.poolHashrate);
        stats.poolSoloHashRate = Format.getReadableHashRateString(responseObj.soloHashrate);
        stats.poolFee = coin.pool.fee;
        stats.poolMinimumPayout = coin.pool.minPayout;
        stats.networkDifficulty = responseObj.lastblock.Difficulty;
        stats.blockHeight = responseObj.lastblock.Height;
        stats.blockLastFound = responseObj.lastblock.Timestamp;
        stats.hashRateHistory = hashrateHistory;
        stats.blocks = blocks;
        stats.payments = [];
        stats.config = responseObj.config;
        stats.reward = responseObj.lastblock.Reward;

        localStorage.set("config", stats.config);

        return stats;
    },

    async parsePayments(pool, response) {
        try {
            const timestamp = Math.round(Date.now() / 1000);
            const paymentUrl = pool.endpoints.payments.replace('{time}', timestamp);
            const payments = await axios.get(paymentUrl);
            const coin = PoolApi.coinConfig();
            
            response.payments = this.parsePaymentsString(payments, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parsePayments:', error);
            throw error;
        }
    },

    parsePaymentsString(payments, response, coin) {
        const paymentsList = [];
        const responseObj = payments.data.payments;

        if (responseObj) {
            const config = localStorage.get("config");
            
            responseObj.forEach((parts, index) => {
                const payment = {
                    time: Format.formatDate(parseInt(parts.Timestamp)),
                    hash: parts.Hash,
                    amount: parts.Amount.toLocaleString('fullwide', { useGrouping: false }),
                    fee: parts.Fee.toLocaleString('fullwide', { useGrouping: false }),
                    mixin: parts.Mixin,
                    recipients: parts.Payees,
                    payees: parts.Payees,
                    rawtime: parseInt(parts.Timestamp),
                    id: index
                };

                payment.amount = Format.getReadableCoins(coin, parseInt(payment.amount), null, true, config.coinUnits) + 
                                ' ' + PoolApi.getSelectedCoin();
                payment.fee = Format.getReadableCoins(coin, payment.fee, null, true, config.coinUnits) + 
                             ' ' + PoolApi.getSelectedCoin();

                paymentsList.push(payment);
            });

            paymentsList.sort((a, b) => b.rawtime - a.rawtime);
        }

        return paymentsList;
    }
};