import PoolApi from "@/services/api/Pool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const localStorage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        try {
            const url = pool.endpoints.wallet.replace('{address}', address);
            const response = await axios.get(url);
            
            if (response.data.error === undefined) {
                return this.parseWalletInfo(response);
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletInfo:', error);
            return { error: 'Failed to fetch wallet info' };
        }
    },

    async getStats(pool) {
        try {
            const url = pool.endpoints.stats;
            let response = await axios.get(url);
            response = await this.parseStats(response);
            response = await this.parseLastBlock(pool, response);
            response = await this.parseHashrate(pool, response);
            response = await this.getTop10(pool, response);
            response = await this.parseBlocks(pool, response);
            return await this.parsePayments(pool, response);
        } catch (error) {
            console.error('Error in getStats:', error);
            throw error;
        }
    },

    async getTop10(pool, response) {
        if (!pool.endpoints.top10) {
            return response;
        }

        try {
            const url = pool.endpoints.top10;
            const responseTop = await axios.get(url);
            return this.parseTop10(responseTop, response);
        } catch (error) {
            console.error('Error in getTop10:', error);
            return response;
        }
    },

    parseTop10(responseTop, response) {
        const stats = [];
        let power = 0;
        let miners = 0;
        let workers = 0;
        const responseObj = responseTop.data.datalist;

        if (responseObj?.length > 0) {
            responseObj.forEach(miner => {
                stats.push({
                    miner: miner.address,
                    hashrate: Format.getReadableHashRateString(miner.realtime_power / 1000),
                    lastShare: 'N/A',
                    hashes: `blocks found: ${miner.find_blocks}, coins earned: ${miner.find_coins}`,
                });
                power += miner.realtime_power;
                workers += miner.clients;
            });

            miners = responseObj.length;
        }

        response.miners = miners;
        response.workers = workers;
        response.poolHashRate = Format.getReadableHashRateString(power / 1000);
        response.top10 = stats;

        return response;
    },

    async parseLastBlock(pool, response) {
        try {
            const blockUrl = pool.endpoints.lastblocks;
            const blocks = await axios.get(blockUrl);
            response.blockHeight = blocks.data.height;
            response.networkDifficulty = 'N/A';
            return response;
        } catch (error) {
            console.error('Error in parseLastBlock:', error);
            throw error;
        }
    },

    async parseHashrate(pool, response) {
        try {
            const hashrateUrl = pool.endpoints.hashrate;
            const hashrate = await axios.get(hashrateUrl);
            response.networkHashrate = hashrate.data.current_hashrate;
            response.networkDifficulty = 'N/A';
            return response;
        } catch (error) {
            console.error('Error in parseHashrate:', error);
            throw error;
        }
    },

    parseWalletInfo(response) {
        const stats = {};
        const workers = [];
        const responseObj = response.data.datalist[0] || response.data.datalist;

        const balance = responseObj.deserved_and_unconfirmed_rewards.split(':');
        const paid = responseObj.complete_rewards.split(':');

        stats.lastShare = '-';
        stats.balance = (parseInt(balance[0].substring(1).replace(/,/g, '')) / 100000000).toFixed(8);
        stats.paid = (parseInt(paid[0].substring(1).replace(/,/g, '')) / 100000000).toFixed(8);

        workers.push({
            name: `connected clients: ${responseObj.clients}`,
            hashrate: Format.getReadableHashRateString(responseObj.realtime_power / 1000),
            lastShare: `blocks found: ${responseObj.find_blocks}, coins earned: ${responseObj.find_coins}`,
        });

        stats.workers = workers;
        stats.hashes = Format.getReadableHashRateString(responseObj.realtime_power / 1000);
        stats.payments = false;

        return stats;
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        const selectedCoin = PoolApi.getSelectedCoin().toUpperCase();
        const responseObj = response.data;
        const hashrateHistory = [];

        stats.miners = responseObj.total_addresses || 0;
        stats.soloMiners = 0;
        stats.workers = responseObj.total_clients || 0;
        stats.soloWorkers = 0;
        stats.poolHashRate = 'N/A';
        stats.poolSoloHashRate = 0;
        stats.poolFee = coin.pool.fee;
        stats.poolMinimumPayout = coin.pool.minPayout;
        stats.networkDifficulty = '';
        stats.blockHeight = '';
        stats.blockLastFound = '';
        stats.hashRateHistory = hashrateHistory;
        stats.blocks = [];
        stats.payments = [];
        stats.config = responseObj;
        stats.reward = 0;

        localStorage.set("config", responseObj);

        return stats;
    },

    async parseMiners(pool, response) {
        try {
            const minersUrl = pool.endpoints.miners;
            const miners = await axios.get(minersUrl);
            let power = 0, totalMiners = 0, workers = 0;

            if (miners.data.datalist?.length > 0) {
                miners.data.datalist.forEach(miner => {
                    power += miner.realtime_power;
                    workers += miner.clients;
                });
                totalMiners = miners.data.datalist.length;
            }

            response.poolHashRate = power;
            response.miners = totalMiners;
            response.workers = workers;

            return response;
        } catch (error) {
            console.error('Error in parseMiners:', error);
            throw error;
        }
    },

    async parseBlocks(pool, response) {
        try {
            const blockUrl = pool.endpoints.blocks.replace('{block}', parseInt(response.blockHeight));
            const blocks = await axios.get(blockUrl);
            const coin = PoolApi.coinConfig();
            response.blocks = this.parseBlocksString(blocks, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parseBlocks:', error);
            throw error;
        }
    },

    parseBlocksString(blocks, response, coin) {
        const payments = [];
        const responseObj = blocks.data.datas;
        const config = localStorage.get("config");

        if (responseObj) {
            response.networkDifficulty = responseObj[0]?.bits || 0;

            responseObj.forEach((parts, index) => {
                const payment = {
                    time: Format.formatDate(parseInt(parts.time)),
                    hash: parts.hash,
                    height: parts.height,
                    amount: '',
                    rawtime: parseInt(parts.time),
                    difficulty: parts.bits,
                    shares: '',
                    orphaned: '',
                    reward: `${parts.rewards.amount.split(':')[0].replace('ㄜ', '')} ${PoolApi.getSelectedCoin()}`,
                    luck: '',
                    status: `Txs: ${parts.txs}`,
                    minerAddress: `${parts.rewards.address.substring(0, 10)}..., message: ${parts.rewards.message}`,
                    ourBlock: parts.rewards.message.includes("letshash"),
                    id: index
                };

                payment.amount = `${payment.amount} ${PoolApi.getSelectedCoin()}`;
                payment.fee = `${payment.fee ? Format.getReadableCoins(coin, payment.fee, null, true, config.coinUnits) : 0} ${PoolApi.getSelectedCoin()}`;

                response.top10?.forEach(topMiner => {
                    if (topMiner.miner.includes(parts.rewards.address)) {
                        payment.ourBlock = true;
                    }
                });

                payments.push(payment);
            });

            payments.sort((a, b) => b.rawtime - a.rawtime);
        }

        return payments;
    },

    async parsePayments(pool, response) {
        try {
            const paymentUrl = pool.endpoints.payments;
            const payments = await axios.get(paymentUrl);
            const coin = PoolApi.coinConfig();
            response.payments = this.parsePaymentsString(payments, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parsePayments:', error);
            throw error;
        }
    },

    parsePaymentsString(payments, response, coin) {
        const paymentsList = [];
        const responseObj = payments.data;

        if (responseObj) {
            responseObj.forEach(parts => {
                const amount = parts[3].split(':');
                if (parts[1] === '1C2nuvhfqbNyWqDMMUWa74UfApLrGrt6Ur') {
                    const payment = {
                        time: Format.formatDate(parseInt(parts[4])),
                        hash: parts[2],
                        amount: `${(parseInt(amount[0].substring(1).replace(/,/g, '')) / 100000000).toFixed(8)} ${PoolApi.getSelectedCoin()}`,
                        fee: coin.pool.fee,
                        mixin: '',
                        recipients: 1,
                        payees: 1,
                        rawtime: parseInt(parts[4])
                    };
                    paymentsList.push(payment);
                }
            });

            paymentsList.sort((a, b) => b.rawtime - a.rawtime);
        }

        return paymentsList;
    }
};