<template>
  <page>
    <div class="page-header">
      <div class="row">
        <!-- Coin Info Card -->
        <div class="col-xs-6 col-sm-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <div class="row g-2 align-items-center">
                <div class="col-auto">
                  <span
                    class="avatar avatar-rounded"
                    :style="{
                      backgroundImage: `url(/coins/${coinConfig?.img || 'default.png'})`
                    }"
                  ></span>
                </div>
                <div class="col">
                  <h3 class="mb-0">
                    {{ coinConfig?.name || "N/A" }}
                  </h3>
                  <div class="text-muted text-h5">
                    <Activity class="icon icon-sm me-1" />
                    Pool status:
                    <span v-if="isPoolOnline" class="text-green">
                      <CheckCircle class="icon icon-sm me-1" />
                      online
                    </span>
                    <span v-else class="text-red">
                      <XCircle class="icon icon-sm me-1" />
                      offline
                    </span>
                  </div>
                  <div class="text-muted text-h5" v-if="coinPrice">
                    <DollarSign class="icon icon-sm me-1" />
                    {{ coinConfig?.symbol || storage.get(PoolConfig.KEY_COIN).toUpperCase() }} Price (USD): {{ coinPrice }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Links Cards -->
        <div
          class="col-xs-6 col-sm-6 col-lg-4"
          v-for="link in coinConfig?.links || []"
          :key="link.url"
        >
          <div class="card">
            <div class="card-body">
              <div class="row g-2 align-items-center">
                <div class="col-auto">
                  <span class="avatar avatar-rounded">
                    <Github v-if="link.icon === 'github'" class="icon" />
                    <Link v-else-if="link.icon === 'website'" class="icon" />
                  </span>
                </div>
                <div class="col">
                  <h3 class="mb-0">
                    {{ link.title }}
                  </h3>
                  <div class="text-muted text-h5">
                    <a
                      class=""
                      target="_blank"
                      rel="noopener noreferrer"
                      :href="link.url + `?ref=letshash.it`"
                    >
                      {{ link.url }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loader v-bind:loading="loading" />

    <!-- Charts and Stats -->
    <div class="row row-deck row-cards">
      <!-- Hashrate Chart -->
      <div class="col-sm-12 col-lg-12">
        <div class="card">
          <div class="card-header">
            <TrendingUp class="icon icon-sm me-1" />
            Pool Hashrate
          </div>
          <HashRateChart
            title="Pool Hashrate"
            :data-fetch-function="fetchHashrateData"
            :live-fetch-function="fetchLiveHashrate"
            :refresh-interval="90000"
            :force-update="true"
          />
        </div>
      </div>

      <!-- Grid Stats -->
      <div class="col-sm-12 col-lg-12">
        <grid-row cards>
          <grid-col
            v-for="stat in stats"
            :key="stat.label"
            xs="3"
            sm="3"
            :lg="stat.lg"
          >
            <stamp-card
              :color="stat.color"
              :footer="stat.total"
            >
              <component :is="stat.icon" class="icon icon-sm me-1" />
              {{ stat.label }}
            </stamp-card>
          </grid-col>
        </grid-row>
      </div>

      <!-- Miner Info -->
      <div class="col-sm-12 col-lg-12">
        <MinerInfo></MinerInfo>
      </div>
    </div>

    <!-- Top 10 Miners Table -->
    <div class="row" v-if="poolStats.top10">
      <div class="col-sm-12 col-lg-12 mt-3">
        <card>
          <card-header>
            <Users class="icon icon-sm me-2" />
            Top 10 miners
          </card-header>
          <div class="table-responsive">
            <t-table class="table card-table">
              <table-head>
                <table-row>
                  <table-cel header="true">#</table-cel>
                  <table-cel header="true">Miner</table-cel>
                  <table-cel header="true">Hash rate</table-cel>
                  <table-cel header="true">Last share submitted</table-cel>
                  <table-cel header="true">Total hashes submitted</table-cel>
                </table-row>
              </table-head>
              <table-body>
                <table-row
                  v-for="(block, index) in poolStats.top10"
                  :key="block.hash"
                >
                  <table-cel>{{ index + 1 }}</table-cel>
                  <table-cel>
                    {{ block.miner }}
                    <span v-if="index === 0" title="Top miner, nice!">
                      <Award class="icon icon-sm text-yellow" />
                    </span>
                  </table-cel>
                  <table-cel>{{ block.hashrate }}</table-cel>
                  <table-cel>{{ block.lastShare }}</table-cel>
                  <table-cel>{{ block.hashes }}</table-cel>
                </table-row>
              </table-body>
            </t-table>
          </div>
        </card>
      </div>
    </div>
  </page>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStorage } from "@/composables/useStorage";
import PoolConfig from "@/config/PoolConfig";
import PoolApi from "@/services/api/Pool";
import StatsApi from "@/services/api/Stats";
import MinerInfo from "@/components/sections/MinerInfo.vue";
import Loader from "@/components/sections/Loader.vue";
import Format from "@/utils/format";
import axios from "axios";
import {
  Activity,
  CheckCircle,
  XCircle,
  Github,
  Link,
  TrendingUp,
  Users,
  Award,
  Cpu,
  Zap,
  Globe,
  Layers,
  BarChart2,
  Code,
  Percent,
  DollarSign,
  Clock,
} from "lucide-vue-next";
import HashRateService from "@/services/HashRateService";
import HashRateChart from "@/components/charts/HashRateChart.vue";

const API_BASE_URL = "https://www.letshash.it"; // Live API URL
const EXBITRON_API_URL = "https://api.exbitron.digital/api/v1/trading/info"; // Exbitron API URL

// State
const router = useRouter();
const storage = useStorage();
const coinConfig = ref(PoolApi.coinConfig());
const poolStats = ref({});
const stats = ref([]);
const loading = ref(false);
const interval = ref(null);
const coinPrice = ref(null); // State for coin price

// Fetch chart data dynamically
const fetchHashrateData = async (period = '1h') => {
  try {
    const data = await HashRateService.fetchHashrateData(PoolApi.getSelectedCoin(), period);
    return data;
  } catch (error) {
    console.error('Error in fetchHashrateData:', error);
    // Return empty array to trigger fallback in chart component
    return [];
  }
};

// Fetch coin price from Exbitron API
const fetchCoinPrice = async () => {
  try {
    const symbol = storage.get(PoolConfig.KEY_COIN).toUpperCase();
    if (!symbol) {
      console.error("Coin symbol is not defined in localStorage.");
      return;
    }

    const response = await axios.get(EXBITRON_API_URL);
    const markets = response.data.markets;
    
    // Find the market with the matching base_currency_id
    const market = markets.find(m => m.base_currency_id === symbol);
    
    if (market && market.marketDynamics && market.marketDynamics.lastPrice) {
      coinPrice.value = market.marketDynamics.lastPrice;
    } else {
      console.warn(`No price data found for ${symbol}`);
      // Don't set to null, keep the previous value if it exists
    }
  } catch (error) {
    console.error("Error fetching coin price:", error.message);
    // Don't set to null, keep the previous value if it exists
  }
};

// Fetch live hashrate data
const fetchLiveHashrate = async () => {
  try {
    const data = await HashRateService.fetchLiveHashrate(PoolApi.getSelectedCoin());
    return data;
  } catch (error) {
    console.error('Error in fetchLiveHashrate:', error);
    return 0;
  }
};

// Computed property for pool online status
const isPoolOnline = computed(() => {
  return (
    poolStats.value &&
    (poolStats.value.miners > 0 ||
      poolStats.value.networkHashrate > 0 ||
      poolStats.value.blockHeight > 0)
  );
});

// Grid stats preparation
const gridStats = (stats) => {
  const defaultIcon = Cpu; // Fallback icon if none is provided
  return [
    {
      label: "Connected Miners",
      icon: Users || defaultIcon,
      color: "green",
      total: `${stats.miners}${stats.soloMiners > 0 ? ` / ${stats.soloMiners} solo` : ""}`,
      lg: 2,
    },
    {
      label: "Connected Workers",
      icon: Cpu || defaultIcon,
      color: "green",
      total: `${stats.workers}${stats.soloWorkers > 0 ? ` / ${stats.soloWorkers} solo` : ""}`,
      lg: 2,
    },
    {
      label: "Pool Hashrate",
      icon: Zap || defaultIcon,
      color: "green",
      total: `${stats.poolHashRate}${stats.poolSoloHashRate ? ` / ${stats.poolSoloHashRate} solo` : ""}`,
      lg: 2,
    },
    {
      label: "Network Hashrate",
      icon: Globe || defaultIcon,
      color: "green",
      total: Format.getReadableHashRateString(stats.networkHashrate || 0, coinConfig.value),
      lg: 2,
    },
    {
      label: "Block height",
      icon: Layers || defaultIcon,
      color: "blue",
      total: String(stats.blockHeight),
      lg: 2,
    },
    {
      label: "Network difficulty",
      icon: BarChart2 || defaultIcon,
      color: "blue",
      total: formatDifficulty(stats.networkDifficulty),
      lg: 2,
    },
    {
      label: "Algo",
      icon: Code || defaultIcon,
      color: "purple",
      total: String(coinConfig.value.algo),
    },
    {
      label: "Pool Fee",
      icon: Percent || defaultIcon,
      color: "yellow",
      total: String(stats.poolFee),
    },
    {
      label: "Minimum Payout",
      icon: DollarSign || defaultIcon,
      color: "green",
      total: String(coinConfig.value.pool.minPayout),
    },
    {
      label: "Payment Interval",
      icon: Clock || defaultIcon,
      color: "orange",
      total: String(coinConfig.value.pool.payInterval),
    },
  ];
};

// Helper function to format difficulty values properly
const formatDifficulty = (difficulty) => {
  if (difficulty === undefined || difficulty === null) {
    return "N/A";
  }
  
  // Convert to number if it's a string
  const diffValue = typeof difficulty === 'string' ? parseFloat(difficulty) : difficulty;
  
  // Handle small numbers (less than 1)
  if (diffValue < 1 && diffValue > 0) {
    return diffValue.toFixed(6);
  }
  
  // Handle regular numbers
  return Format.formatDifficulty(diffValue);
};

// Fetch all data and initialize stats
const fetchData = () => {
  loading.value = true;
  PoolApi.callStats(
    (response) => {
      // Create a new object to ensure reactivity
      const updatedStats = { ...response };
      
      StatsApi.poolGeneralStats(
        (responseGeneral) => {
          const symbol = storage.get(PoolConfig.KEY_COIN).toUpperCase();

          if (responseGeneral[symbol]?.status.networkHeight > 0 || responseGeneral[symbol]?.status.networkHashrate > 0) {
            updatedStats.networkDifficulty = responseGeneral[symbol].status.networkDiff;
            updatedStats.networkHashrate = responseGeneral[symbol].status.networkHashrate;
          }
          
          // Update poolStats with the new data
          poolStats.value = updatedStats;
          
          // Update the grid stats with the new data
          stats.value = gridStats(poolStats.value);
        },
        (error) => {
          console.error("Error fetching general stats:", error);
          // Still update poolStats and grid stats with what we have
          poolStats.value = updatedStats;
          stats.value = gridStats(poolStats.value);
        }
      );

      fetchHashrateData(); // Fetch chart data
      fetchCoinPrice(); // Fetch coin price
      loading.value = false;
    },
    (error) => {
      console.error("API Error:", error);
      loading.value = false;
    }
  );
};

// Lifecycle hooks
onMounted(() => {
  nextTick(() => {
    fetchData(); // Fetch initial data
    
    // Create separate intervals for hashrate and price updates
    // This ensures one failing doesn't affect the other
    interval.value = setInterval(fetchData, 90000); // Update data every 90 seconds
    
    // Create a separate interval for price updates
    const priceInterval = setInterval(fetchCoinPrice, 90000); // Update price every 90 seconds
    
    // Create a separate interval for live hashrate updates
    const liveHashrateInterval = setInterval(() => {
      fetchLiveHashrate();
    }, 90000); // Update live hashrate every 90 seconds
    
    // Store the interval references for cleanup
    interval.priceInterval = priceInterval;
    interval.liveHashrateInterval = liveHashrateInterval;
  });
});

onBeforeUnmount(() => {
  if (interval.value) clearInterval(interval.value);
  if (interval.priceInterval) clearInterval(interval.priceInterval);
  if (interval.liveHashrateInterval) clearInterval(interval.liveHashrateInterval);
});
</script>

