var config = {

  KEY_COIN: "coin",
  KEY_THEME: "themeBg",

  getNotifications() {
    return [
      { label: "Mintme,Arowanacoin,DigitalNote,Espers pools added", time: "15.06.2021" },
      { label: "Added pools for Antimony (phi1612), Cribs (argon2d4096), Zurcoin (quark)", time: "24.05.2021" },
      { label: "Ergon sha256 pool added", time: "04.05.2021" },
      /*{ label: "Uplexa Cryptonight-UPX/2 pool added", time: "03.02.2021" },
      { label: "Ethereum pool added", time: "04.12.2020" },
      { label: "Curvehash, Veruscoin pool added", time: "03.12.2020" },
      */
      //{ label: "We added profit calculator", time: "29.11.2020" },
      //{ label: "EthereumClassic pool added", time: "28.11.2020" },

    ];
  },

  getCoin(coin) {
    return this.getConfig()[coin];
  },

  getConfig() {
    return {
      "0DYNC": {
        "type": "mc",
        "endpoints": {
          "stats": "https://mc-api.letshash.it/api/pools/0dync",
          "blocks": "https://mc-api.letshash.it/api/pools/0dync/blocks",
          "payments": "https://mc-api.letshash.it/api/pools/0dync/payments", // Global payments
          "wallet": "https://mc-api.letshash.it/api/pools/0dync/miners/{address}",
          "minerPayments": "https://mc-api.letshash.it/api/pools/0dync/miners/{address}/payments" // New endpoint
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 0DYNC",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 8132, "difficulty": 256, "desc": "GPU VarDiff", "donation": 0, "command": "-o stratum+tcp://eu.letshash.it:8132" }
        ],
        "links": [
          { "title": "Zero Dynamics website", "icon": "website", "url": "http://zero-dynamics.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Zero-Dynamics/cash-core/" }
        ],
        "minerLinks": [
          { "name": "CCminer-0DYNC", "arch": "GPU", "url": "https://github.com/Zero-Dynamics/ccminer/releases/tag/2.4.0-0dyn", "title": "NVIDIA GPU Miner" },
        ],
        "explorerLinks": {
          "block": "http://154.92.17.70:3008/block/",
          "tx": "http://154.92.17.70:3008/tx/"
        },
        "url": "https://letshash.it/0dync",
        "img": "0dyn.png",
        "name": "Zero Dynamics Cash",
        "new": false,
        "active": true,
        "algo": "argon2d1000",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "0dync"
      },
      "AGM": {
        "type": "mc",
        "endpoints": {
          "stats": "https://mc-api.letshash.it/api/pools/agm",
          "blocks": "https://mc-api.letshash.it/api/pools/agm/blocks",
          "payments": "https://mc-api.letshash.it/api/pools/agm/payments", // Global payments
          "wallet": "https://mc-api.letshash.it/api/pools/agm/miners/{address}",
          "minerPayments": "https://mc-api.letshash.it/api/pools/agm/miners/{address}/payments" // New endpoint
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 AGM",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 8032, "difficulty": 0.1, "desc": "VarDiff 0.1", "donation": 0, "command": "-o stratum+tcp://eu.letshash.it:8032" }
        ],
        "links": [
          { "title": "Argoneum website", "icon": "website", "url": "http://argoneum.net" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Argoneum/argoneum" }
        ],
        "minerLinks": [
          { "name": "CPUminer-OPT", "arch": "CPU", "url": "https://github.com/JayDDee/cpuminer-opt/releases", "title": "CPU Miner" },
          { "name": "Z-enemy", "arch": "GPU", "url": "https://github.com/zealot-rvn/z-enemy/releases", "title": "NVIDIA GPU Miner" },
          { "name": "SGminer", "arch": "GPU", "url": "https://github.com/fancyIX/sgminer-phi2-branch/releases", "title": "AMD GPU miner" }
        ],
        "explorerLinks": {
          "block": "https://chainz.cryptoid.info/agm/block.dws?",
          "tx": "https://chainz.cryptoid.info/agm/tx.dws?"
        },
        "url": "https://letshash.it/agm",
        "img": "agm.png",
        "name": "Argoneum",
        "new": false,
        "active": true,
        "algo": "phi2",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "DPC": {
        "type": "mc",
        "endpoints": {
          "stats": "https://mc-api.letshash.it/api/pools/dpc",
          "blocks": "https://mc-api.letshash.it/api/pools/dpc/blocks",
          "payments": "https://mc-api.letshash.it/api/pools/dpc/payments", // Global payments
          "wallet": "https://mc-api.letshash.it/api/pools/dpc/miners/{address}",
          "minerPayments": "https://mc-api.letshash.it/api/pools/dpc/miners/{address}/payments" // New endpoint
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 DPC",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 3382, "difficulty": 0.01, "desc": "VarDiff 0.005", "donation": 0, "command": "-o stratum+tcp://eu.letshash.it:3382" }
        ],
        "links": [
          { "title": "Dpowcoin website", "icon": "website", "url": "https://dpowcore.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/dpowcore-project/dpowcoin" }
        ],
        "minerLinks": [
          { "name": "CPUminer-Rplant", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases", "title": "CPU Miner" },
        ],
        "explorerLinks": {
          "block": "https://explorer.dpowcore.org/block/",
          "tx": "https://explorer.dpowcore.org/tx/"
        },
        "url": "https://letshash.it/dpc",
        "img": "dpc.png",
        "name": "Dpowcoin",
        "new": false,
        "active": true,
        "algo": "dpowhash",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "BLACK": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=47",
          "payments": "https://web.letshash.it/site/payments_results_json?id=47",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha256"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 VK7R",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3333, "difficulty": 32, "desc": "ASIC", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:3333" }
        ],
        "links": [
          { "title": "BitcoinBlack Website", "icon": "website", "url": "https://bitcoinblackcore.com" },
          { "title": "Github", "icon": "github", "url": " https://github.com/Bitcoin-Black-Core/bitcoinblackcore" }
        ],
        "minerLinks": [
          { "name": "CGminer", "arch": "CPU", "url": "https://github.com/ckolivas/cgminer", "title": "ASIC miner", "command": "./cgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" },
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner", "command": "./bfgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" }
        ],
        "explorerLinks": {
          "block": "http://185.230.160.185:3001/block/",
          "tx": "http://185.230.160.185:3001/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "black.png",
        "name": "BitcoinBlack",
        "new": false,
        "active": false,
        "algo": "sha256d",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "FOXD": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=16",
          "payments": "https://web.letshash.it/site/payments_results_json?id=16",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=x16rv2"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "50 FOXD",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 3637, "difficulty": 0.5, "desc": "GPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:3637" }
        ],
        "links": [
          { "title": "Foxdcoin", "icon": "website", "url": "https://foxrtb.com/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/foxdproject/foxdcoin" }
        ],
        "minerLinks": [
          { "name": "T-Rex", "arch": "GPU", "url": "https://github.com/trexminer/T-Rex/releases", "title": "AMD/NVIDIA GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer2.foxrtb.com/block/",
          "tx": "https://explorer2.foxrtb.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "foxd.png",
        "name": "Foxdcoin",
        "new": false,
        "active": false,
        "algo": "x16rv2",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "OBTC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=11",
          "payments": "https://web.letshash.it/site/payments_results_json?id=11",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=heavyhash"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 OBTC",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 3390, "difficulty": 0.01, "desc": "CPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:3390" },
          { "port": 3391, "difficulty": 0.5, "desc": "GPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:3391" }
        ],
        "links": [
          { "title": "Optical Bitcoin", "icon": "website", "url": "https://powx.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/PoWx-Org/obtc-core" }
        ],
        "minerLinks": [
          { "name": "SRBminer MULTI", "arch": "MULTI", "url": "https://github.com/doktor83/SRBMiner-Multi/releases/tag/0.7.2", "title": "MULTIminer" }
        ],
        "explorerLinks": {
          "block": "https://explorer.powx.org/block/",
          "tx": "https://explorer.powx.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "obtc.png",
        "name": "Optical Bitcoin",
        "new": false,
        "active": false,
        "algo": "heavyhash",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "MOTA": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=23",
          "payments": "https://web.letshash.it/site/payments_results_json?id=23",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 MOTA",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 3633, "difficulty": 0.008, "desc": "GPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:3633" }
        ],
        "links": [
          { "title": "MotaCoin website", "icon": "website", "url": "https://motacoin.net/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Jahvinci/Motacoin" }
        ],
        "minerLinks": [
          { "name": "CGminer", "arch": "CPU", "url": "https://github.com/ckolivas/cgminer", "title": "ASIC miner", "command": "./cgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" },
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner", "command": "./bfgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" }
        ],
        "explorerLinks": {
          "block": "https://openchains.info/coin/motacoin/block/",
          "tx": "https://openchains.info/coin/motacoin/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "mota.png",
        "name": "Motacoin",
        "new": false,
        "active": false,
        "algo": "x13",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "motacoin"
      },
      "BLAS": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=19",
          "payments": "https://web.letshash.it/site/payments_results_json?id=19",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 BLAS",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3663, "difficulty": 0.25, "desc": "GPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:3833" }
        ],
        "links": [
          { "title": "BlakeStar2.0 website", "icon": "website", "url": "https://github.com/blakestar2/releases/tag/v2.0.1.1" },
          { "title": "Github", "icon": "github", "url": "https://github.com/blakestar2/releases/tag/v2.0.1.1" }
        ],
        "minerLinks": [
          { "name": "CryptoDredge", "arch": "GPU", "url": "https://github.com/technobyl/CryptoDredge/releases", "title": "NVIDIA GPU Miner" },
          { "name": "T-rex Miner", "arch": "GPU", "url": "https://github.com/trexminer/T-Rex/releases", "title": "NVIDIA GPU Miner" },
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/releases", "title": "AMD/NVIDIA GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://openchains.info/coin/blakestar2.0/block/",
          "tx": "https://openchains.info/coin/blakestar2.0/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "blas.png",
        "name": "BlakeStar2.0",
        "new": false,
        "active": false,
        "algo": "x16s",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "BGEM": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=21",
          "payments": "https://web.letshash.it/site/payments_results_json?id=21",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 BLAS",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3533, "difficulty": 0.016, "desc": "ASIC", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:3533" }
        ],
        "links": [
          { "title": "BitcoinGem website", "icon": "website", "url": "bitcoingem.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/bitcoingem/bitcoingem" }
        ],
        "minerLinks": [
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "NVIDIA GPU x11 Miner" }
        ],
        "explorerLinks": {
          "block": "http://explorer.bitcoingem.org/block/",
          "tx": "http://explorer.bitcoingem.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "bgem.png",
        "name": "BitcoinGem",
        "new": false,
        "active": false,
        "algo": "x11",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "CBE": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=27",
          "payments": "https://web.letshash.it/site/payments_results_json?id=27",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=phi5"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 CBE",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 9033, "difficulty": 0.001, "desc": "CPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:9033" }
        ],
        "links": [
          { "title": "Combode website", "icon": "website", "url": "http://combode.com" },
          { "title": "Github", "icon": "github", "url": "https://github.com/combode/combode" }
        ],
        "minerLinks": [
          { "name": "CPUminer", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases", "title": "MultiCPU miner" },
          { "name": "SRB", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "MultiCPU+GPU miner" },
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/releases", "title": "AMD/NVIDIA GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://openchains.info/coin/combode/block/",
          "tx": "https://openchains.info/coin/combode/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "cbe.png",
        "name": "Combode",
        "new": false,
        "active": false,
        "algo": "phi5",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "LUX": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=8",
          "payments": "https://web.letshash.it/site/payments_results_json?id=8",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=rx2"
        },
        "pool": {
          "fee": "10 %",
          "minPayout": "0.0001 LUX",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 9009, "difficulty": 0.0001, "desc": "CPU+GPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:9009" }
        ],
        "links": [
          { "title": "Lux website", "icon": "website", "url": "https://luxcore.io" },
          { "title": "Github", "icon": "github", "url": "https://github.com/LUX-Core/lux" }
        ],
        "minerLinks": [
          { "name": "CPUminer", "arch": "CPU", "url": "https://github.com/LUX-Core/rx2-cpuminer/releases", "title": "CPU miner" },
          { "name": "SRBminer", "arch": "CPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases/tag/0.8.9", "title": "CPU miner" },
          { "name": "GPUminer", "arch": "GPU", "url": "https://github.com/LUX-Core/rx2-ccminer/releases", "title": "NVIDIA GPU miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.luxcore.io/#/block/{blockHeight}",
          "tx": "https://explorer.luxcore.io/#/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "lux.png",
        "name": "Luxcoin",
        "new": false,
        "active": false,
        "algo": "rx2",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "luxcoin"
      },
      "RTM": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=48",
          "payments": "https://web.letshash.it/site/payments_results_json?id=48",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=gr"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "50 RTM",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 8009, "difficulty": 0.01, "desc": "CPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:8009" }
        ],
        "links": [
          { "title": "Raptoreum website", "icon": "website", "url": "https://raptoreum.com" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Raptor3um/Raptoreum" }
        ],
        "minerLinks": [
          { "name": "CPUminer GR", "arch": "CPU", "url": "https://github.com/Raptor3um/cpuminer-opt/releases/tag/v2.0", "title": "CPU miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.raptoreum.com/block/",
          "tx": "https://explorer.raptoreum.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "rtm.png",
        "name": "Raptoreum",
        "new": false,
        "active": false,
        "algo": "GhostRider",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "XBTX": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=20",
          "payments": "https://web.letshash.it/site/payments_results_json?id=20",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 XBTX",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 6666, "difficulty": 128, "desc": "CPU", "donation": 0, "command": "-o stratum+tcp://pool.letshash.it:6666" }
        ],
        "links": [
          { "title": "BitcoinSubsidium", "icon": "website", "url": "https://bitcoin-subsidium.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/phoenixkonsole/xbtx" }
        ],
        "minerLinks": [
          { "name": "VeriumCPU miner", "arch": "CPU", "url": "https://github.com/fireworm71/veriumMiner/releases", "title": "CPU Miner" },
        ],
        "explorerLinks": {
          "block": "https://explorer.bitcoin-subsidium.org/block/",
          "tx": "https://explorer.bitcoin-subsidium.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "xbtx.png",
        "name": "BitcoinSubsidium",
        "new": false,
        "active": false,
        "algo": "scrypt1048576",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "DGB": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=43",
          "payments": "https://web.letshash.it/site/payments_results_json?id=43",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=scrypt"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.05 DGB",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 9433, "difficulty": 128, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": "Digibyte website", "icon": "website", "url": "https://digibyte.io/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/digibyte/digibyte" }
        ],
        "minerLinks": [
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Scrypt Miner" }
        ],
        "explorerLinks": {
          "block": "https://dgb.ccore.online/block/",
          "tx": "https://dgb.ccore.online/transaction/"
        },
        "url": "https://web.letshash.it",
        "img": "dgb.png",
        "name": "DigiByte",
        "new": false,
        "active": false,
        "algo": "scrypt",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "digibyte"
      },
      "CHIP": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=15",
          "payments": "https://web.letshash.it/site/payments_results_json?id=15",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=x17"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.0001 CHIP",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3737, "difficulty": 1, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "ChipCoin website", "icon": "website", "url": "https://chipcoin.info/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Chip-Coin/chipcoin" }
        ],
        "minerLinks": [
          { "name": "CryptoDredge", "arch": "GPU", "url": "https://github.com/technobyl/CryptoDredge/releases", "title": "NVIDIA GPU Miner" },
          { "name": "T-rex Miner", "arch": "GPU", "url": "https://github.com/trexminer/T-Rex/releases", "title": "NVIDIA GPU Miner" },
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/releases", "title": "AMD/NVIDIA GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.chipcoin.info/search?request=",
          "tx": "https://explorer.chipcoin.info/search?request="
        },
        "url": "https://web.letshash.it",
        "img": "chip.png",
        "name": "Chipcoin",
        "new": false,
        "active": false,
        "algo": "x17",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "LNC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=54",
          "payments": "https://web.letshash.it/site/payments_results_json?id=54",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=yespowerLTNCG"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.005 LNC",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 6241, "difficulty": 0.2, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Lightning Cash website", "icon": "website", "url": "https://lightning-cash.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/LightningCash-Dev/lightningcash" }
        ],
        "minerLinks": [
          { "name": "CPU Miner-RKZ", "arch": "CPU", "url": "https://github.com/RickillerZ/cpuminer-RKZ/releases/", "title": "YespowerLTNCG CPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.lightning-cash.com/block/",
          "tx": "https://explorer.lightning-cash.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "lnc.png",
        "name": "LightningCash",
        "new": false,
        "active": false,
        "algo": "yespowerLTNCG",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "TDC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=19",
          "payments": "https://web.letshash.it/site/payments_results_json?id=19",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=yespowerTIDE"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "10 TDC",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 9950, "difficulty": 0.2, "desc": "Low End Hardware", "donation": 0 },
          { "port": 9951, "difficulty": 2, "desc": "High End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Tidecoin website", "icon": "website", "url": "http://tidecoin.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/tidecoin" }
        ],
        "minerLinks": [
          { "name": "CPU Miner TIDE", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases/", "title": "YespowerTIDE CPU Miner" }
        ],
        "explorerLinks": {
          "block": "http://tidecoin-explorer.gleeze.com/block/",
          "tx": "http://tidecoin-explorer.gleeze.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "tdc.png",
        "name": "Tidecoin",
        "new": false,
        "active": false,
        "algo": "yespowerTIDE",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "LTFN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=29",
          "payments": "https://web.letshash.it/site/payments_results_json?id=29",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=yespowerR16"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 LTFN",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 6236, "difficulty": 1, "desc": "High End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Litecoin Finance website", "icon": "website", "url": "https://ltfn.scalaris.info/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/litecoinfinance/litecoinfinance" }
        ],
        "minerLinks": [
          { "name": "CPU Miner", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases/", "title": "CPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://openchains.info/coin/ltfn/block/",
          "tx": "https://openchains.info/coin/ltfn/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "ltfn.png",
        "name": "Litecoin Finance",
        "new": false,
        "active": false,
        "algo": "yespowerr16",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "litecoin-finance"
      },
      "ESP": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=11",
          "payments": "https://web.letshash.it/site/payments_results_json?id=11",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=hmq1725"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.1 ESP",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 7933, "difficulty": 250, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Espers website", "icon": "website", "url": "http://espers.io/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/cryptocoderz/espers" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.espers.io/block/",
          "tx": "https://explorer.espers.io/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "esp.png",
        "name": "Espers",
        "new": false,
        "active": false,
        "algo": "hmq1725",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "espers"
      },
      "PPCN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=32",
          "payments": "https://web.letshash.it/site/payments_results_json?id=32",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=bmw512"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 PPCN",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 6558, "difficulty": 256, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "PupaCoin website", "icon": "website", "url": "http://pupacoin.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/PupaCoin/PupaCoin-Core" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "http://explorer.pupacoin.com/block/",
          "tx": "http://explorer.pupacoin.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "ppcn.png",
        "name": "PupaCoin",
        "new": false,
        "active": false,
        "algo": "bmw512",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "XDN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=9",
          "payments": "https://web.letshash.it/site/payments_results_json?id=9",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=bmw512"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 XDN",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 5787, "difficulty": 256, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "DigitalNote website", "icon": "website", "url": "https://digitalnote.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/DigitalNoteXDN/DigitalNote-2" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://xdn-explorer.com/block/",
          "tx": "https://xdn-explorer.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "xdn.png",
        "name": "DigitalNote",
        "new": false,
        "active": false,
        "algo": "bmw512",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "digitalnote"
      },
      "CCASH": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=51",
          "payments": "https://web.letshash.it/site/payments_results_json?id=51",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=bmw512"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 CCASH",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 5778, "difficulty": 256, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "CampusCash website", "icon": "website", "url": "https://campuscash.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/CampusCash/CampusCash_Core" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.campuscash.org/block/",
          "tx": "https://explorer.campuscash.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "ccash.png",
        "name": "CampusCash",
        "new": false,
        "active": false,
        "algo": "bmw512",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "coingecko"
      },
      "HCC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=46",
          "payments": "https://web.letshash.it/site/payments_results_json?id=46",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=x11"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 HCC",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 3533, "difficulty": 0.016, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "HCC website", "icon": "website", "url": "https://healthcarecoin.in/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/hcccrypto/hcc" }
        ],
        "minerLinks": [
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "NVIDIA GPU x11 Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.healthcarecoin.in/block/",
          "tx": "https://explorer.healthcarecoin.in/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "hcc.png",
        "name": "HealthCareCoin",
        "new": false,
        "active": false,
        "algo": "x11",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "coingecko"
      },
      "SUGAR": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=18",
          "payments": "https://web.letshash.it/site/payments_results_json?id=18",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=yespowerSUGAR"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 SUGAR",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 6098, "difficulty": 0.01, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "SugarChain website", "icon": "website", "url": "https://sugarchain.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/sugarchain-project/sugarchain" }
        ],
        "minerLinks": [
          { "name": "CPU Miner-RKZ", "arch": "CPU", "url": "https://github.com/RickillerZ/cpuminer-RKZ/releases/", "title": "YespowerSUGAR CPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://1explorer.sugarchain.org/block/",
          "tx": "https://1explorer.sugarchain.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "sugar.png",
        "name": "SugarChain",
        "new": false,
        "active": false,
        "algo": "yespowersugar",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "sugarchain"
      },
      "CPU": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=7",
          "payments": "https://web.letshash.it/site/payments_results_json?id=7",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=cpupower"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 CPU",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 4450, "difficulty": 1, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "CPUchain website", "icon": "website", "url": "https://cpuchain.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/cpuchain/cpuchain" }
        ],
        "minerLinks": [
          { "name": "CPUminer OPT", "arch": "CPU", "url": "https://github.com/cpu-pool/cpuminer-opt-cpupower/releases/tag/1.4", "title": "CPUpower CPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.cpuchain.org/block/",
          "tx": "https://explorer.cpuchain.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "cpu.png",
        "name": "CPUchain",
        "new": false,
        "active": false,
        "algo": "cpupower",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "cpuchain"
      },
      "MBC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=44",
          "payments": "https://web.letshash.it/site/payments_results_json?id=44",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "100 MBC",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3993, "difficulty": 0.02, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Microbitcoin website", "icon": "website", "url": "https://microbitcoin.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/MicroBitcoinOrg/MicroBitcoin" }
        ],
        "minerLinks": [
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases", "title": "CPU miners" }
        ],
        "explorerLinks": {
          "block": "https://microbitcoinorg.github.io/explorer/#/block/",
          "tx": "https://microbitcoinorg.github.io/explorer/#/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "mbc.png",
        "name": "Microbitcoin",
        "new": false,
        "active": false,
        "algo": "power2b",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "microbitcoin"
      },
      "PYRK": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=41",
          "payments": "https://web.letshash.it/site/payments_results_json?id=41",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=lyra2z330"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 PYRK",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 4433, "difficulty": 1, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Pyrk website", "icon": "website", "url": "https://www.pyrk.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/pyrkcommunity/pyrk" }
        ],
        "minerLinks": [
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/JayDDee/cpuminer-opt/releases/tag/v3.15.7", "title": "CPU miners" },
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases", "title": "CPU miners" }
        ],
        "explorerLinks": {
          "block": "https://explorer.pyrk.org/block/",
          "tx": "https://explorer.pyrk.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "pyrk.png",
        "name": "Pyrk",
        "new": false,
        "active": false,
        "algo": "lyra2z330",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "pyrk"
      },
      "MGPC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=17",
          "payments": "https://web.letshash.it/site/payments_results_json?id=17",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=yespowerMGPC"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1000 MGPC",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 6234, "difficulty": 0.5, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "MagPieCoin website", "icon": "website", "url": "http://magpiecoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/mgpc-lab/MagpieCoin" }
        ],
        "minerLinks": [
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/letshash/cpuminer-opt-magpiecoin-yespower/releases/", "title": "CPU miners" },
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases", "title": "CPU miners" }
        ],
        "explorerLinks": {
          "block": "https://web.letshash.it/explorer/MGPC?hash=",
          "tx": "https://web.letshash.it/explorer/MGPC?txid="
        },
        "url": "https://web.letshash.it",
        "img": "mgpc.png",
        "name": "MagPieCoin",
        "new": false,
        "active": false,
        "algo": "yespowerMGPC",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "ARWN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=39",
          "payments": "https://web.letshash.it/site/payments_results_json?id=39",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=yespowerARWN"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 ARWN",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 6244, "difficulty": 0.2, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "ArowanaCoin website", "icon": "website", "url": "http://arowanacoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/WilliamMcAvoy77/Arowanacoin/releases/" }
        ],
        "minerLinks": [
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases", "title": "CPU miners" }
        ],
        "explorerLinks": {
          "block": "https://web.letshash.it/explorer/ARWN?hash=",
          "tx": "https://web.letshash.it/explorer/ARWN?txid="
        },
        "url": "https://web.letshash.it",
        "img": "arwn.png",
        "name": "Arowanacoin",
        "new": false,
        "active": false,
        "algo": "yespowerARWN",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "WCN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=21",
          "payments": "https://web.letshash.it/site/payments_results_json?id=21",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha256"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 WCN",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 3309, "difficulty": 512, "desc": "ASIC", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:3309" }
        ],
        "links": [
          { "title": "Widecoin Website", "icon": "website", "url": "https://widecoin.org/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/widecoin-project/widecoin" }
        ],
        "minerLinks": [
          { "name": "CGminer", "arch": "CPU", "url": "https://github.com/ckolivas/cgminer", "title": "ASIC miner", "command": "./cgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" },
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner", "command": "./bfgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" }
        ],
        "explorerLinks": {
          "block": "http://explorer.widecoin.org:5000/block/",
          "tx": "http://explorer.widecoin.org:5000/block/"
        },
        "url": "https://web.letshash.it",
        "img": "wcn.png",
        "name": "Widecoin",
        "new": false,
        "active": false,
        "algo": "sha256",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "IBTC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=30",
          "payments": "https://web.letshash.it/site/payments_results_json?id=30",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha256"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 IBTC",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 9869, "difficulty": 32, "desc": "ASIC", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:3309" }
        ],
        "links": [
          { "title": "iBitcoin Website", "icon": "website", "url": "http://ibtc.network/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/NullFunctor/iBTC" }
        ],
        "minerLinks": [
          { "name": "CGminer", "arch": "CPU", "url": "https://github.com/ckolivas/cgminer", "title": "ASIC miner", "command": "./cgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" },
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner", "command": "./bfgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" }
        ],
        "explorerLinks": {
          "block": "https://explorer.ibtc.network/block/",
          "tx": "https://explorer.ibtc.network/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "ibtc.png",
        "name": "iBitcoin",
        "new": false,
        "active": false,
        "algo": "sha256",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "improved-bitcoin"
      },
      "BTCV": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=44",
          "payments": "https://web.letshash.it/site/payments_results_json?id=44",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha256"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 BTCV",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 1221, "difficulty": 32, "desc": "ASIC", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:3309" }
        ],
        "links": [
          { "title": "BitcoinVault Website", "icon": "website", "url": "https://bitcoinvault.global/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/bitcoinvault/bitcoinvault" }
        ],
        "minerLinks": [
          { "name": "CGminer", "arch": "CPU", "url": "https://github.com/ckolivas/cgminer", "title": "ASIC miner", "command": "./cgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" },
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner", "command": "./bfgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" }
        ],
        "explorerLinks": {
          "block": "https://explorer.bitcoinvault.global/block/",
          "tx": "https://explorer.bitcoinvault.global/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "btcv.png",
        "name": "BitcoinVault",
        "new": false,
        "active": false,
        "algo": "sha256",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "bitcoin-vault"
      },
      "XRG": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=31",
          "payments": "https://web.letshash.it/site/payments_results_json?id=31",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha256"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.000001 XRG",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 13391, "difficulty": 32, "desc": "ASIC", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:3309" }
        ],
        "links": [
          { "title": "Ergon Website", "icon": "website", "url": "https://ergon.moe/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/Ergon-moe/Bitcoin-Static" }
        ],
        "minerLinks": [
          { "name": "CGminer", "arch": "CPU", "url": "https://github.com/ckolivas/cgminer", "title": "ASIC miner", "command": "./cgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" },
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner", "command": "./bfgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" }
        ],
        "explorerLinks": {
          "block": "https://explorer.ergon.network/block/",
          "tx": "https://explorer.ergon.network/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "erg.png",
        "name": "Ergon",
        "new": false,
        "active": false,
        "algo": "sha256",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "BUTK": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=22",
          "payments": "https://web.letshash.it/site/payments_results_json?id=22",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha256"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 BUTK",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 8324, "difficulty": 512, "desc": "ASIC", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:8324" }
        ],
        "links": [
          { "title": "Butkoin Website", "icon": "website", "url": "https://butcoin.tech/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/ButKoin/but" }
        ],
        "minerLinks": [
          { "name": "CGminer", "arch": "CPU", "url": "https://github.com/ckolivas/cgminer", "title": "ASIC miner", "command": "./cgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" },
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner", "command": "./bfgminer -o stratum+tcp://pool.letshash.it:3333 -u YOURUSERNAME –p ANYTHING" }
        ],
        "explorerLinks": {
          "block": "https://explorer.butcoin.tech/block/",
          "tx": "https://explorer.butcoin.tech/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "but.png",
        "name": "Butcoin",
        "new": false,
        "active": false,
        "algo": "sha256",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "BUTK-yespower": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=27",
          "payments": "https://web.letshash.it/site/payments_results_json?id=27",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=yespower"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 BUTK",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 8188, "difficulty": 0.5, "desc": "CPU", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:8188" }
        ],
        "links": [
          { "title": "Butkoin website", "icon": "website", "url": "https://butcoin.tech/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/ButKoin/but" }
        ],
        "minerLinks": [
          { "name": "CGminer", "arch": "CPU", "url": "hhttps://github.com/JayDDee/cpuminer-opt/releases/", "title": "cpu miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.butcoin.tech/block/",
          "tx": "https://explorer.butcoin.tech/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "but.png",
        "name": "Butcoin",
        "new": false,
        "active": false,
        "algo": "yespower",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "WATC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=43",
          "payments": "https://web.letshash.it/site/payments_results_json?id=43",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=gr"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 WATC",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 8088, "difficulty": 0.05, "desc": "CPU", "donation": 0, "command": "-o stratum+tcp://eu.letshash.it:8088" }
        ],
        "links": [
          { "title": "WhatCoin website", "icon": "website", "url": "https://www.whatcoin.cash/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/whatcoin-project/whatcoin" }
        ],
        "minerLinks": [
          { "name": "GRminer", "arch": "CPU", "url": "https://github.com/WyvernTKC/cpuminer-gr-avx2/releases/tag/1.1.7", "title": "cpu miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.whatcoin.cash/block/",
          "tx": "https://explorer.whatcoin.cash/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "watc.png",
        "name": "WhatCoin",
        "new": false,
        "active": false,
        "algo": "gr",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "CRIBS": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=28",
          "payments": "https://web.letshash.it/site/payments_results_json?id=28",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=argon2d4096"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 CRIBS",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 8924, "difficulty": 1, "desc": "GPU+CPU", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:8924" }
        ],
        "links": [
          { "title": "Crypto Cribs Website", "icon": "website", "url": "https://www.cryptocribs.org/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/CribsDev/cribs" }
        ],
        "minerLinks": [
          { "name": "CryptoDredge", "arch": "GPU", "url": "https://github.com/technobyl/CryptoDredge/releases", "title": "NVIDIA GPU Miner" },
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/JayDDee/cpuminer-opt/releases", "title": "CPU miners" }
        ],
        "explorerLinks": {
          "block": "https://explorer.cryptocribs.org/block/",
          "tx": "https://explorer.cryptocribs.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "cribs.png",
        "name": "Crypto Cribs",
        "new": false,
        "active": false,
        "algo": "argon2d4096",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "ADOT": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=10",
          "payments": "https://web.letshash.it/site/payments_results_json?id=10",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=argon2d16000"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 ADOT",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 8924, "difficulty": 1, "desc": "GPU+CPU", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:8924" }
        ],
        "links": [
          { "title": "Alterdot Website", "icon": "website", "url": "https://alterdot.network/" },
          { "title": "Github", "icon": "github", "url": " https://github.com/Alterdot/Alterdot" }
        ],
        "minerLinks": [
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/Alterdot/cpuminer-opt-kudaraidee/releases", "title": "CPU miners" }
        ],
        "explorerLinks": {
          "block": "https://explorer.alterdot.network/block/",
          "tx": "https://explorer.alterdot.network/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "adot.png",
        "name": "Alterdot",
        "new": false,
        "active": false,
        "algo": "argon2d16000",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "COSA": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=47",
          "payments": "https://web.letshash.it/site/payments_results_json?id=47",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=cosa"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.000001 COSA",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 4433, "difficulty": 0.0001, "desc": "CPU", "donation": 0, "command": "-o stratum+tcp://EU.letshash.it:4433" }
        ],
        "links": [
          { "title": "Cosanta Website", "icon": "website", "url": "https://cosanta.net/en" },
          { "title": "Github", "icon": "github", "url": " https://github.com/cosanta/cosanta-core" }
        ],
        "minerLinks": [
          { "name": "SRBminer", "arch": "CPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "CPU miners" }
        ],
        "explorerLinks": {
          "block": "https://explorer.cosanta.net/block/",
          "tx": "https://explorer.cosanta.net/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "cosa.png",
        "name": "Cosanta",
        "new": false,
        "active": false,
        "algo": "cosa",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "GOLD": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=8",
          "payments": "https://web.letshash.it/site/payments_results_json?id=8",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.0001 GOLD",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 6333, "difficulty": 1, "desc": "Low End Hardware", "donation": 0 }
        ],
        "links": [
          { "title": "GoldCash website", "icon": "website", "url": "https://www.goldcashcoin.co.uk/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/MR-TRUMP-Gold-Cash/Gold-Cash" }
        ],
        "minerLinks": [
          { "name": "CPU Miner-RKZ", "arch": "CPU", "url": "https://github.com/RickillerZ/cpuminer-RKZ/releases/", "title": "YescryptR16 CPU Miner" }
        ],
        "explorerLinks": {
          "block": "http://goldcashblockexplorer.co.uk/block/",
          "tx": "http://goldcashblockexplorer.co.uk/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "gold.png",
        "name": "GoldCash",
        "new": false,
        "active": false,
        "algo": "yescryptr16",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "QAC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=9",
          "payments": "https://web.letshash.it/site/payments_results_json?id=9",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=scrypt"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.0001 QAC",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3433, "difficulty": 1, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": "QuasarCoin website", "icon": "website", "url": "http://quasarcoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/quasarcoin/quasar" }
        ],
        "minerLinks": [
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Scrypt Miner" }
        ],
        "explorerLinks": {
          "block": "http://insight.quasarcoin.org/block/",
          "tx": "http://insight.quasarcoin.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "qac.png",
        "name": "QuasarCoin",
        "new": false,
        "active": false,
        "algo": "scrypt",
        "unitPlaces": 7,
        "addressPrefix": 0,
        "coingeckoID": "quasarcoin"
      },
      "VPC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=12",
          "payments": "https://web.letshash.it/site/payments_results_json?id=12",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=scrypt"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.05 VPC",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 3933, "difficulty": 128, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": "VoltPotCoin website", "icon": "website", "url": "https://voltpot.org/" },
          { "title": "Github", "icon": "github", "url": "https://voltpot.org/" }
        ],
        "minerLinks": [
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Scrypt Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.voltpot.org/block/",
          "tx": "hhttps://explorer.voltpot.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "vpc.png",
        "name": "VoltPotCoin",
        "new": false,
        "active": false,
        "algo": "scrypt",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "UQB": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=29",
          "payments": "https://web.letshash.it/site/payments_results_json?id=29",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=scrypt"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "10 UQB",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3233, "difficulty": 128, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": " UQB website", "icon": "website", "url": "http://ubiquitium.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Ubiquitium-Blockchain" }
        ],
        "minerLinks": [
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Scrypt Miner" }
        ],
        "explorerLinks": {
          "block": "http://45.63.6.100/block/",
          "tx": "http://45.63.6.100/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "uqb.png",
        "name": "Ubiquitium",
        "new": false,
        "active": false,
        "algo": "scrypt",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "BCC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=20",
          "payments": "https://web.letshash.it/site/payments_results_json?id=20",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=scrypt"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.0001 BCC",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 3633, "difficulty": 128, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": "  BarrelCrudeCoin website", "icon": "website", "url": "https://www.barrelcrudecoin.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/BarrelCrudeCoin/BarrelCrudeCoin" }
        ],
        "minerLinks": [
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Scrypt Miner" }
        ],
        "explorerLinks": {
          "block": "https://blocks.barrelcrudecoin.com/block/",
          "tx": "https://blocks.barrelcrudecoin.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "bcc.png",
        "name": "BarrelCrudeCoin",
        "new": false,
        "active": false,
        "algo": "scrypt",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "EHS": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=13",
          "payments": "https://web.letshash.it/site/payments_results_json?id=13",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=scrypt"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 EHS",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 14433, "difficulty": 32, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": "Ethros website", "icon": "website", "url": "http://www.ethros.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Ethros-Project/hardcode" }
        ],
        "minerLinks": [
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Scrypt Miner" }
        ],
        "explorerLinks": {
          "block": "http://3.131.90.169:3001/block/",
          "tx": "http://3.131.90.169:3001/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "ehs.png",
        "name": "Ethros",
        "new": false,
        "active": false,
        "algo": "scrypt",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "WEAC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=59",
          "payments": "https://web.letshash.it/site/payments_results_json?id=59",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=scrypt"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 WEAC",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 14443, "difficulty": 32, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": "Weacoin website", "icon": "website", "url": "https://wea.pw/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/sokolsem/weacoin" }
        ],
        "minerLinks": [
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Scrypt Miner" }
        ],
        "explorerLinks": {
          "block": "https://openchains.info/coin/weacoin/block/",
          "tx": "https://openchains.info/coin/weacoin/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "weac.png",
        "name": "Weacoin",
        "new": false,
        "active": false,
        "algo": "scrypt",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "CTC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=35",
          "payments": "https://web.letshash.it/site/payments_results_json?id=35",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=scrypt"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.01 CTC",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 7433, "difficulty": 128, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": "Connecticoin website", "icon": "website", "url": "https://connecticoin.com" },
          { "title": "Github", "icon": "github", "url": "https://github.com/ConnectiCoin/ConnectiCoin/" }
        ],
        "minerLinks": [
          { "name": "BFGminer", "arch": "GPU", "url": "http://bfgminer.org/", "title": "ASIC miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Scrypt Miner" }
        ],
        "explorerLinks": {
          "block": "http://explorer.connecticoin.com/block/",
          "tx": "http://explorer.connecticoin.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "ctc.png",
        "name": "Connecticoin",
        "new": false,
        "active": false,
        "algo": "scrypt",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "ATMN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=14",
          "payments": "https://web.letshash.it/site/payments_results_json?id=14",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=phi1612"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 ATMN",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 8927, "difficulty": 0.016, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Antimony website ", "icon": "website", "url": "https://www.antimonycoin.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/antimonycoin/antimonysourcecd" }
        ],
        "minerLinks": [
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer/releases/tag/2.3.1-tpruvot", "title": "NVIDIA GPU Miner" },
          { "name": "Wildrig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/releases/tag/0.28.3", "title": "NVIDIA/AMD GPU Miner" }
        ],
        "explorerLinks": {
          "block": "http://wallet.antimonycoin.com:3001/block/",
          "tx": "http://wallet.antimonycoin.com:3001/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "atmn.png",
        "name": "Antimony",
        "new": false,
        "active": false,
        "algo": "phi1612",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "GPBS": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=50",
          "payments": "https://web.letshash.it/site/payments_results_json?id=50",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=phi1612"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.0001 GPBS",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 8928, "difficulty": 0.016, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "British Pound Coin website ", "icon": "website", "url": "https://www.britishpoundcoin.co.uk" },
          { "title": "Github", "icon": "github", "url": "https://github.com/robinhoodgb/britishpoundcoin" }
        ],
        "minerLinks": [
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer/releases/tag/2.3.1-tpruvot", "title": "NVIDIA GPU Miner" },
          { "name": "Wildrig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/releases/tag/0.28.3", "title": "NVIDIA/AMD GPU Miner" }
        ],
        "explorerLinks": {
          "block": "http://explorer.britishpoundcoin.co.uk/block/",
          "tx": "http://explorer.britishpoundcoin.co.uk/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "gpbs.png",
        "name": "British Pound Coin",
        "new": false,
        "active": false,
        "algo": "phi1612",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "PESP": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=53",
          "payments": "https://web.letshash.it/site/payments_results_json?id=53",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=x11"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.0001 PESP",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 8833, "difficulty": 0.016, "desc": "ASIC", "donation": 0 }
        ],
        "links": [
          { "title": "PESP Coin website ", "icon": "website", "url": "https://www.pespcoin.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/PESPCOIN/PESPCOIN" }
        ],
        "minerLinks": [
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer/releases/tag/2.3.1-tpruvot", "title": "NVIDIA GPU Miner" },
          { "name": "SGminer", "arch": "GPU", "url": "https://github.com/genesismining/sgminer-gm/releases", "title": "AMD GPU Miner" }
        ],
        "explorerLinks": {
          "block": "http://explorer.pespcoin.net/#/block/",
          "tx": "http://explorer.pespcoin.net/#/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "pespcoin.png",
        "name": "PESP Coin",
        "new": false,
        "active": false,
        "algo": "x11",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "RVL": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=54",
          "payments": "https://web.letshash.it/site/payments_results_json?id=54",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=x16r"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.0001 RVL",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 9636, "difficulty": 0.025, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "RavenCoin Lite website ", "icon": "website", "url": "https://ravencoinlite.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/RavencoinLite/RavencoinLite" }
        ],
        "minerLinks": [
          { "name": "T-rex miner", "arch": "GPU", "url": "https://github.com/trexminer/T-Rex/releases/tag/0.18.11", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "Z-enemy", "arch": "GPU", "url": "https://github.com/z-enemy/z-enemy/releases", "title": "NVIDIA GPU Miner" },
          { "name": "Wildrig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/releases/tag/0.28.3", "title": "NVIDIA/AMD GPU Miner" }
        ],
        "explorerLinks": {
          "block": "http://explorer.ravencoinlite.org/block/",
          "tx": "http://explorer.ravencoinlite.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "ravenlite.png",
        "name": "RavenCoin Lite",
        "new": false,
        "active": false,
        "algo": "x16r",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "DYNAMO": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=7",
          "payments": "https://web.letshash.it/site/payments_results_json?id=7",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=dynamo"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 DYNAMO",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 5966, "difficulty": 2, "desc": "CPU & GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Dynamo website ", "icon": "website", "url": "https://www.dynamocoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/dynamofoundation" }
        ],
        "minerLinks": [
          { "name": "Dynamo Miner", "arch": "GPU", "url": "https://github.com/dynamofoundation/mainnet-binaries/raw/main/windows-x64/miner/WindowsPackage.zip", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "SRBminer", "arch": "CPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases/tag/0.9.1", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.dynamocoin.org/block/",
          "tx": "http://explorer.dynamocoin.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "dynamo.png",
        "name": "Dynamo",
        "new": false,
        "active": false,
        "algo": "dynamo",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "PBTC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=12",
          "payments": "https://web.letshash.it/site/payments_results_json?id=12",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=heavyhash"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 pBTC",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 3333, "difficulty": 0.2, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Photonic Bitcoin website ", "icon": "website", "url": "https://photonicbitcoin.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/PhotonicBitcoin/pBTC-core" }
        ],
        "minerLinks": [
          { "name": "SRBminer-Multi", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "Wildrig Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.photonicbitcoin.com/block/",
          "tx": "https://explorer.photonicbitcoin.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "pbtc.png",
        "name": "Photonic Bitcoin",
        "new": false,
        "active": false,
        "algo": "heavyhash",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "KCN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=15",
          "payments": "https://web.letshash.it/site/payments_results_json?id=15",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha3d"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.01 KCN",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 8569, "difficulty": 2, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Kylacoin website ", "icon": "website", "url": "https://kylacoin.v6.army/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/kylacoin/kylacoin/" }
        ],
        "minerLinks": [
          { "name": "CCminer-SHA3d", "arch": "GPU", "url": "https://github.com/JayBitron/ccminer/releases/tag/v1.0", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "CPUminer-OPT", "arch": "CPU", "url": "https://github.com/JayDDee/cpuminer-opt/releases/tag/v3.19.5", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://kcnexp.eu.org/block/",
          "tx": "https://kcnexp.eu.org/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "kcn.png",
        "name": "Kylacoin",
        "new": false,
        "active": false,
        "algo": "sha3d",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "BTM": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=17",
          "payments": "https://web.letshash.it/site/payments_results_json?id=17",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=gr"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 BTRM",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 8088, "difficulty": 0.2, "desc": "CPU Mining", "donation": 0 }
        ],
        "links": [
          { "title": "Bitoreum website ", "icon": "website", "url": "http://bitoreum.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/bugahost/bitoreum" }
        ],
        "minerLinks": [
          { "name": "SRBminer", "arch": "CPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases/tag/0.9.4", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "Xmrig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.bitoreum.org:8000/block-height/",
          "tx": "http://explorer.bitoreum.org:8000/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "btm.png",
        "name": "Bitoreum",
        "new": false,
        "active": false,
        "algo": "GhostRider",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "TTN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=34",
          "payments": "https://web.letshash.it/site/payments_results_json?id=34",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=x21s"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 TTN",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 5897, "difficulty": 1, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Titancoin website ", "icon": "website", "url": "https://titanprojects.co/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/titancoinproject/Titan" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://titanexplorer.co/block/",
          "tx": "https://titanexplorer.co/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "ttn.png",
        "name": "Titan Coin",
        "new": false,
        "active": false,
        "algo": "x21s",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "MYNT": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=40",
          "payments": "https://web.letshash.it/site/payments_results_json?id=40",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=x16s"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 MYNT",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 3663, "difficulty": 0.25, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Mynt website ", "icon": "website", "url": "https://getmynt.io/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/project-mynt/mynt" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://insight.getmynt.io/block/",
          "tx": "https://insight.getmynt.io/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "mynt.png",
        "name": "Mynt",
        "new": false,
        "active": false,
        "algo": "x16s",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "ZUR": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=35",
          "payments": "https://web.letshash.it/site/payments_results_json?id=35",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=quark"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 ZUR",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 8224, "difficulty": 128, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Zurcoin website ", "icon": "website", "url": "https://zurcoin.com/" },
          { "title": "Github", "icon": "gi56thub", "url": "https://github.com/zurcoin/zurcoin" }
        ],
        "minerLinks": [
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.zurcoin.com/block/",
          "tx": "https://explorer.zurcoin.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "zur.png",
        "name": "Zurcoin",
        "new": false,
        "active": false,
        "algo": "quark",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "PA": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=9",
          "payments": "https://web.letshash.it/site/payments_results_json?id=9",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha256csm"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 PA",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 4833, "difficulty": 0.01, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Polar website ", "icon": "website", "url": "https://www.hugophoenix.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/HugoPhoenix/Polar/" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://polarexplorer.hugophoenix.com/block/",
          "tx": "https://polarexplorer.hugophoenix.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "pa.png",
        "name": "POLAR",
        "new": false,
        "active": false,
        "algo": "sha256csm",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "REA": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=14",
          "payments": "https://web.letshash.it/site/payments_results_json?id=14",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=sha256csm"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 REA",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 4033, "difficulty": 0.01, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "Realcoin website ", "icon": "website", "url": "https://real-coin.in" },
          { "title": "Github", "icon": "github", "url": "https://github.com/realcoin-rea/rea" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://trezor.real-coin.in/block/",
          "tx": "https://trezor.real-coin.in/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "rlc.png",
        "name": "Realcoin",
        "new": false,
        "active": false,
        "algo": "sha256csm",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "KYAN": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=15",
          "payments": "https://web.letshash.it/site/payments_results_json?id=15",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=x11k"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "50 KYAN",
          "payInterval": "2 hours",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 3534, "difficulty": 1, "desc": "Low end hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Kyanite website", "icon": "website", "url": "https://kyancoin.net/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/kyancoin/KYAN" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "x11k GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.kyancoin.net/block/",
          "tx": "https://explorer.kyancoin.net/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "kyan.png",
        "name": "Kyanite",
        "new": false,
        "active": false,
        "algo": "x11k",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "CURVE": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=24",
          "payments": "https://web.letshash.it/site/payments_results_json?id=24",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "5 CURVE",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3343, "difficulty": 0.0001, "desc": "Low end hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Curvehash website", "icon": "website", "url": "http://www.curvehash.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/curvehashcoin/" }
        ],
        "minerLinks": [
          { "name": "CPUminer Curvehash", "arch": "CPU", "url": "https://github.com/curvehashcoin/cpuminer-curvehash", "title": "Curvehash CPU miner" }
        ],
        "explorerLinks": {
          "block": "http://explorer.curvehash.com/block/",
          "tx": "http://explorer.curvehash.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "curve.png",
        "name": "Curvehash",
        "new": false,
        "active": false,
        "algo": "curvehash",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "ZENX": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=26",
          "payments": "https://web.letshash.it/site/payments_results_json?id=26",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "5 ZENX",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 8734, "difficulty": 0.00003, "desc": "Low end hardware", "donation": 0 },
          { "port": 8735, "difficulty": 0.00006, "desc": "More CPUs", "donation": 0 }
        ],
        "links": [
          { "title": "Zentoshi website", "icon": "website", "url": "http://www.zentoshi.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/zentoshi/" }
        ],
        "minerLinks": [
          { "name": "CPUminer Balloon", "arch": "CPU", "url": "https://github.com/zentoshi/cpuminer-opt-balloon", "title": "Zentoshi CPU miner" }
        ],
        "explorerLinks": {
          "block": "http://explorer.zentoshi.com/block/",
          "tx": "http://explorer.zentoshi.com/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "zenx.png",
        "name": "Zentoshi",
        "new": false,
        "active": false,
        "algo": "balloon",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "SCC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=13",
          "payments": "https://web.letshash.it/site/payments_results_json?id=13",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 SCC",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 3533, "difficulty": 1, "desc": "Low end hardware", "donation": 0 }
        ],
        "links": [
          { "title": "StakeCibeCoin website", "icon": "website", "url": "https://stakecube.net/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/stakecube/StakeCubeCoin" }
        ],
        "minerLinks": [
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer", "title": "NVIDIA GPU x11 Miner" }
        ],
        "explorerLinks": {
          "block": "https://www.coinexplorer.net/SCC/block/",
          "tx": "https://www.coinexplorer.net/SCC/transaction/"
        },
        "url": "https://web.letshash.it",
        "img": "scc.png",
        "name": "StakeCubeCoin",
        "new": false,
        "active": false,
        "algo": "x11",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "GXX": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=32",
          "payments": "https://web.letshash.it/site/payments_results_json?id=32",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 gxx",
          "payInterval": "2 hours"
        },
        "ports": [
          { "port": 4555, "difficulty": 0.0001, "desc": "Low end hardware", "donation": 0 }
        ],
        "links": [
          { "title": "GravityCoin website", "icon": "website", "url": "https://www.reddit.com/r/GravityCoinOfficial" },
          { "title": "Github", "icon": "github", "url": "https://github.com/SpecialCoins/GravityCoin" }
        ],
        "minerLinks": [
          { "name": "CPU miner", "arch": "CPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases", "title": "CPU miners" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/rplant8/cpuminer-opt-rplant/releases/download/ccminer/ccminer-win-cuda10.zip", "title": "NVIDIA GPU  Miner" }
        ],
        "explorerLinks": {
          "block": "https://www.blockseek.io/gxx/block/",
          "tx": "https://www.blockseek.io/gxx/tx/"
        },
        "url": "https://web.letshash.it",
        "img": "gxx.png",
        "name": "GravityCoin",
        "new": false,
        "active": false,
        "algo": "lyra2z330",
        "unitPlaces": 7,
        "addressPrefix": 0
      },
      "CXCHE": {
        "type": "cn",
        "endpoints": {
          "stats": "https://cxche.letshash.it/api/stats",
          "blocks": "https://cxche.letshash.it/api/stats",
          "top10": "https://cxche.letshash.it/api/get_top10miners",
          "payments": "https://cxche.letshash.it/api/get_payments?time={time}",
          "wallet": "https://cxche.letshash.it/api/stats_address?address={address}",
          "settings": "https://cxche.letshash.it/api/{md}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 CXCHE",
          "payInterval": "2minutes"
        },
        "ports": [
          { "port": 1500, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1501, "difficulty": 20000, "desc": "High end hardware", "donation": 0 },
          { "port": 1502, "difficulty": 100000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Cache website", "icon": "website", "url": "https://cxche.org/" },
          { "title": "Cache github", "icon": "github", "url": "https://github.com/Cache-core" }
        ],
        "minerLinks": [
          { "name": "XMRig cn_hash support", "arch": "CPU", "url": "https://github.com/Cache-core/xmrig/releases", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.cxche.org/index.html?hash=",
          "tx": "https://explorer.cxche.org/index.html?hash="
        },
        "url": "https://cxche.letshash.it",
        "img": "cxche.png",
        "name": "CACHE",
        "new": false,
        "active": false,
        "coinUnits": 100000,
        "decimalPlaces": 5,
        "algo": "CryptoNight CacheHash",
        "unitPlaces": 5,
        "addressPrefix": 43810262
      },
      "CCX": {
        "type": "cn",
        "endpoints": {
          "stats": "https://ccx.letshash.it/api/stats",
          "blocks": "https://ccx.letshash.it/api/stats",
          "top10": "https://ccx.letshash.it/api/get_top10miners",
          "payments": "https://ccx.letshash.it/api/get_payments?time={time}",
          "wallet": "https://ccx.letshash.it/api/stats_address?address={address}",
          "settings": "https://ccx.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.05 CCX",
          "payInterval": "10 minutes"
        },
        "ports": [
          { "port": 1574, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1575, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1576, "difficulty": 50000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Conceal website", "icon": "website", "url": "https://conceal.network/" },
          { "title": "Conceal github", "icon": "github", "url": "https://github.com/ConcealNetwork/conceal-core" }
        ],
        "minerLinks": [
          { "name": "XMRig Nvidia", "arch": "GPU", "url": "https://github.com/xmrig/xmrig-nvidia", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "XMR Stak", "arch": "GPU", "url": "https://github.com/fireice-uk/xmr-stak/releases/tag/2.10.8", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.conceal.network/index.html?hash=",
          "tx": "http://explorer.conceal.network/index.html?hash="
        },
        "url": "https://ccx.letshash.it",
        "img": "ccx.png",
        "name": "CONCEAL",
        "new": false,
        "active": false,
        "coinUnits": 1000000,
        "decimalPlaces": 6,
        "algo": "CryptoNight GPU",
        "unitPlaces": 5,
        "addressPrefix": 31444,
        "coingeckoID": "conceal"
      },
      "CIRQ": {
        "type": "cn",
        "endpoints": {
          "stats": "https://cirq.letshash.it/api/stats",
          "blocks": "https://cirq.letshash.it/api/stats",
          "payments": "https://cirq.letshash.it/api/get_payments?time={time}",
          "wallet": "https://cirq.letshash.it/api/stats_address?address={address}",
          "settings": "https://cirq.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "500 CIRQ",
          "payInterval": "10minutes"
        },
        "ports": [
          { "port": 1506, "difficulty": 2500, "desc": "Low end hardware", "donation": 0 },
          { "port": 1507, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1508, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Cirquity website", "icon": "website", "url": "https://cirquity.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/cirquity/cirquity" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.cirquity.com/block.html?hash=",
          "tx": "https://explorer.cirquity.com/transaction.html?hash="
        },
        "url": "https://cirq.letshash.it",
        "img": "cirq.png",
        "name": "CIRQUITY",
        "new": false,
        "active": false,
        "coinUnits": 100,
        "decimalPlaces": 2,
        "algo": "CryptoNight-Pico/Trtl",
        "unitPlaces": 2,
        "addressPrefix": 3934293
      },
      "CPA": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:15094/stats",
          "blocks": "https://letshash.it:15094/stats",
          "top10": "https://letshash.it:15094/get_top10miners",
          "payments": "https://letshash.it:15094/get_payments?time={time}",
          "wallet": "https://letshash.it:15094/stats_address?address={address}",
          "settings": "https://letshash.it:15094/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "10 CPA",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 1707, "difficulty": 2500, "desc": "Low end hardware", "donation": 0 },
          { "port": 1708, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1709, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Cpacoin website", "icon": "website", "url": "https://cryptopay.org.za/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/devafrica" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://api.cryptopay.org.za/?hash=",
          "tx": "https://api.cryptopay.org.za/?hash="
        },
        "url": "https://cpa.letshash.it",
        "img": "cpa.png",
        "name": "CRYPTO PAY AFRICA",
        "new": false,
        "active": false,
        "coinUnits": 1000000,
        "decimalPlaces": 6,
        "algo": "CryptoNight-Pico/Trtl",
        "unitPlaces": 6,
        "addressPrefix": 1515348
      },
      "CRFI": {
        "type": "cn",
        "endpoints": {
          "stats": "https://crfi.letshash.it/api/stats",
          "blocks": "https://crfi.letshash.it/api/stats",
          "top10": "https://crfi.letshash.it/api/get_top10miners",
          "payments": "https://crfi.letshash.it/api/get_payments?time={time}",
          "wallet": "https://crfi.letshash.it/api/stats_address?address={address}",
          "settings": "https://crfi.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.000005 CRFI",
          "payInterval": "30seconds"
        },
        "ports": [
          { "port": 1509, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1510, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1511, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Crystaleum website", "icon": "website", "url": "https://crystaleum.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/crystaleum/crystaleum" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://oracle.crystaleum.org/search?value=",
          "tx": "https://oracle.crystaleum.org/search?value="
        },
        "url": "https://crfi.letshash.it",
        "img": "crfi.png",
        "name": "CRYSTALEUM",
        "new": false,
        "active": false,
        "coinUnits": 1000000000000,
        "decimalPlaces": 12,
        "algo": "CryptoNight-Fast",
        "unitPlaces": 12,
        "addressPrefix": 343,
        "coingeckoID": "CRYSTALEUM"
      },
      "DMCH": {
        "type": "cn-go",
        "endpoints": {
          "stats": "https://letshash.it:16054/api/stats",
          "blocks": "https://letshash.it:16054/api/blocks",
          "payments": "https://letshash.it:16054/api/payments?time={time}",
          "wallet": "https://letshash.it:16054/api/accounts?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.05 DMCH",
          "payInterval": "30 seconds"
        },
        "ports": [
          { "port": 1662, "difficulty": 200000, "desc": "BaikalN+, RR2XX", "donation": 0 },
          { "port": 1663, "difficulty": 1500000, "desc": "A8 >150K & < 200K", "donation": 0 },
          { "port": 1664, "difficulty": 6000000, "desc": "X3 Default", "donation": 0 },
          { "port": 1665, "difficulty": 9000000, "desc": "X3 OC", "donation": 0 },
          { "port": 1666, "difficulty": 12000000, "desc": "Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Darmacash website", "icon": "website", "url": "https://darmacash.com" },
          { "title": "Darmacash github", "icon": "github", "url": "https://github.com/darmaproject/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.darmacash.com/block?block=",
          "tx": "https://explorer.darmacash.com/tx?hash="
        },
        "url": "https://dmch.letshash.it",
        "img": "dmch.png",
        "name": "DARMACASH",
        "new": false,
        "active": false,
        "coinUnits": 100000000,
        "decimalPlaces": 6,
        "algo": "Cryptonight ASIC",
        "unitPlaces": 6,
        "addressPrefix": 31444,
        "coingeckoID": "darma-cash"
      },
      "DERO": {
        "type": "cn-go",
        "endpoints": {
          "stats": "https://letshash.it:16058/api/stats",
          "blocks": "https://letshash.it:16058/api/blocks",
          "payments": "https://letshash.it:16058/api/payments?time={time}",
          "wallet": "https://letshash.it:16058/api/accounts?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.01 DERO",
          "payInterval": "1 minute"
        },
        "ports": [
          { "port": 1464, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1465, "difficulty": 2500, "desc": "High end hardware", "donation": 0 },
          { "port": 1466, "difficulty": 10000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Dero website", "icon": "website", "url": "https://dero.io/" },
          { "title": "Dero github", "icon": "github", "url": "https://github.com/deroproject/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.dero.io/block/",
          "tx": "https://explorer.dero.io/tx/"
        },
        "url": "https://dero.letshash.it",
        "img": "dero.png",
        "name": "DERO",
        "new": false,
        "active": false,
        "coinUnits": 1000000000000,
        "decimalPlaces": 4,
        "algo": "AstroBWT",
        "unitPlaces": 4,
        "addressPrefix": 823000,
        "coingeckoID": "dero"
      },
      "ETNXP": {
        "type": "cn",
        "endpoints": {
          "stats": "https://etnxp.letshash.it/api/stats",
          "blocks": "https://etnxp.letshash.it/api/stats",
          "top10": "https://etnxp.letshash.it/api/get_top10miners",
          "payments": "https://etnxp.letshash.it/api/get_payments?time={time}",
          "wallet": "https://etnxp.letshash.it/api/stats_address?address={address}",
          "settings": "https://etnxp.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "100 ETNXP",
          "payInterval": "2 minutes"
        },
        "ports": [
          { "port": 1515, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1516, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1517, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "ElectroneroPulse website", "icon": "website", "url": "https://electroneropulse.org/" },
          { "title": "ElectroneroPulse github", "icon": "github", "url": "https://github.com/electronero-pulse/electroneropulse/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://blockexplorer.electroneropulse.org/search?value=",
          "tx": "https://blockexplorer.electroneropulse.org/search?value="
        },
        "url": "https://etnxp.letshash.it",
        "img": "etnxp.png",
        "name": "ELECTRONEROPULSE",
        "new": false,
        "active": false,
        "coinUnits": 1000000,
        "decimalPlaces": 6,
        "algo": "CryptoNight-Fast",
        "unitPlaces": 6,
        "addressPrefix": 18018,
        "coingeckoID": "electronero-pulse"
      },
      "GLDX": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:15016/stats",
          "blocks": "https://letshash.it:15016/stats",
          "payments": "https://letshash.it:15016/get_payments?time={time}",
          "wallet": "https://letshash.it:15016/stats_address?address={address}",
          "settings": "https://letshash.it:15016/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 GLDX",
          "payInterval": "5 seconds"
        },
        "ports": [
          { "port": 1518, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1519, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1520, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Goldnero website", "icon": "website", "url": "https://goldnero.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/goldnero/goldnero" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://blockexplorer.goldnero.org/search?value=",
          "tx": "https://blockexplorer.goldnero.org/search?value="
        },
        "url": "https://gldx.letshash.it",
        "img": "gldx.png",
        "name": "GOLDNERO",
        "new": false,
        "active": false,
        "coinUnits": 1000000000000,
        "decimalPlaces": 12,
        "algo": "CryptoNight-Fast",
        "unitPlaces": 12,
        "addressPrefix": 18018
      },
      "KLR": {
        "type": "cn",
        "endpoints": {
          "stats": "https://klr.letshash.it/api/stats",
          "blocks": "https://klr.letshash.it/api/stats",
          "top10": "https://klr.letshash.it/api/get_top10miners",
          "payments": "https://klr.letshash.it/api/get_payments?time={time}",
          "wallet": "https://klr.letshash.it/api/stats_address?address={address}",
          "settings": "https://klr.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "5 KLR",
          "payInterval": "2minutes"
        },
        "ports": [
          { "port": 1521, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1522, "difficulty": 15000, "desc": "High end hardware", "donation": 0 },
          { "port": 1523, "difficulty": 300000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Crystaleum website", "icon": "website", "url": "https://klaro.ooo/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/klaroooo/klaro/" }
        ],
        "minerLinks": [
          { "name": "XMRig/RandomXHFPI", "arch": "CPU", "url": "https://github.com/klaroooo/xmrig-randomxhfpi/", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://blocks.klaro.ooo/search?value=",
          "tx": "http://blocks.klaro.ooo/search?value="
        },
        "url": "https://klr.letshash.it",
        "img": "klr.png",
        "name": "KLARO",
        "new": false,
        "active": false,
        "coinUnits": 1000000000000,
        "decimalPlaces": 4,
        "algo": "RandomXHFPI",
        "unitPlaces": 4,
        "addressPrefix": 214,
        "coingeckoID": "klaro"
      },
      "KVA": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:16021/stats",
          "blocks": "https://letshash.it:16021/stats",
          "payments": "https://letshash.it:16021/get_payments?time={time}",
          "wallet": "https://letshash.it:16021/stats_address?address={address}",
          "settings": "https://letshash.it:16021/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 KVA",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 1524, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1525, "difficulty": 15000, "desc": "High end hardware", "donation": 0 },
          { "port": 1526, "difficulty": 50000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Kevacoin website", "icon": "website", "url": "https://kevacoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/kevacoin-project/kevacoin" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.kevacoin.org/block/",
          "tx": "https://explorer.kevacoin.org/tx/"
        },
        "url": "https://kva.letshash.it",
        "img": "kva.png",
        "name": "KEVACOIN",
        "new": false,
        "active": false,
        "coinUnits": 100000000,
        "decimalPlaces": 4,
        "algo": "RandomX/Keva",
        "unitPlaces": 4,
        "addressPrefix": 1515348,
        "coingeckoID": "kevacoin"
      },
      "LTNX": {
        "type": "cn",
        "endpoints": {
          "stats": "https://ltnx.letshash.it/api/stats",
          "blocks": "https://ltnx.letshash.it/api/stats",
          "top10": "https://ltnx.letshash.it/api/get_top10miners",
          "payments": "https://ltnx.letshash.it/api/get_payments?time={time}",
          "wallet": "https://ltnx.letshash.it/api/stats_address?address={address}",
          "settings": "https://ltnx.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "10000 LTNX",
          "payInterval": "1minute"
        },
        "ports": [
          { "port": 1527, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1528, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1529, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Litenero website", "icon": "website", "url": "https://litenero.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/litenero/litenero" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://blockexplorer.litenero.org/search?value=",
          "tx": "https://explorer.litenero.org/index.html?hash="
        },
        "url": "https://ltnx.letshash.it",
        "img": "ltnx.png",
        "name": "LITENERO",
        "new": false,
        "active": false,
        "algo": "CryptoNight-Fast",
        "coinUnits": 100000000,
        "decimalPlaces": 8,
        "unitPlaces": 8,
        "addressPrefix": 18018,
        "coingeckoID": "litenero"
      },
      "QUE": {
        "type": "cn",
        "endpoints": {
          "stats": "https://qmr.letshash.it/api/stats",
          "blocks": "https://qmr.letshash.it/api/stats",
          "top10": "https://qmr.letshash.it/api/get_top10miners",
          "payments": "https://qmr.letshash.it/api/get_payments?time={time}",
          "wallet": "https://qmr.letshash.it/api/stats_address?address={address}",
          "settings": "https://qmr.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "50 QMR",
          "payInterval": "1 minute"
        },
        "ports": [
          { "port": 1624, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1625, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1626, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Quenero website", "icon": "website", "url": "https://quenero.tech" },
          { "title": "Github", "icon": "github", "url": "https://github.com/quenero/quenero" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.quenero.tech/block/",
          "tx": "https://explorer.quenero.tech/tx/"
        },
        "url": "https://qmr.letshash.it",
        "img": "que.png",
        "name": "Quenero",
        "new": false,
        "active": false,
        "algo": "RandomX",
        "coinUnits": 1000000000000,
        "decimalPlaces": 12,
        "unitPlaces": 12,
        "addressPrefix": 53
      },
      "RS": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:15081/stats",
          "blocks": "https://letshash.it:15081/stats",
          "payments": "https://letshash.it:15081/get_payments?time={time}",
          "wallet": "https://letshash.it:15081/stats_address?address={address}",
          "settings": "https://letshash.it:15081/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "5 RS",
          "payInterval": "1 minute"
        },
        "ports": [
          { "port": 1580, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1581, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1582, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Redstone website", "icon": "website", "url": "http://redstone.crypto.ba/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/RedStoneCoin/redstone" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "GPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://178.236.85.196/explorer/?hash=",
          "tx": "http://178.236.85.196/explorer/?hash="
        },
        "url": "https://rs.letshash.it",
        "img": "rs.png",
        "name": "REDSTONE",
        "new": false,
        "active": false,
        "algo": "Chukwa",
        "coinUnits": 1000000,
        "decimalPlaces": 6,
        "unitPlaces": 6,
        "addressPrefix": 149
      },
      "MSR": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:15050/stats",
          "blocks": "https://letshash.it:15050/stats",
          "payments": "https://letshash.it:15050/get_payments?time={time}",
          "wallet": "https://letshash.it:15050/stats_address?address={address}",
          "settings": "https://letshash.it:15050/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 MSR",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 1571, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1572, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1573, "difficulty": 50000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Masari website", "icon": "website", "url": "https://getmasari.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.getmasari.org/block.html?hash=",
          "tx": "https://explorer.getmasari.org/transaction.html?hash="
        },
        "url": "https://msr.letshash.it",
        "img": "msr.png",
        "name": "MASARI",
        "new": false,
        "active": false,
        "algo": "CryptoNight V8 Half",
        "coinUnits": 1000000000000,
        "decimalPlaces": 4,
        "unitPlaces": 4,
        "addressPrefix": 28,
        "coingeckoID": "masari"
      },
      "NBXC": {
        "type": "cn",
        "endpoints": {
          "stats": "https://nbxc.letshash.it/api/stats",
          "blocks": "https://nbxc.letshash.it/api/stats",
          "top10": "https://nbxc.letshash.it/api/get_top10miners",
          "payments": "https://nbxc.letshash.it/api/get_payments?time={time}",
          "wallet": "https://nbxc.letshash.it/api/stats_address?address={address}",
          "settings": "https://nbxc.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.1 NBXC",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 1530, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1531, "difficulty": 5000, "desc": "High end hardware", "donation": 0 },
          { "port": 1532, "difficulty": 50000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Nibble website", "icon": "website", "url": "https://nibble-nibble.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://nibbling.org/?hash=",
          "tx": "http://nibbling.org/?hash="
        },
        "url": "https://nbxc.letshash.it",
        "img": "nbxc.png",
        "name": "NIBBLE",
        "new": false,
        "active": false,
        "algo": "CryptoNight Conceal",
        "coinUnits": 1000000,
        "decimalPlaces": 6,
        "unitPlaces": 6,
        "addressPrefix": 17588,
        "coingeckoID": "nibble"
      },
      "NINJA": {
        "type": "cn",
        "endpoints": {
          "stats": "https://ninja.letshash.it/api/stats",
          "blocks": "https://ninja.letshash.it/api/stats",
          "top10": "https://ninja.letshash.it/api/get_top10miners",
          "payments": "https://ninja.letshash.it/api/get_payments?time={time}",
          "wallet": "https://ninja.letshash.it/api/stats_address?address={address}",
          "settings": "https://ninja.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "50 NINJA",
          "payInterval": "5minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 1533, "difficulty": 50000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1534, "difficulty": 200000, "desc": "High end hardware", "donation": 0 },
          { "port": 1535, "difficulty": 500000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "NinjaCoin website", "icon": "website", "url": "https://ninjacoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig NINJA", "arch": "CPU", "url": "https://github.com/NinjaCoin-Master/xmrig-ninja/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu NINJA", "arch": "GPU", "url": "https://github.com/NinjaCoin-Master/ninjaminer/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.ninjacoin.org/block.html?hash=",
          "tx": "https://explorer.ninjacoin.org/transaction.html?hash="
        },

        "url": "https://ninja.letshash.it",
        "img": "ninja.png",
        "name": "NINJACOIN",
        "new": false,
        "active": false,
        "algo": "Argon2id/Ninja",
        "coinUnits": 100000,
        "decimalPlaces": 5,
        "unitPlaces": 5,
        "addressPrefix": 10115542401
      },
      "XCC": {
        "type": "cn",
        "endpoints": {
          "stats": "https://xcc.letshash.it/api/stats",
          "blocks": "https://xcc.letshash.it/api/stats",
          "top10": "https://xcc.letshash.it/api/get_top10miners",
          "payments": "https://xcc.letshash.it/api/get_payments?time={time}",
          "wallet": "https://xcc.letshash.it/api/stats_address?address={address}",
          "settings": "https://xcc.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 XCC",
          "payInterval": "5minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 1915, "difficulty": 10000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1916, "difficulty": 100000, "desc": "High end hardware", "donation": 0 },
          { "port": 1917, "difficulty": 2500000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Cryptic website", "icon": "website", "url": "http://crypticnetwork.cf/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/cryptic-network/cryptic" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.crypticnetwork.cf/?hash=",
          "tx": "http://explorer.crypticnetwork.cf/?hash="
        },
        "url": "https://xcc.letshash.it",
        "img": "xcc.png",
        "name": "Cryptic",
        "new": false,
        "active": false,
        "algo": "argon2/chukwa",
        "coinUnits": 100000000,
        "decimalPlaces": 8,
        "unitPlaces": 8,
        "addressPrefix": 16820
      },
      "WSBC": {
        "type": "cn",
        "endpoints": {
          "stats": "https://wsbc.letshash.it/api/stats",
          "blocks": "https://wsbc.letshash.it/api/stats",
          "top10": "https://wsbc.letshash.it/api/get_top10miners",
          "payments": "https://wsbc.letshash.it/api/get_payments?time={time}",
          "wallet": "https://wsbc.letshash.it/api/stats_address?address={address}",
          "settings": "https://wsbc.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 WSBC",
          "payInterval": "5 minutes",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 1815, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1816, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1817, "difficulty": 250000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Wallstreetbets website", "icon": "website", "url": "https://wallstreetbetsbros.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/wsbc-coin/wallstreetbets" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.wallstreetbetsbros.com/search?value=+",
          "tx": "http://explorer.wallstreetbetsbros.com/search?value=+"
        },
        "url": "https://wsbc.letshash.it",
        "img": "wsbc.png",
        "name": "WALLSTREETBETSCOIN",
        "new": false,
        "active": false,
        "algo": "randomx ARQ",
        "coinUnits": 1000000000,
        "decimalPlaces": 9,
        "unitPlaces": 9,
        "addressPrefix": 2309295
      },
      "CRYPTO": {
        "type": "cn",
        "endpoints": {
          "stats": "https://crypto.letshash.it/api/stats",
          "blocks": "https://crypto.letshash.it/api/stats",
          "top10": "https://crypto.letshash.it/api/get_top10miners",
          "payments": "https://crypto.letshash.it/api/get_payments?time={time}",
          "wallet": "https://crypto.letshash.it/api/stats_address?address={address}",
          "settings": "https://crypto.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.1 CRYPTO",
          "payInterval": "5 minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 8008, "difficulty": 2000, "desc": "GPU+CPU", "donation": 0, "ssl": false },
          { "port": 8018, "difficulty": 50000, "desc": "GPU+CPU", "donation": 0, "ssl": false },
          { "port": 8028, "difficulty": 250000, "desc": "GPU+CPU", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Cryptocoin website", "icon": "website", "url": "http://crypt-o-coin.cash/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/GonzoTheDev/crypto" }
        ],
        "minerLinks": [
          { "name": "XMRStak", "arch": "GPU+CPU", "url": "https://github.com/fireice-uk/xmr-stak/releases/tag/2.10.8", "title": "Ligthweight but powerful GPU + CPU Mining App" },
          { "name": "XMRigCC", "arch": "CPU", "url": "https://github.com/Bendr0id/xmrigCC/releases", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.crypt-o-coin.cash/block/",
          "tx": "http://explorer.crypt-o-coin.cash/tx/"
        },
        "url": "https://crypto.letshash.it",
        "img": "crypto.png",
        "name": "CRYPTOCOIN",
        "new": false,
        "active": false,
        "algo": "Cryptonight SuperFast",
        "coinUnits": 1000000000000,
        "decimalPlaces": 12,
        "unitPlaces": 12,
        "addressPrefix": 925524
      },
      "NIMBO": {
        "type": "cn",
        "endpoints": {
          "stats": "https://nimbo.letshash.it/api/stats",
          "blocks": "https://nimbo.letshash.it/api/stats",
          "top10": "https://nimbo.letshash.it/api/get_top10miners",
          "payments": "https://nimbo.letshash.it/api/get_payments?time={time}",
          "wallet": "https://nimbo.letshash.it/api/stats_address?address={address}",
          "settings": "https://nimbo.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "100000 NIMBO",
          "payInterval": "1.5 minutes"
        },
        "ports": [
          { "port": 1715, "difficulty": 2500, "desc": "Low end hardware", "donation": 0 },
          { "port": 1716, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1717, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "NimboCoin website", "icon": "website", "url": "https://www.nimbocoin.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/nimbocoin/nimbocoin" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.nimbocoin.com/?hash=",
          "tx": "http://explorer.nimbocoin.com/?hash="
        },
        "url": "https://nimbo.letshash.it",
        "img": "nimbo.png",
        "name": "NIMBOCOIN",
        "new": false,
        "active": false,
        "algo": "CryptoNight-Pico/Trtl",
        "coinUnits": 100,
        "decimalPlaces": 2,
        "unitPlaces": 2,
        "addressPrefix": 912769
      },
      "QRL": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:1605/stats",
          "blocks": "https://letshash.it:1605/stats",
          "payments": "https://letshash.it:1605/get_payments?time={time}",
          "wallet": "https://letshash.it:1605/stats_address?address={address}",
          "settings": "https://letshash.it:1605/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 QRL",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 1533, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1534, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1535, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Quantum Resistant Ledger website", "icon": "website", "url": "https://theqrl.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.cxche.org/index.html?hash=",
          "tx": "https://explorer.cxche.org/index.html?hash="
        },
        "url": "https://qrl.letshash.it",
        "img": "qrl.png",
        "name": "QUANTUM RESISTANCE LEDGER",
        "new": false,
        "active": false,
        "algo": "RandomX",
        "unitPlaces": 9,
        "addressPrefix": 1515348
      },
      "SPWN": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:15030/stats",
          "blocks": "https://letshash.it:15030/stats",
          "payments": "https://letshash.it:15030/get_payments?time={time}",
          "wallet": "https://letshash.it:15030/stats_address?address={address}",
          "settings": "https://letshash.it:15030/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "10 SPWN",
          "payInterval": "2minutes"
        },
        "ports": [
          { "port": 1539, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1540, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1541, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Spawn website", "icon": "website", "url": "https://spawncoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://78.141.213.139/?hash=",
          "tx": "http://78.141.213.139/?hash="
        },
        "url": "https://spwn.letshash.it",
        "img": "spwn.png",
        "name": "SPAWN",
        "new": false,
        "active": false,
        "algo": "Cryptonight-Pico/Trtl",
        "coinUnits": 100000000,
        "decimalPlaces": 8,
        "unitPlaces": 8,
        "addressPrefix": 25751
      },
      "XCG": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:15092/stats",
          "blocks": "https://letshash.it:15092/stats",
          "payments": "https://letshash.it:15092/get_payments?time={time}",
          "wallet": "https://letshash.it:15092/stats_address?address={address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.05 XCG",
          "payInterval": "2minutes"
        },
        "ports": [
          { "port": 1597, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1598, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1599, "difficulty": 200000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "CardGenix website", "icon": "website", "url": "http://cardgenix.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.cxche.org/index.html?hash=",
          "tx": "https://explorer.cxche.org/index.html?hash="
        },
        "url": "https://xcg.letshash.it",
        "img": "xcg.png",
        "name": "CARDGENIX",
        "new": false,
        "active": false,
        "algo": "Chukwa",
        "unitPlaces": 8,
        "addressPrefix": 1515348
      },
      "XEQ": {
        "type": "cn",
        "endpoints": {
          "stats": "https://xeq.letshash.it/api/stats",
          "blocks": "https://xeq.letshash.it/api/stats",
          "top10": "https://xeq.letshash.it/api/get_top10miners",
          "payments": "https://xeq.letshash.it/api/get_payments?time={time}",
          "wallet": "https://xeq.letshash.it/api/stats_address?address={address}",
          "settings": "https://xeq.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 XEQ",
          "payInterval": "2minutes"
        },
        "ports": [
          { "port": 1543, "difficulty": 1500, "desc": "Low end hardware", "donation": 0 },
          { "port": 1544, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1545, "difficulty": 100000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Equlibria website", "icon": "website", "url": "http://equlibria.network" },
          { "title": "Github", "icon": "github", "url": "https://github.com/EquilibriaCC/Equilibria/" }
        ],
        "minerLinks": [
          { "name": "XMRStak", "arch": "GPU", "url": "https://github.com/fireice-uk/xmr-stak/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.equilibria.network/block/",
          "tx": "https://explorer.equilibria.network/tx/"
        },
        "url": "https://xeq.letshash.it",
        "img": "xeq.png",
        "name": "EQUILIBRIA",
        "new": false,
        "active": false,
        "algo": "Cryptonight-Gpu",
        "coinUnits": 10000,
        "decimalPlaces": 4,
        "unitPlaces": 4,
        "addressPrefix": 289,
        "coingeckoID": "equilibria"
      },
      "RYO": {
        "type": "cn",
        "endpoints": {
          "stats": "https://ryo.letshash.it/api/stats",
          "blocks": "https://ryo.letshash.it/api/stats",
          "top10": "https://ryo.letshash.it/api/get_top10miners",
          "payments": "https://ryo.letshash.it/api/get_payments?time={time}",
          "wallet": "https://ryo.letshash.it/api/stats_address?address={address}",
          "settings": "https://ryo.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 RYO",
          "payInterval": "2 minutes"
        },
        "ports": [
          { "port": 1780, "difficulty": 1500, "desc": "Low end hardware", "donation": 0 },
          { "port": 1581, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1582, "difficulty": 100000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Ryo Currency website", "icon": "website", "url": "https://ryo-currency.com" },
          { "title": "Github", "icon": "github", "url": "https://github.com/ryo-currency/ryo-currency" }
        ],
        "minerLinks": [
          { "name": "XMRStak", "arch": "GPU", "url": "https://github.com/fireice-uk/xmr-stak/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.ryo-currency.com/block/",
          "tx": "https://explorer.ryo-currency.com/tx/"
        },
        "url": "https://ryo.letshash.it",
        "img": "ryo.png",
        "name": "RYO CURRENCY",
        "new": false,
        "active": false,
        "algo": "Cryptonight-Gpu",
        "coinUnits": 1000000000,
        "decimalPlaces": 4,
        "unitPlaces": 4,
        "addressPrefix": 2941330,
        "coingeckoID": "ryo-currency"
      },
      "XFG": {
        "type": "cn",
        "endpoints": {
          "stats": "https://xfg.letshash.it/api/stats",
          "blocks": "https://xfg.letshash.it/api/stats",
          "top10": "https://xfg.letshash.it/api/get_top10miners",
          "payments": "https://xfg.letshash.it/api/get_payments?time={time}",
          "wallet": "https://xfg.letshash.it/api/stats_address?address={address}",
          "settings": "https://xfg.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.5 XEQ",
          "payInterval": "2minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 1546, "difficulty": 1500, "desc": "Low end hardware", "donation": 0 },
          { "port": 1547, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1548, "difficulty": 100000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Fandom Gold website", "icon": "website", "url": "https://fandom.gold" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.fandom.gold/?hash=",
          "tx": "http://explorer.fandom.gold/?hash="
        },
        "url": "https://xfg.letshash.it",
        "img": "xfg.png",
        "name": "FANDOM GOLD",
        "new": false,
        "active": false,
        "algo": "Cryptonight V8",
        "coinUnits": 10000000,
        "decimalPlaces": 7,
        "unitPlaces": 7,
        "addressPrefix": 203478502,
        "coingeckoID": "fango"
      },
      "XKR": {
        "type": "cn",
        "endpoints": {
          "stats": "https://xkr.letshash.it/api/stats",
          "blocks": "https://xkr.letshash.it/api/stats",
          "top10": "https://xkr.letshash.it/api/get_top10miners",
          "payments": "https://xkr.letshash.it/api/get_payments?time={time}",
          "wallet": "https://xkr.letshash.it/api/stats_address?address={address}",
          "settings": "https://xkr.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "50 %",
          "minPayout": "10000 XKR",
          "payInterval": "2 minutes"
        },
        "ports": [
          { "port": 6995, "difficulty": 15000, "desc": "Low end hardware", "donation": 0 },
          { "port": 6996, "difficulty": 60000, "desc": "High end hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Kryptokrona website", "icon": "website", "url": "https://kryptokrona.se" },
          { "title": "Github", "icon": "github", "url": "https://github.com/kryptokrona/kryptokrona" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.kryptokrona.se/?hash=",
          "tx": "https://explorer.kryptokrona.se/?hash="
        },
        "url": "https://xkr.letshash.it",
        "img": "xkr.png",
        "name": "KRYPTOKRONA",
        "new": false,
        "active": false,
        "algo": "Cryptonight-Pico/Trtl",
        "coinUnits": 100000,
        "decimalPlaces": 5,
        "unitPlaces": 5,
        "addressPrefix": 2239254,
        "coingeckoID": "kryptokrona"
      },
      "DEGO": {
        "type": "cn",
        "endpoints": {
          "stats": "https://dego.letshash.it/api/stats",
          "blocks": "https://dego.letshash.it/api/stats",
          "top10": "https://dego.letshash.it/api/get_top10miners",
          "payments": "https://dego.letshash.it/api/get_payments?time={time}",
          "wallet": "https://dego.letshash.it/api/stats_address?address={address}",
          "settings": "https://dego.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1000 DEGO",
          "payInterval": "2 minutes"
        },
        "ports": [
          { "port": 7397, "difficulty": 2000, "desc": "Mid end hardware", "donation": 0 },
          { "port": 7398, "difficulty": 10000, "desc": "High end hardware", "donation": 0 }
        ],
        "links": [
          { "title": "DeroGold website", "icon": "website", "url": "http://derogold.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/derogold/derogold" }
        ],
        "minerLinks": [
          { "name": "XmrigUPX", "arch": "CPU", "url": "https://github.com/uPlexa/xmrig-upx/releases/tag/v0.2.0", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "SRBminer", "arch": "CPU", "url": "https://github.com/doktor83/SRBMiner-Multi/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.derogold.com/block.html?hash=",
          "tx": "https://explorer.derogold.com/transaction.html?hash="
        },
        "url": "https://dego.letshash.it",
        "img": "dego.png",
        "name": "DeroGold",
        "new": false,
        "active": false,
        "algo": "Cryptonight-UPX/2",
        "coinUnits": 100,
        "decimalPlaces": 2,
        "unitPlaces": 2,
        "addressPrefix": 8411,
        "coingeckoID": "derogold"
      },
      "XLA": {
        "type": "cn",
        "endpoints": {
          "stats": "https://xla.letshash.it/api/stats",
          "blocks": "https://xla.letshash.it/api/stats",
          "payments": "https://xla.letshash.it/api/get_payments?time={time}",
          "wallet": "https://xla.letshash.it/api/stats_address?address={address}",
          "settings": "https://xla.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "100 XLA",
          "payInterval": "10 minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 1559, "difficulty": 1000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1560, "difficulty": 10000, "desc": "High end hardware", "donation": 0 },
          { "port": 1561, "difficulty": 100000, "desc": "MiningRigs", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Scala website", "icon": "website", "url": "https://scalaproject.io" },
          { "title": "Github", "icon": "github", "url": "https://github.com/scala-network/scala" }
        ],
        "minerLinks": [
          { "name": "XLArig", "arch": "CPU", "url": "https://github.com/scala-network/XLArig/releases", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.scalaproject.io/block?block_info",
          "tx": "https://explorer.scalaproject.io/tx?tx_info"
        },
        "url": "https://xla.letshash.it",
        "img": "xla.png",
        "name": "SCALA",
        "new": false,
        "active": false,
        "algo": "Pantera",
        "coinUnits": 100,
        "decimalPlaces": 2,
        "unitPlaces": 2,
        "addressPrefix": 155,
        "coingeckoID": "scala"
      },
      "XMR": {
        "type": "cn",
        "endpoints": {
          "stats": "https://letshash.it:15038/stats",
          "blocks": "https://letshash.it:15038/stats",
          "top10": "https://letshash.it:15038/get_top10miners",
          "payments": "https://letshash.it:15038/get_payments?time={time}",
          "wallet": "https://letshash.it:15038/stats_address?address={address}",
          "settings": "https://letshash.it:15038/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 XMR",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 1551, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1552, "difficulty": 25000, "desc": "High end hardware", "donation": 0 },
          { "port": 1553, "difficulty": 300000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Monero website", "icon": "website", "url": "https://monero.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://xmrchain.net/block/",
          "tx": "https://xmrchain.net/tx/"
        },
        "url": "https://xmr.letshash.it",
        "img": "xmr.png",
        "name": "MONERO",
        "new": false,
        "active": false,
        "algo": "RandomX",
        "coinUnits": 1000000000000,
        "decimalPlaces": 4,
        "unitPlaces": 4,
        "addressPrefix": 18,
        "coingeckoID": "monero"
      },
      "WOW": {
        "type": "cn",
        "endpoints": {
          "stats": "https://wow.letshash.it/api/stats",
          "blocks": "https://wow.etshash.online/api/stats",
          "top10": "https://wow.letshash.it/api/get_top10miners",
          "payments": "https://wow.letshash.it/api/get_payments?time={time}",
          "wallet": "https://wow.letshash.it/api/stats_address?address={address}",
          "settings": "https://wow.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "5 WOW",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 4060, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 4061, "difficulty": 25000, "desc": "High end hardware", "donation": 0 },
          { "port": 4062, "difficulty": 300000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Wownero website", "icon": "website", "url": "https://wownero.org" },
          { "title": "Github", "icon": "github", "url": "https://git.wownero.com/wowario/wownero/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explore.wownero.com/block/",
          "tx": "https://explore.wownero.com/tx/"
        },
        "url": "https://wow.letshash.it",
        "img": "wow.png",
        "name": "WOWNERO",
        "new": false,
        "active": false,
        "algo": "RandomX",
        "coinUnits": 100000000000,
        "decimalPlaces": 4,
        "unitPlaces": 4,
        "addressPrefix": 4146,
        "coingeckoID": "wownero"
      },
      "SFX": {
        "type": "cn",
        "endpoints": {
          "stats": "https://sfx.letshash.it/api/stats",
          "blocks": "https://sfx.letshash.it/api/stats",
          "top10": "https://sfx.letshash.it/api/get_top10miners",
          "payments": "https://sfx.letshash.it/api/get_payments?time={time}",
          "wallet": "https://sfx.letshash.it/api/stats_address?address={address}",
          "settings": "https://sfx.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "2 SFX",
          "payInterval": "10 minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 5219, "difficulty": 10000, "desc": "Mid end hardware", "donation": 0 },
          { "port": 5220, "difficulty": 30000, "desc": "High end hardware", "donation": 0 }
        ],
        "links": [
          { "title": "Safex website", "icon": "website", "url": "http://safex.io/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/safex/safexcore" }
        ],
        "minerLinks": [
          { "name": "Xmrig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explore.safex.io/block/",
          "tx": "https://explore.safex.io/tx/"
        },
        "url": "https://sfx.letshash.it",
        "img": "sfx.png",
        "name": "SafexCash",
        "new": false,
        "active": false,
        "algo": "RandomSFX",
        "coinUnits": 10000000000,
        "decimalPlaces": 10,
        "unitPlaces": 10,
        "addressPrefix": 0x10003798
      },
      "UPX": {
        "type": "cn",
        "endpoints": {
          "stats": "https://upx.letshash.it/api/stats",
          "blocks": "https://upx.letshash.it/api/stats",
          "top10": "https://upx.letshash.it/api/get_top10miners",
          "payments": "https://upx.letshash.it/api/get_payments?time={time}",
          "wallet": "https://upx.letshash.it/api/stats_address?address={address}",
          "settings": "https://upx.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "25 UPX",
          "payInterval": "5 minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 1809, "difficulty": 10000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1810, "difficulty": 100000, "desc": "High end hardware", "donation": 0 },
          { "port": 1811, "difficulty": 2000000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Uplexa website", "icon": "website", "url": "https://uplexa.com" },
          { "title": "Github", "icon": "github", "url": "https://github.com/uPlexa/uplexa" }
        ],
        "minerLinks": [
          { "name": "UplexaRig", "arch": "CPU", "url": "https://github.com/uPlexa/xmrig-upx/releases/tag/v0.2.0", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.uplexa.com/block/",
          "tx": "https://explorer.uplexa.com/tx/"
        },
        "url": "https://upx.letshash.it",
        "img": "upx.png",
        "name": "UPLEXA",
        "new": false,
        "active": false,
        "algo": "Cryptonight-UPX/2",
        "coinUnits": 100,
        "decimalPlaces": 2,
        "unitPlaces": 2,
        "addressPrefix": 1449763,
        "coingeckoID": "uplexa"
      },
      "XOL": {
        "type": "cn",
        "endpoints": {
          "stats": "https://xol.letshash.it/api/stats",
          "blocks": "https://xol.letshash.it/api/stats",
          "top10": "https://xol.letshash.it/api/get_top10miners",
          "payments": "https://xol.letshash.it/api/get_payments?time={time}",
          "wallet": "https://xol.letshash.it/api/stats_address?address={address}",
          "settings": "https://xol.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "10 XOL",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 1565, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1566, "difficulty": 25000, "desc": "High end hardware", "donation": 0 },
          { "port": 1567, "difficulty": 300000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Xolentum website", "icon": "website", "url": "https://xolentum.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.xolentum.org/?hash=",
          "tx": "https://explorer.xolentum.org/?hash="
        },
        "url": "https://xol.letshash.it",
        "img": "xol.png",
        "name": "XOLENTUM",
        "new": false,
        "active": false,
        "algo": "RandomX",
        "coinUnits": 1000000000000,
        "decimalPlaces": 12,
        "unitPlaces": 12,
        "addressPrefix": 185
      },
      "XUNI": {
        "type": "cn",
        "endpoints": {
          "stats": "https://xuni.letshash.it/api/stats",
          "blocks": "https://xuni.letshash.it/api/stats",
          "top10": "https://xuni.letshash.it/api/get_top10miners",
          "payments": "https://xuni.letshash.it/api/get_payments?time={time}",
          "wallet": "https://xuni.letshash.it/api/stats_address?address={address}",
          "settings": "https://xuni.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 XUNI",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 1568, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1569, "difficulty": 20000, "desc": "High end hardware", "donation": 0 },
          { "port": 1570, "difficulty": 100000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Ultranote Infinity website", "icon": "website", "url": "https://ultranote.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://stats.ultranote.org/index.html?hash=",
          "tx": "https://stats.ultranote.org/index.html?hash="
        },
        "url": "https://xuni.letshash.it",
        "img": "xuni.png",
        "name": "ULTRANOTE INFINITY",
        "new": false,
        "active": false,
        "algo": "Cryptonight Conceal",
        "coinUnits": 1000000,
        "decimalPlaces": 6,
        "unitPlaces": 6,
        "addressPrefix": 3008056
      },
      "ZUM": {
        "type": "cn",
        "endpoints": {
          "stats": "https://zum.letshash.it/api/stats",
          "blocks": "https://zum.letshash.it/api/stats",
          "payments": "https://zum.letshash.it/api/get_payments?time={time}",
          "wallet": "https://zum.letshash.it/api/stats_address?address={address}",
          "settings": "https://zum.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.1 ZUM",
          "payInterval": "2minutes"
        },
        "ports": [
          { "port": 1704, "difficulty": 2500, "desc": "Low end hardware", "donation": 0 },
          { "port": 1705, "difficulty": 20000, "desc": "High end hardware", "donation": 0 },
          { "port": 1706, "difficulty": 100000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Zumcoin website", "icon": "website", "url": "https://zumcoin.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.zumcoin.org/?hash=",
          "tx": "http://explorer.zumcoin.org/?hash="
        },
        "url": "https://zum.letshash.it",
        "img": "zum.png",
        "name": "ZUMCOIN",
        "new": false,
        "active": false,
        "algo": "Cryptonight-Pico/Trtl",
        "coinUnits": 1000000000,
        "decimalPlaces": 9,
        "unitPlaces": 9,
        "addressPrefix": 4153412,
        "coingeckoID": "zumcoin"
      },
      "TLO": {
        "type": "cn",
        "endpoints": {
          "stats": "https://tlo.letshash.it/api/stats",
          "blocks": "https://tlo.letshash.it/api/stats",
          "top10": "https://tlo.letshash.it/api/get_top10miners",
          "payments": "https://tlo.letshash.it/api/get_payments?time={time}",
          "wallet": "https://tlo.letshash.it/api/stats_address?address={address}",
          "settings": "https://tlo.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 TLO",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 1776, "difficulty": 5000, "desc": "Low end hardware", "donation": 0 },
          { "port": 1777, "difficulty": 50000, "desc": "High end hardware", "donation": 0 },
          { "port": 1778, "difficulty": 500000, "desc": "Rig rentals / Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Talleo website", "icon": "website", "url": "https://www.talleo.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/TalleoProject/Talleo" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.talleo.org/?hash=",
          "tx": "https://explorer.talleo.org/?hash="
        },
        "url": "https://tlo.letshash.it",
        "img": "tlo.png",
        "name": "TALLEO",
        "new": false,
        "active": false,
        "algo": "Cryptonight-Ultra",
        "coinUnits": 100,
        "decimalPlaces": 2,
        "unitPlaces": 2,
        "addressPrefix": 12316,
        "coingeckoID": "talleo"
      },
      "INF": {
        "type": "cn",
        "endpoints": {
          "stats": "https://inf.letshash.it/api/stats",
          "blocks": "https://inf.letshash.it/api/stats",
          "top10": "https://inf.letshash.it/api/get_top10miners",
          "payments": "https://inf.letshash.it/api/get_payments?time={time}",
          "wallet": "https://inf.letshash.it/api/stats_address?address={address}",
          "settings": "https://inf.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "50 INF",
          "payInterval": "5minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 1770, "difficulty": 5000, "desc": "Low Hardware", "donation": 0 },
          { "port": 1771, "difficulty": 200000, "desc": "BaikalN+, RR2XX", "donation": 0 },
          { "port": 1772, "difficulty": 1500000, "desc": "A8 >150K & < 200K", "donation": 0 },
          { "port": 1773, "difficulty": 6000000, "desc": "X3 Default", "donation": 0 },
          { "port": 1774, "difficulty": 9000000, "desc": "X3 OC", "donation": 0 },
          { "port": 1775, "difficulty": 12000000, "desc": "Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Infinium website", "icon": "website", "url": "https://infinium.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Infinium-dev/Infinium/" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.infinium.space/block/#",
          "tx": "https://explorer.infinium.space/tx/#"
        },
        "url": "https://inf.letshash.it",
        "img": "inf.png",
        "name": "INFINIUM",
        "new": false,
        "active": false,
        "algo": "Cryptonight ASIC",
        "coinUnits": 1000000000000,
        "decimalPlaces": 12,
        "unitPlaces": 12,
        "addressPrefix": 1288825
      },
      "SCTS": {
        "type": "cn",
        "endpoints": {
          "stats": "https://scts.letshash.it/api/stats",
          "blocks": "https://scts.letshash.it/api/stats",
          "top10": "https://scts.letshash.it/api/get_top10miners",
          "payments": "https://scts.letshash.it/api/get_payments?time={time}",
          "wallet": "https://scts.letshash.it/api/stats_address?address={address}",
          "settings": "https://scts.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "100000 INF",
          "payInterval": "10 minutes"
        },
        "ports": [
          { "port": 1818, "difficulty": 5000, "desc": "Low Hardware", "donation": 0 },
          { "port": 1819, "difficulty": 200000, "desc": "BaikalN+, RR2XX", "donation": 0 },
          { "port": 1820, "difficulty": 1500000, "desc": "A8 >150K & < 200K", "donation": 0 },
          { "port": 1821, "difficulty": 6000000, "desc": "X3 Default", "donation": 0 },
          { "port": 1822, "difficulty": 9000000, "desc": "X3 OC", "donation": 0 },
          { "port": 1823, "difficulty": 12000000, "desc": "Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Societatis website", "icon": "website", "url": "https://societatis.io" },
          { "title": "Github", "icon": "github", "url": "https://github.com/societatis" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.societatis.io/?hash=#",
          "tx": "https://explorer.societatis.io/?hash=#"
        },
        "url": "https://scts.letshash.it",
        "img": "scts.png",
        "name": "SOCIETATIS",
        "new": false,
        "active": false,
        "algo": "Cryptonight ASIC",
        "coinUnits": 100000000,
        "decimalPlaces": 8,
        "unitPlaces": 8,
        "addressPrefix": 0
      },
      "AMX": {
        "type": "cn",
        "endpoints": {
          "stats": "https://amx.letshash.it/api/stats",
          "blocks": "https://amx.letshash.it/api/stats",
          "top10": "https://amx.letshash.it/api/get_top10miners",
          "payments": "https://amx.letshash.it/api/get_payments?time={time}",
          "wallet": "https://amx.letshash.it/api/stats_address?address={address}",
          "settings": "https://amx.letshash.it/api/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.1 AMX",
          "payInterval": "5 minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 7770, "difficulty": 1000, "desc": "Low Hardware", "donation": 0 },
          { "port": 7771, "difficulty": 15000, "desc": "BaikalN+, RR2XX", "donation": 0 },
          { "port": 7772, "difficulty": 25000, "desc": "A8 >150K & < 200K", "donation": 0 }
        ],
        "links": [
          { "title": "Armor website", "icon": "website", "url": "https://armornetwork.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/noritte/armor" }
        ],
        "minerLinks": [
          { "name": "XMRig", "arch": "CPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful CPU Mining App" },
          { "name": "XMRigGpu", "arch": "GPU", "url": "https://github.com/xmrig/xmrig/releases/latest", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.asdas.space/block/#",
          "tx": "https://explorer.asdads.space/tx/#"
        },
        "url": "https://amx.letshash.it",
        "img": "amx.png",
        "name": "ARMOR",
        "new": false,
        "active": false,
        "algo": "Cryptonight ZLS",
        "coinUnits": 100000000,
        "decimalPlaces": 8,
        "unitPlaces": 8,
        "addressPrefix": 0
      },
      "HAC": {
        "type": "hc",
        "endpoints": {
          "stats": "https://hacash.letshash.it/api/addresses",
          "miners": "https://hacash.letshash.it/api/addresses",
          "blocks": "https://haex.letshash.it/api/block/list?last={block}&limit=100",
          "lastblocks": "https://haex.letshash.it/api/block/last",
          "hashrate": "https://haex.letshash.it/api/difficulty/chartsv3",
          "top10": "https://hacash.letshash.it/api/addresses",
          "payments": "https://haex.letshash.it/api/transfer/logs?page=1&limit=200",
          "wallet": "https://hacash.letshash.it/api/addresses?address={address}",
          "settings": false
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.0001 HAC",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 4005, "difficulty": 1, "desc": "CPU", "donation": 0 }
        ],
        "links": [
          { "title": "Hacash website", "icon": "website", "url": "https://hacash.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/hacash" }
        ],
        "minerLinks": [
          { "name": "Hacash Pool Worker Windows", "arch": "CPU", "url": "http://download.hacash.org:8080/miner_pool_worker_hacash_windows64.zip", "title": "x16rs CPU Mining App" },
          { "name": "Hacash Pool Worker Linux", "arch": "CPU", "url": "http://download.hacash.org:8080/miner_pool_worker_hacash_ubuntu64.zip", "title": "x16rs CPU Mining App" },
        ],
        "explorerLinks": {
          "block": "https://haex.letshash.it/block/",
          "tx": "https://haex.letshash.it/address/"
        },
        "url": "https://hacash.letshash.it",
        "img": "hacash.png",
        "name": "HACASH",
        "new": true,
        "active": false,
        "algo": "x16rs",
        "coinUnits": 100000000,
        "decimalPlaces": 8,
        "unitPlaces": 8,
        "addressPrefix": 0
      },
      "ETH": {
        "type": "et",
        "endpoints": {
          "stats": "https://eth-api.letshash.it/api/stats",
          "blocks": "https://eth-api.letshash.it/api/blocks",
          "payments": "https://eth-api.letshash.it/api/payments",
          "wallet": "https://eth-api.letshash.it/api/accounts/{address}"
        },
        "pool": {
          "fee": "0.5%",
          "minPayout": "0.0001 ETH",
          "payInterval": "30 minutes",
          "rewardType": "PPLNS"
        },
        "ports": [
          { "port": 1444, "difficulty": 2000000000, "desc": "Single GPU", "donation": 0 },
          { "port": 1445, "difficulty": 4000000000, "desc": "More GPUS", "donation": 0 },
          { "port": 1446, "difficulty": 9000000000, "desc": "MiningRigs", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Ethereum", "icon": "website", "url": "https://www.ethereum.org/" },
          { "title": "Github", "icon": "website", "url": "https://github.com/ethereum/go-ethereum" }
        ],
        "minerLinks": [
          { "name": "Ethminer", "arch": "GPU", "url": "https://github.com/ethereum-mining/ethminer/releases/tag/v0.18.0", "title": "Ligthweight but powerful eth Mining App" }
        ],
        "explorerLinks": {
          "block": "https://etherscan.io/block/#",
          "tx": "https://etherscan.io/tx/#",
          "address": "https://etherscan.io/address/#"
        },
        "url": "https://eth.letshash.it",
        "img": "eth.png",
        "name": "ETHEREUM",
        "new": false,
        "active": false,
        "algo": "ethash",
        "decimalPlaces": 9,
        "unitPlaces": 9,
        "addressPrefix": 0,
        "coingeckoID": "ETHEREUM",
        "coinUnits": 111144324
      },
      "ETC": {
        "type": "et",
        "endpoints": {
          "stats": "https://etc-api.letshash.it/api/stats",
          "blocks": "https://etc-api.letshash.it/api/blocks",
          "payments": "https://etc-api.letshash.it/api/payments",
          "wallet": "https://etc-api.letshash.it/api/accounts/{address}"
        },
        "pool": {
          "fee": "0.5%",
          "minPayout": "0.0001 ETC",
          "payInterval": "30 minutes",
          "rewardType": "PPLNS"
        },
        "ports": [
          { "port": 1340, "difficulty": 2000000000, "desc": "Single GPU", "donation": 0 },
          { "port": 1341, "difficulty": 4000000000, "desc": "More GPUS", "donation": 0 },
          { "port": 1342, "difficulty": 9000000000, "desc": "MiningRigs", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "EthereumClassic", "icon": "website", "url": "https://www.ethereumclassic.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/ethereumclassic" }
        ],
        "minerLinks": [
          { "name": "Ethminer", "arch": "GPU", "url": "https://github.com/ethereum-mining/ethminer/releases/tag/v0.18.0", "title": "Ligthweight but powerful eth Mining App" }
        ],
        "explorerLinks": {
          "block": "https://etcblockexplorer.com/block/#",
          "tx": "https://etcblockexplorer.com/tx/#",
          "address": "https://etcblockexplorer.com/address/#"
        },
        "url": "https://etc.letshash.it",
        "img": "etc.png",
        "name": "ETHEREUM CLASSIC",
        "new": false,
        "active": false,
        "algo": "etchash",
        "decimalPlaces": 9,
        "unitPlaces": 9,
        "addressPrefix": 0, //1515348,
        "coingeckoID": "ETHEREUM-CLASSIC"
      },
      "ETHO": {
        "type": "et",
        "endpoints": {
          "stats": "https://etho-api.letshash.it/api/stats",
          "blocks": "https://etho-api.letshash.it/api/blocks",
          "payments": "https://etho-api.letshash.it/api/payments",
          "wallet": "https://etho-api.letshash.it/api/accounts/{address}"
        },
        "pool": {
          "fee": "0.5%",
          "minPayout": "0.00001 ETHO",
          "payInterval": "30 minutes",
          "rewardType": "PPLNS",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 1144, "difficulty": 2000000000, "desc": "Single GPU", "donation": 0 },
          { "port": 1145, "difficulty": 4000000000, "desc": "More GPUS", "donation": 0 },
          { "port": 1146, "difficulty": 9000000000, "desc": "MiningRigs", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Ether1", "icon": "website", "url": "https://www.ether1.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Ether1Project/Ether1" }
        ],
        "minerLinks": [
          { "name": "Ethminer", "arch": "GPU", "url": "https://github.com/ethereum-mining/ethminer/releases/tag/v0.18.0", "title": "Ligthweight but powerful eth Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.ether1.org/block/",
          "tx": "https://explorer.ether1.org/tx/",
          "address": "https://explorer.ether1.org/addr/"
        },
        "url": "https://etho.letshash.it",
        "img": "etho.png",
        "name": "Etho Protocol",
        "new": false,
        "active": false,
        "algo": "ethash",
        "decimalPlaces": 9,
        "unitPlaces": 9,
        "addressPrefix": 0,
        "coingeckoID": "ETHER-1",
        "coinUnits": 1000000000
      },
      "MINTME": {
        "type": "et",
        "endpoints": {
          "stats": "https://mintme-api.letshash.it/api/stats",
          "blocks": "https://mintme-api.letshash.it/api/blocks",
          "payments": "https://mintme-api.letshash.it/api/payments",
          "wallet": "https://mintme-api.letshash.it/api/accounts/{address}"
        },
        "pool": {
          "fee": "0.5%",
          "minPayout": "0.00001 MINTME",
          "payInterval": "30 minutes",
          "rewardType": "PROP",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 3333, "difficulty": 500, "desc": "CPU", "donation": 0 }
        ],
        "links": [
          { "title": "Mintme website", "icon": "website", "url": "https://www.mintme.com/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/mintme-com/" }
        ],
        "minerLinks": [
          { "name": "Mintme miner", "arch": "CPU", "url": "https://github.com/mintme-com/miner/releases/tag/v2.8.0", "title": "CPU MINER" }
        ],
        "explorerLinks": {
          "block": "https://www.mintme.com/explorer/block/",
          "tx": "https://www.mintme.com/explorer/tx/",
          "address": "https://www.mintme.com/explorer/addr/"
        },
        "url": "https://mintme.letshash.it",
        "img": "mintme.png",
        "name": "Mintme",
        "new": false,
        "active": false,
        "algo": "Lyra2Mintme",
        "decimalPlaces": 9,
        "unitPlaces": 9,
        "addressPrefix": 0,
        "coingeckoID": "mintme-com-coin",
        "coinUnits": 1000000000
      },
      "ZANO": {
        "type": "et",
        "endpoints": {
          "stats": "https://zano-api.letshash.it/api/stats",
          "blocks": "https://zano-api.letshash.it/api/blocks",
          "payments": "https://zano-api.letshash.it/api/payments",
          "wallet": "https://zano-api.letshash.it/api/accounts/{address}"
        },
        "pool": {
          "fee": "0.5%",
          "minPayout": "0.1 ZANO",
          "payInterval": "5 minutes",
          "rewardType": "PPLNS",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 6555, "difficulty": 500000000, "desc": "Single GPU", "donation": 0 },
          { "port": 6556, "difficulty": 1000000000, "desc": "More GPUs", "donation": 0 },
          { "port": 6557, "difficulty": 5000000000, "desc": "GPU Rigs", "donation": 0 }
        ],
        "links": [
          { "title": "Zano", "icon": "website", "url": "https://zano.org" },
          { "title": "Github", "icon": "github", "url": "https://github.com/hyle-team/zano" }
        ],
        "minerLinks": [
          { "name": "T-rex miner", "arch": "GPU", "url": "https://trex-miner.com/download/t-rex-0.20.4-win.zip", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "GPU Miner" },
          { "name": "TT-miner", "arch": "GPU", "url": "https://tradeproject.de/download/Miner/TT-Miner.zip", "title": "GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://explorer.zano.org/block/",
          "tx": "https://explorer.zano.org/tx/",
          "address": "https://explorer.zano.org/addr/"
        },
        "url": "https://zano.letshash.it",
        "img": "zano.png",
        "name": "Zano",
        "new": false,
        "active": false,
        "algo": "ProgPowZ",
        "decimalPlaces": 9,
        "unitPlaces": 9,
        "addressPrefix": 0,
        "coingeckoID": "197",
        "coinUnits": 1000000
      },
      "CLO": {
        "type": "et",
        "endpoints": {
          "stats": "https://clo-api.letshash.it/api/stats",
          "blocks": "https://clo-api.letshash.it/api/blocks",
          "payments": "https://clo-api.letshash.it/api/payments",
          "wallet": "https://clo-api.letshash.it/api/accounts/{address}"
        },
        "pool": {
          "fee": "0.5%",
          "minPayout": "5 CLO",
          "payInterval": "30 minutes",
          "rewardType": "PPLNS"
        },
        "ports": [
          { "port": 2244, "difficulty": 2000000000, "desc": "Single GPU", "donation": 0 },
          { "port": 2245, "difficulty": 4000000000, "desc": "More GPUS", "donation": 0 },
          { "port": 2246, "difficulty": 9000000000, "desc": "MiningRigs", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Callisto Network", "icon": "website", "url": "https://www.callisto.network/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/EthereumCommonwealth" }
        ],
        "minerLinks": [
          { "name": "Ethminer", "arch": "GPU", "url": "https://github.com/ethereum-mining/ethminer/releases/tag/v0.18.0", "title": "Ligthweight but powerful eth Mining App" }
        ],
        "explorerLinks": {
          "block": "https://callistoexplorer.com/block/",
          "tx": "https://callistoexplorer.com/tx/",
          "address": "https://callistoexplorer.com/address/"
        },
        "url": "https://clo.letshash.it",
        "img": "clo.png",
        "name": "CALLISTO NETWORK",
        "new": false,
        "active": false,
        "algo": "ethash",
        "decimalPlaces": 9,
        "unitPlaces": 9,
        "addressPrefix": 0,
        "coingeckoID": "CALLISTO",
        "coinUnits": 111144324
      },
      "PASC": {
        "type": "ps",
        "endpoints": {
          "stats": "https://pasc-api.letshash.it/stats",
          "blocks": "https://pasc-api.letshash.it/stats",
          "top10": "https://pasc-api.letshash.it/get_top_miners",
          "payments": false,
          "wallet": "https://pasc-api.letshash.it/stats_address?address={address}",
          "settings": false //"https://pasc-api.letshash.it/{method}_miner_payout_level?address={address}&ip={ip}&level={amount}"
        },
        "pool": {
          "fee": "0.6 %",
          "minPayout": "0.5 PASC",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 2250, "difficulty": 0.0003500, "desc": "Low end hardware", "donation": 0 },
          { "port": 2251, "difficulty": 0.0007000, "desc": "High end hardware", "donation": 0 },
          { "port": 2252, "difficulty": 0.0014000, "desc": "CPU Farm", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Pascal website", "icon": "website", "url": "https://pascalcoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/PascalCoin/PascalCoin" }
        ],
        "minerLinks": [
          { "name": "RHminer", "arch": "CPU", "url": "https://github.com/polyminer1/rhminer/releases/tag/2.3", "title": "Ligthweight but powerful CPU Mining App" }
        ],
        "explorerLinks": {
          "block": "http://explorer.pascalcoin.org/block.php?block=",
          "tx": "http://explorer.pascalcoin.org/findoperation.php?ophash="
        },
        "url": "https://pasc.letshash.it",
        "img": "pasc.png",
        "name": "PASCAL",
        "new": true,
        "active": false,
        "coinUnits": 10000,
        "decimalPlaces": 5,
        "algo": "RandomHashV2",
        "unitPlaces": 5,
        "addressPrefix": 0
      },
      "SPACE": {
        "type": "kn",
        "apikey": "spacecoin",
        "endpoints": {
          "stats": "https://equihash.letshash.it/api/stats",
          "blocks": "https://equihash.letshash.it/api/rawblocks",
          "payments": "https://equihash.letshash.it/api/payments",
          "wallet": "https://equihash.letshash.it/api/worker_stats?{address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 SPACE",
          "payInterval": "5minutes"
        },
        "ports": [
          { "port": 7999, "difficulty": 0.05, "desc": "Low end hardware", "donation": 0 },
          { "port": 7998, "difficulty": 20, "desc": "Mid end hardware", "donation": 0 },
          { "port": 7997, "difficulty": 80, "desc": "High end hardware", "donation": 0 },
          { "port": 7996, "difficulty": 512, "desc": "MiningRigs/Nicehash", "donation": 0, "ssl": false }
        ],
        "links": [
          { "title": "Spacecoin", "icon": "website", "url": "https://www.spaceworks.co/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/SpaceWorksCo/spacecoin" }
        ],
        "minerLinks": [
          { "name": "Bminer", "arch": "GPU", "url": "https://www.bminer.me/releases/", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.spaceworks.co/block/",
          "tx": "https://explorer.spaceworks.co/tx/"
        },
        "url": "https://equihash.letshash.it",
        "img": "space.png",
        "name": "Spacecoin",
        "new": false,
        "active": false,
        "algo": "equihash 200,9",
        "unitPlaces": 2,
        "addressPrefix": 0,
        "byteUnitType": "Sol"
      },
      "VRSC": {
        "type": "np",
        "apikey": "verus",
        "endpoints": {
          "stats": "https://veruhash.letshash.it/api/stats",
          "blocks": "https://veruhash.letshash.it/api/blocks",
          "payments": "https://veruhash.letshash.it/api/payments",
          "wallet": "https://veruhash.letshash.it/api/worker_stats?{address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "1 VRSC",
          "payInterval": "2minutes"
        },
        "ports": [
          { "port": 9999, "difficulty": 2000, "desc": "Low end hardware", "donation": 0 },
          { "port": 9998, "difficulty": 10000, "desc": "Mid end hardware", "donation": 0 },
          { "port": 9997, "difficulty": 131072, "desc": "High end hardware", "donation": 0 }
        ],
        "links": [
          { "title": "VerusCoin", "url": "https://www.veruscoin.io/" },
          { "title": "Github", "url": "https://github.com/xmrig/xmrig/" }
        ],
        "minerLinks": [
          { "name": "Bminer", "arch": "GPU", "url": "https://www.bminer.me/releases/", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.verus.io/block/",
          "tx": "https://explorer.verus.io/tx/"
        },
        "url": "http://veruhash.letshash.it",
        "img": "vrsc.png",
        "name": "VerusCoin",
        "new": false,
        "active": false,
        "algo": "veruhash",
        "unitPlaces": 2,
        "addressPrefix": 0,
        "coingeckoID": "verus-coin"
      },
      "RVN": {
        "type": "kw",
        "apikey": "ravencoin",
        "endpoints": {
          "stats": "https://kawpow.letshash.it/api/stats",
          "blocks": "https://kawpow.letshash.it/api/stats",
          "payments": "https://kawpow.letshash.it/api/payments",
          "wallet": "https://kawpow.letshash.it/api/worker_stats?{address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.25 RVN",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 4040, "difficulty": 0.001, "desc": "4.3M", "donation": 0 },
          { "port": 4041, "difficulty": 0.5, "desc": "2.16G", "donation": 0 },
          { "port": 4042, "difficulty": 3, "desc": "12.94G", "donation": 0 }
        ],
        "links": [
          { "title": "Ravencoin website", "icon": "website", "url": "https://ravencoin.org/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/RavenProject/Ravencoin/" }
        ],
        "minerLinks": [
          { "name": "Bminer", "arch": "GPU", "url": "https://www.bminer.me/releases/", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "T-rex miner", "arch": "GPU", "url": "https://github.com/trexminer/T-Rex/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://ravencoin.network/block/",
          "tx": "https://ravencoin.network/tx/"
        },
        "url": "http://kawpow.letshash.it",
        "img": "rvn.png",
        "name": "Ravencoin",
        "new": false,
        "active": false,
        "algo": "kawpow",
        "unitPlaces": 2,
        "addressPrefix": 0,
        "coingeckoID": "ravencoin"
      },
      "SATO": {
        "type": "kw",
        "apikey": "satocoin",
        "endpoints": {
          "stats": "https://kawpow.letshash.it/api/stats",
          "blocks": "https://kawpow.letshash.it/api/stats",
          "payments": "https://kawpow.letshash.it/api/payments",
          "wallet": "https://kawpow.letshash.it/api/worker_stats?{address}"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.25 SATO",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 4440, "difficulty": 0.001, "desc": "4.3M", "donation": 0 },
          { "port": 4441, "difficulty": 0.5, "desc": "2.16G", "donation": 0 },
          { "port": 4442, "difficulty": 3, "desc": "12.94G", "donation": 0 }
        ],
        "links": [
          { "title": "Satocoin website", "icon": "website", "url": "https://satoex.com/sato" },
          { "title": "Github", "icon": "github", "url": "https://github.com/Satoex/sato" }
        ],
        "minerLinks": [
          { "name": "Bminer", "arch": "GPU", "url": "https://www.bminer.me/releases/", "title": "Ligthweight but powerful GPU Mining App" },
          { "name": "T-rex miner", "arch": "GPU", "url": "https://github.com/trexminer/T-Rex/releases", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.satoex.com/block/",
          "tx": "https://explorer.satoex.com/tx/"
        },
        "url": "http://kawpow.letshash.it",
        "img": "sato.png",
        "name": "Satocoin",
        "new": false,
        "active": false,
        "algo": "kawpow",
        "unitPlaces": 2,
        "addressPrefix": 0
      },
      "EXCC": {
        "type": "ex",
        "apikey": "exchangecoin",
        "endpoints": {
          "stats": "https://excc-api.letshash.it/pools",
          "blocks": "https://excc-api.letshash.it/pools/exchangeCoin/blocks?pageSize=50",
          "payments": "https://excc-api.letshash.it/pools/exchangeCoin/payments?pageSize=500",
          "wallet": "https://excc-api.letshash.it/pools/exchangeCoin/miners/{address}",
          "top10": "https://excc-api.letshash.it/pools/exchangeCoin/miners"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.01 excc",
          "payInterval": "5 minutes"
        },
        "ports": [
          { "port": 3052, "difficulty": 1, "desc": "GPU", "donation": 0 }
        ],
        "links": [
          { "title": "ExchangeCoin website", "icon": "website", "url": "https://excc.co/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/EXCCoin/exccd" }
        ],
        "minerLinks": [
          { "name": "lolMiner", "arch": "GPU", "url": "https://github.com/Lolliedieb/lolMiner-releases/releases/", "title": "Ligthweight but powerful GPU Mining App" }
        ],
        "explorerLinks": {
          "block": "https://explorer.excc.co/block/",
          "tx": "https://explorer.excc.co/tx/"
        },
        "url": "http://excc.letshash.it",
        "img": "excc.png",
        "name": "ExchangeCoin",
        "new": false,
        "active": false,
        "algo": "Equihash 144, 5",
        "unitPlaces": 2,
        "addressPrefix": 0,
        "coingeckoID": "exchangecoin"
      },
      "YDA": {
        "type": "yd",
        "apikey": "yadacoin",
        "endpoints": {
          "stats": "https://yada.letshash.it/pool-info",
          "blocks": "https://yada.letshash.it/pool-info",
          "payments": "https://yada.letshash.it/pool-info",
          "wallet": "https://yada.letshash.it/hashrate-for-address?address={address}",
          "walletShares": "https://yada.letshash.it/shares-for-address?address={address}",
          "walletPayouts": "https://yada.letshash.it/payouts-for-address?address={address}",
          "top10": "https://yada.letshash.it/pool-info"
        },
        "pool": {
          "fee": "1 %",
          "minPayout": "0.01 yada",
          "payInterval": "5 minutes",
          "hasAsiaServer": false
        },
        "ports": [
          { "port": 6666, "difficulty": 1, "desc": "CPU", "donation": 0 }
        ],
        "links": [
          { "title": "YadaCoin website", "icon": "website", "url": "https://yadacoin.io/" },
          { "title": "Github", "icon": "github", "url": "https://github.com/pdxwebdev/yadacoin" }
        ],
        "minerLinks": [
          { "name": "XmrigCC", "arch": "CPU", "url": "https://github.com/Bendr0id/xmrigCC/releases", "title": "Highly optimized CN/RX miner with CC." }
        ],
        "explorerLinks": {
          "block": "https://yadacoin.io/explorer?term=",
          "tx": "https://yadacoin.io/explorer?term="
        },
        "url": "https://yadacoin.io",
        "img": "yada.png",
        "name": "YadaCoin",
        "new": false,
        "active": false,
        "algo": "randomx YDA",
        "unitPlaces": 2,
        "addressPrefix": 0,
        "coingeckoID": "yadacoin"
      },
      "SFC": {
        "type": "yt",
        "endpoints": {
          "stats": "https://web.letshash.it/api/currencies",
          "blocks": "https://web.letshash.it/site/block_results_json?id=48",
          "payments": "https://web.letshash.it/site/payments_results_json?id=48",
          "wallet": "https://web.letshash.it/api/walletEx?address={address}",
          "hashrateHistory": "https://web.letshash.it/site/graph_hashrate_results?algo=qubit"
        },
        "pool": {
          "fee": "0.5 %",
          "minPayout": "0.001 SFC",
          "payInterval": "2 hours",
          "hasAsiaServer": false,
          "hasUsServer": false
        },
        "ports": [
          { "port": 4733, "difficulty": 0.01, "desc": "GPU Starting Diff", "donation": 0 }
        ],
        "links": [
          { "title": "Safechain website", "icon": "website", "url": "https://github.com/safechaincrypto/blockchain" },
          { "title": "Github", "icon": "github", "url": "https://github.com/safechaincrypto/blockchain" }
        ],
        "minerLinks": [
          { "name": "WildRig-Multi", "arch": "GPU", "url": "https://github.com/andru-kun/wildrig-multi/", "title": "Qubit GPU Miner" },
          { "name": "CCminer", "arch": "GPU", "url": "https://github.com/tpruvot/ccminer/releases", "title": "Qubit NVIDIA GPU Miner" }
        ],
        "explorerLinks": {
          "block": "https://web.letshash.it/explorer/SFC?hash=",
          "tx": "https://web.letshash.it/explorer/SFC?txid="
        },
        "url": "https://web.letshash.it",
        "img": "sfc.png",
        "name": "Safechain",
        "new": false,
        "active": false,
        "algo": "qubit",
        "unitPlaces": 7,
        "addressPrefix": 0
      }
    }
  }
};

export default config;
