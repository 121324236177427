<template>
  <page title="">
    <div class="d-flex align-items-center mb-3">
      <DollarSign class="me-2" /> <!-- Lucide Icon -->
      <h1 class="page-title">Payments</h1>
    </div>
    <div class="col-sm-12 col-lg-12 mb-3">
      <card>
        <div class="table-responsive">
          <t-table class="table card-table">
            <table-head>
              <table-row>
                <table-cel :header="true">Time Sent</table-cel>
                <table-cel v-if="coinConfig.type == 'et'" :header="true">
                  Address
                </table-cel>
                <table-cel :header="true">Transaction Hash</table-cel>
                <table-cel :header="true">Amount</table-cel>
                <table-cel v-if="coinConfig.type != 'et'" :header="true">
                  Fee
                </table-cel>
                <table-cel v-if="coinConfig.type == 'cn'" :header="true">
                  Mixin
                </table-cel>
                <table-cel v-if="coinConfig.type != 'et'" :header="true">
                  Payees
                </table-cel>
              </table-row>
            </table-head>
            <table-body>
              <table-row :key="block.id" v-for="block in poolStats.payments">
                <table-cel>
                  {{ block.time }}
                </table-cel>
                <table-cel v-if="coinConfig.type == 'et'">
                  {{ block.address }}
                </table-cel>
                <table-cel>
                  <div v-if="coinConfig.explorerLinks">
                    <a
                      :href="coinConfig.explorerLinks.tx + block.hash"
                      target="_blank"
                    >
                      {{ block.hash }}
                    </a>
                  </div>
                  <div v-else>
                    {{ block.hash }}
                  </div>
                </table-cel>
                <table-cel>{{ block.amount }}</table-cel>
                <table-cel v-if="coinConfig.type != 'et'">
                  {{ block.fee }}
                </table-cel>
                <table-cel v-if="coinConfig.type == 'cn'">
                  {{ block.mixin }}
                </table-cel>
                <table-cel v-if="coinConfig.type != 'et'">
                  {{ block.recipients }}
                </table-cel>
              </table-row>
            </table-body>
          </t-table>
        </div>

        <Loader v-bind:loading="loading" />
      </card>
    </div>
  </page>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import PoolConfig from "@/config/PoolConfig"
import PoolApi from "@/services/api/Pool"
import Loader from '@/components/sections/Loader.vue'
import { DollarSign } from 'lucide-vue-next' // Import Lucide Icon

const route = useRoute()

// State
const poolStats = ref({})
const coinConfig = ref(PoolApi.coinConfig())
const loading = ref(false)
let intervalId = null // Store the interval ID for cleanup

// Methods
const fetchData = () => {
  loading.value = true
  PoolApi.callStats(
    (response) => {
      poolStats.value = response
      loading.value = false
    },
    () => {
      loading.value = false
    }
  )
}

// Watch route changes
watch(() => route, fetchData)

// Initial fetch and periodic refresh
onMounted(() => {
  fetchData()
  intervalId = setInterval(fetchData, 150000) // Fetch data every 2.5 minutes (150 000ms)
})

// Cleanup interval on component unmount
onBeforeUnmount(() => {
  clearInterval(intervalId)
})
</script>