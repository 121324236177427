import { useStorage } from '@/composables/useStorage';
import mitt from 'mitt';
import PoolConfig from "@/config/PoolConfig";
import GeneratorConfig from "@/config/GeneratorConfig";
import CnPool from '@/services/api/CnPool';
import CnGoPool from '@/services/api/CnGoPool';
import NpPool from '@/services/api/NpPool';
import KnPool from '@/services/api/KnPool';
import EtPool from '@/services/api/EtPool';
import YtPool from '@/services/api/YtPool';
import McPool from '@/services/api/McPool';
import PsPool from '@/services/api/PsPool';
import HcPool from '@/services/api/HcPool';
import ExPool from '@/services/api/ExPool';
import KwPool from '@/services/api/KwPool';
import YdPool from '@/services/api/YdPool';

const emitter = mitt();
const storage = useStorage();
let callStatsCached;

export default {
    getSelectedCoin() {
        return storage.get(PoolConfig.KEY_COIN, false);
    },

    coinConfig() {
        const coin = this.getSelectedCoin();
        return coin ? PoolConfig.getCoin(coin) : null;
    },

    generatorConfig() {
        const config = GeneratorConfig.getConfig()[this.getSelectedCoin()];
        return config || false;
    },

    defaultHost() {
        return GeneratorConfig.getHost();
    },

    defaultHostAsia() {
        return GeneratorConfig.getHostAsia();
    },

    defaultHostUs() {
        return GeneratorConfig.getHostUs();
    },

    async walletInfo(callback, finallyCallback, address) {
        const poolCoin = this.coinConfig();
        try {
            const response = await this.getWalletByType(poolCoin, address);
            callback(response);
        } catch (error) {
            console.error('Error in walletInfo:', error);
        } finally {
            finallyCallback?.();
        }
    },

    async walletSettingsWrite(callback, finallyCallback, address, ip, amount) {
        const poolCoin = this.coinConfig();
        try {
            const response = await this.setWalletSettingByType(poolCoin, address, ip, amount);
            callback(response);
        } catch (error) {
            console.error('Error in walletSettingsWrite:', error);
        } finally {
            finallyCallback?.();
        }
    },

    async callStats(callback, finallyCallback, address) {
        const poolCoin = this.coinConfig();
        
        // If no coin is selected, log error and call finally callback
        if (!poolCoin) {
            console.error('No coin selected');
            finallyCallback?.();
            return;
        }
    
        try {
            if (callStatsCached) {
                callback(callStatsCached);
            } else {
                const response = await this.getStatsByType(poolCoin, address);
                callback(response);
            }
        } catch (error) {
            console.error('Error in callStats:', error);
        } finally {
            finallyCallback?.();
        }
    },

    getWalletSettingByType(pool, address) {
        const type = pool.type;
        switch (type) {
            case 'cn':
                return CnPool.getWalletSetting(pool, address);
            default:
                return false;
        }
    },

    setWalletSettingByType(pool, address, ip, amount) {
        const type = pool.type;
        switch (type) {
            case 'cn':
                return CnPool.setWalletSetting(pool, address, ip, amount);
            default:
                return false;
        }
    },

    getWalletByType(pool, address) {
        const type = pool.type;
        const poolMap = {
            'cn': CnPool,
            'cn-go': CnGoPool,
            'et': EtPool,
            'np': NpPool,
            'kn': KnPool,
            'kw': KwPool,
            'yt': YtPool,
            'mc': McPool,
            'ps': PsPool,
            'hc': HcPool,
            'ex': ExPool,
            'yd': YdPool
        };

        const selectedPool = poolMap[type];
        if (selectedPool) {
            return selectedPool.getWalletInfo(pool, address);
        }
    },

    getStatsByType(pool) {
        const type = pool.type;
        const poolMap = {
            'cn': CnPool,
            'cn-go': CnGoPool,
            'et': EtPool,
            'np': NpPool,
            'kn': KnPool,
            'kw': KwPool,
            'yt': YtPool,
            'mc': McPool,
            'ps': PsPool,
            'hc': HcPool,
            'ex': ExPool,
            'yd': YdPool
        };

        const selectedPool = poolMap[type];
        if (selectedPool) {
            return selectedPool.getStats(pool);
        }
    },
    
    async getBlocks(coinSymbol) {
        try {
            const pool = PoolConfig.getCoin(coinSymbol);
            if (!pool) {
                console.error('Invalid coin symbol:', coinSymbol);
                return [];
            }
            
            const stats = await this.getStatsByType(pool);
            
            return stats.blocks || [];
        } catch (error) {
            console.error('Error fetching blocks:', error);
            return [];
        }
    }
};