import PoolApi from "@/services/api/Pool";
import YtPool from "@/services/api/YtPool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const storage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        const url = pool.endpoints.wallet.replace('{address}', address);
        try {
            const response = await axios.get(url);
            if (response.data.error === undefined) {
                return YtPool.parseWalletInfo(response);
            } else {
                return { error: 'Not found' };
            }
        } catch (error) {
            console.error('Error fetching wallet info:', error);
            return { error: 'Request failed' };
        }
    },

    async getStats(pool) {
        const url = pool.endpoints.stats;
        try {
            const response = await axios.get(url);
            let parsedStats = YtPool.parseStats(response);
            parsedStats = await YtPool.parseHistoryStats(pool, parsedStats);
            parsedStats = await YtPool.parsePayments(pool, parsedStats);
            return YtPool.parseBlocks(pool, parsedStats);
        } catch (error) {
            console.error('Error fetching stats:', error);
            return null;
        }
    },

    parseWalletInfo(response) {
        const stats = {};
        const workers = [];
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;

        stats.hashes = false;
        stats.lastShare = false;
        stats.balance = (() => {
            const unpaid = Math.abs(Number(responseObj.unpaid)); // Ensure it's a positive number
            const formattedUnpaid = unpaid < 1 
                ? unpaid.toFixed(8) // 8 decimal places for small values
                : unpaid.toFixed(2); // 2 decimal places for larger values
        
            // Remove unnecessary trailing zeroes after decimal
            return formattedUnpaid.replace(/\.?0+$/, "") + ' ' + PoolApi.getSelectedCoin();
        })();
        stats.paid = responseObj.paid24h + ' ' + PoolApi.getSelectedCoin();

        if (responseObj.miners && responseObj.miners.length > 0) {
            responseObj.miners.forEach(miner => {
                workers.push({
                    name: `${miner.ID} ${miner.version}`,
                    hashrate: Format.getReadableHashRateString(miner.accepted),
                    lastShare: '',
                    diff: miner.difficulty
                });
            });
        }

        stats.workers = workers;

        const pays = responseObj.pyments || responseObj.payouts;
        stats.payments = YtPool.parsePaymentsString({ data: pays }, response, coin);

        return stats;
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        let selectedCoin = PoolApi.getSelectedCoin().toUpperCase();
        let responseObj = response.data;

        if (selectedCoin === 'PBTC') {
            selectedCoin = 'pBTC';
        }

        if (responseObj[selectedCoin]) {
            responseObj = responseObj[selectedCoin];
            stats.miners = responseObj.workers;
            stats.soloMiners = 0;
            stats.workers = responseObj.workers - responseObj.workers_solo;
            stats.soloWorkers = responseObj.workers_solo;
            stats.poolHashRate = Format.getReadableHashRateString(responseObj.hashrate);
            stats.poolHashRateRaw = responseObj.hashrate;
            stats.poolSoloHashRate = 0;
            stats.poolFee = coin.pool.fee;
            stats.poolMinimumPayout = coin.pool.minPayout;
            stats.networkDifficulty = responseObj.difficulty;
            stats.networkHashrate = responseObj.network_hashrate;
            stats.blockHeight = responseObj.height;
            stats.blockLastFound = responseObj.lastblock;
            stats.hashRateHistory = [];
            stats.blocks = [];
            stats.payments = [];
            stats.config = responseObj.config;
            stats.reward = responseObj.reward;

            storage.set("config", responseObj);
        }

        return stats;
    },

    async parseHistoryStats(pool, response) {
        if (pool.endpoints.hashrateHistory) {
            const url = pool.endpoints.hashrateHistory;
            try {
                const hashrates = await axios.get(url);
                const hashrateHistory = hashrates.data[0].map(item => item[1]);
                response.hashRateHistory = hashrateHistory;
            } catch (error) {
                console.error('Error fetching hashrate history:', error);
            }
        }
        return response;
    },

    async parseBlocks(pool, response) {
        const url = pool.endpoints.blocks;
        try {
            const blocks = await axios.get(url);
            const coin = PoolApi.coinConfig();
            response.blocks = YtPool.parseBlocksString(blocks, response, coin);
        } catch (error) {
            console.error('Error fetching blocks:', error);
        }
        return response;
    },

    parseBlocksString(blocks, response, coin) {
        const responseObj = blocks.data;
        const parsedBlocks = [];

        responseObj.forEach(parts => {
            const pay = {
                time: Format.formatDate(parseInt(parts.rawtime)),
                hash: parts.hash,
                height: parts.height,
                amount: parts.amount || '',
                rawtime: parseInt(parts.rawtime),
                difficulty: parts.difficulty,
                shares: parts.share_diff,
                orphaned: '',
                reward: parts.amount ? `${parts.amount.toLocaleString('fullwide', { useGrouping: false })} ${PoolApi.getSelectedCoin()}` : 0,
                luck: '',
                status: parts.status.name,
                solo: parts.solo,
                effort: Math.round(parseInt(parts.share_diff) / parseInt(parts.difficulty) * 100),
                minerAddress: parts.finder
            };

            pay.amount = `${pay.amount} ${PoolApi.getSelectedCoin()}`;
            parsedBlocks.push(pay);
        });

        parsedBlocks.sort((a, b) => b.rawtime - a.rawtime);

        return parsedBlocks;
    },

    async parsePayments(pool, response) {
        const timestamp = Math.round(Date.now() / 1000);
        const url = pool.endpoints.payments.replace('{time}', timestamp);
        try {
            const payments = await axios.get(url);
            const coin = PoolApi.coinConfig();
            response.payments = YtPool.parsePaymentsString(payments, response, coin);
        } catch (error) {
            console.error('Error fetching payments:', error);
        }
        return response;
    },

    parsePaymentsString(payments, response, coin) {
        const responseObj = payments.data || [];
        const parsedPayments = [];

        responseObj.forEach((parts, index) => {
            const time = parts.rawtime || parts.time;
            const pay = {
                time: Format.formatDate(parseInt(time)),
                hash: parts.tx || parts.hash,
                amount: `${parts.amount.toLocaleString('fullwide', { useGrouping: false })} ${PoolApi.getSelectedCoin()}`,
                fee: `${(parts.fee ? Format.getReadableCoins(coin, parseFloat(parts.fee), null, true) : 0)} ${PoolApi.getSelectedCoin()}`,
                rawtime: parseInt(time),
                id: index
            };

            parsedPayments.push(pay);
        });

        parsedPayments.sort((a, b) => b.rawtime - a.rawtime);

        return parsedPayments;
    }
};
