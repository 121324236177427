<template>
  <div class="chart-container">
    <div v-if="loading" class="chart-loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="chart-header">
        <h3 class="chart-title">{{ title }}</h3>
        <div class="chart-controls">
          <button 
            v-for="period in timePeriods" 
            :key="period.value" 
            class="btn btn-sm" 
            :class="selectedPeriod === period.value ? 'btn-primary' : 'btn-outline-secondary'"
            @click="changePeriod(period.value)"
          >
            {{ period.label }}
          </button>
        </div>
      </div>
      <div class="chart-wrapper">
        <LineChart
          ref="chartRef"
          :chart-data="chartData"
          :chart-options="chartOptions"
          :height="300"
          :width="null"
          :css-classes="'chart-canvas'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import Format from '@/utils/format';

// Register Chart.js components
Chart.register(...registerables);

// Override Chart.js defaults to ensure proper formatting
Chart.defaults.font.family = "'Helvetica', 'Arial', sans-serif";
Chart.defaults.font.size = 12;
Chart.defaults.color = '#666';

// Custom formatter for Y-axis ticks
const formatYAxisTick = (value) => {
  // Use the Format utility to properly format the hashrate
  return Format.formatHashrate(value * 1000000); // Convert back to H/s for formatting
};

// Determine the appropriate unit for the current data range
const determineHashrateUnit = () => {
  if (!hashRateData.value || hashRateData.value.length === 0) {
    return { divisor: 1000000, unit: 'MH/s' }; // Default to MH/s
  }
  
  // Find the maximum hashrate value
  const maxHashrate = Math.max(...hashRateData.value.map(item => item[1]));
  
  // Determine the appropriate unit based on the maximum value
  if (maxHashrate >= 1e15) {
    return { divisor: 1e15, unit: 'PH/s' };
  } else if (maxHashrate >= 1e12) {
    return { divisor: 1e12, unit: 'TH/s' };
  } else if (maxHashrate >= 1e9) {
    return { divisor: 1e9, unit: 'GH/s' };
  } else if (maxHashrate >= 1e6) {
    return { divisor: 1e6, unit: 'MH/s' };
  } else if (maxHashrate >= 1e3) {
    return { divisor: 1e3, unit: 'KH/s' };
  } else {
    return { divisor: 1, unit: 'H/s' };
  }
};

// Props
const props = defineProps({
  title: {
    type: String,
    default: 'Pool Hashrate'
  },
  initialData: {
    type: Array,
    default: () => []
  },
  dataFetchFunction: {
    type: Function,
    required: true
  },
  refreshInterval: {
    type: Number,
    default: 90000 // 90 seconds to match API refresh rate
  },
  color: {
    type: String,
    default: '#206bc4'
  },
  secondaryColor: {
    type: String,
    default: 'rgba(32, 107, 196, 0.2)'
  },
  liveFetchFunction: {
    type: Function,
    default: null
  },
  forceUpdate: {
    type: Boolean,
    default: false
  }
});

// State
const loading = ref(true);
const chartData = ref(null);
const intervalId = ref(null);
const hashRateData = ref(props.initialData.length ? props.initialData : [[new Date().getTime(), 0]]);
const selectedPeriod = ref('1h');
const chartRef = ref(null);

// Time period options
const timePeriods = [
  { label: '1H', value: '1h' },
  { label: '6H', value: '6h' },
  { label: '24H', value: '24h' },
  { label: '7D', value: '7d' },
];

// Chart options
const chartOptions = computed(() => {
  // Calculate appropriate Y-axis min and max based on data
  let minValue = 0;
  let maxValue = 0;
  
  // Determine the appropriate hashrate unit
  const { divisor, unit } = determineHashrateUnit();
  
  if (hashRateData.value && hashRateData.value.length > 0) {
    const values = hashRateData.value.map(item => item[1] / divisor);
    minValue = Math.min(...values) * 0.9; // 10% below minimum
    maxValue = Math.max(...values) * 1.1; // 10% above maximum
  }
  
  return {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Hashrate: ${context.parsed.y.toFixed(1)} ${unit}`;
          },
          title: (tooltipItems) => {
            const date = new Date(hashRateData.value[tooltipItems[0].dataIndex][0]);
            return date.toLocaleString();
          }
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: 'rgba(200, 200, 200, 0.1)'
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 8,
          padding: 10,
          font: {
            size: 10
          },
          color: '#666'
        }
      },
      y: {
        min: minValue,
        max: maxValue,
        title: {
          display: true,
          text: `Hashrate (${unit})`
        },
        ticks: {
          callback: (value) => `${value.toFixed(1)} ${unit}`,
          padding: 10,
          font: {
            size: 10
          },
          color: '#666',
          stepSize: Math.ceil((maxValue - minValue) / 5)
        },
        grid: {
          color: 'rgba(200, 200, 200, 0.2)'
        }
      }
    },
    elements: {
      line: {
        tension: 0.3
      },
      point: {
        radius: 4,
        hoverRadius: 7,
        backgroundColor: props.color,
        borderColor: '#fff',
        borderWidth: 1
      }
    },
    layout: {
      padding: {
        left: 15,
        right: 25,
        top: 15,
        bottom: 20 // Increased bottom padding for labels
      }
    }
  };
});

// Computed chart data
const updateChartData = () => {
  // Sort data by timestamp to ensure proper display
  const sortedData = [...hashRateData.value].sort((a, b) => a[0] - b[0]);
  
  // Determine the appropriate hashrate unit
  const { divisor, unit } = determineHashrateUnit();
  
  // Convert hashrate to the appropriate unit for better display
  const convertedData = sortedData.map(item => [
    item[0], 
    item[1] / divisor
  ]);
  
  // Create custom labels for X-axis
  const customLabels = convertedData.map(item => {
    const date = new Date(item[0]);
    if (selectedPeriod.value === '7d') {
      return date.getDate() + '/' + (date.getMonth() + 1);
    } else if (selectedPeriod.value === '24h' || selectedPeriod.value === '6h') {
      return date.getHours().toString().padStart(2, '0') + ':00';
    } else {
      return date.getHours().toString().padStart(2, '0') + ':' + 
             date.getMinutes().toString().padStart(2, '0');
    }
  });
  
  // Update chart data
  chartData.value = {
    labels: customLabels,
    datasets: [
      {
        label: 'Hashrate',
        data: convertedData.map(item => item[1]),
        fill: true,
        backgroundColor: props.secondaryColor,
        borderColor: props.color,
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 7,
        tension: 0.3,
        pointBackgroundColor: props.color,
        pointBorderColor: '#fff',
        pointBorderWidth: 1
      }
    ]
  };
  
  // Use the Chart.js instance directly for smoother updates if available
  nextTick(() => {
    if (chartRef.value && chartRef.value.chartInstance) {
      // Update the chart directly instead of forcing a re-render
      chartRef.value.chartInstance.update('none'); // 'none' mode for smoother transitions
    }
  });
};

// Methods
const fetchData = async () => {
  loading.value = true;
  try {
    const data = await props.dataFetchFunction(selectedPeriod.value);
    if (data && data.length) {
      hashRateData.value = data;
      updateChartData();
    } else {
      // If no data is returned, generate some placeholder data
      if (hashRateData.value.length === 0 || hashRateData.value[0][1] === 0) {
        generatePlaceholderData();
      }
    }
  } catch (error) {
    console.error('Error fetching hashrate data:', error);
    // Handle error by showing a message or using placeholder data
    if (hashRateData.value.length === 0) {
      generatePlaceholderData();
    }
  } finally {
    loading.value = false;
  }
};

// Generate placeholder data when no real data is available
const generatePlaceholderData = () => {
  const now = new Date().getTime();
  const placeholderData = [];
  for (let i = 0; i < 10; i++) {
    placeholderData.push([now - (9 - i) * 60000, Math.random() * 1000]);
  }
  hashRateData.value = placeholderData;
  updateChartData();
};

// Update chart with live data point
const updateWithLiveData = (liveHashrate) => {
  if (hashRateData.value.length > 0) {
    const now = new Date().getTime();
    
    // Add the new data point
    hashRateData.value.push([now, liveHashrate]);
    
    // Remove oldest data point if we have more than 20 points
    if (hashRateData.value.length > 20) {
      hashRateData.value.shift();
    }
    
    // Update the chart
    updateChartData();
  }
};

const changePeriod = (period) => {
  selectedPeriod.value = period;
  fetchData();
};

const startAutoRefresh = () => {
  // Clear any existing interval
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
  
  // Set new interval for full data refresh
  intervalId.value = setInterval(() => {
    fetchData();
  }, props.refreshInterval);
  
  // Set interval for live data updates if liveFetchFunction is provided
  if (props.liveFetchFunction) {
    intervalId.liveUpdate = setInterval(async () => {
      try {
        const liveHashrate = await props.liveFetchFunction();
        if (liveHashrate !== null && liveHashrate !== undefined) {
          updateWithLiveData(liveHashrate);
        }
      } catch (error) {
        console.error('Error fetching live hashrate:', error);
      }
    }, props.refreshInterval); // Use the same interval as the full refresh
  }
};

// Lifecycle hooks
onMounted(() => {
  fetchData();
  startAutoRefresh();
  
  // Force an update of the chart after mounting
  setTimeout(() => {
    updateChartData();
    
    // Try to directly access and update the Chart.js instance
    if (chartRef.value && chartRef.value.chartInstance) {
      const chart = chartRef.value.chartInstance;
      
      // Update Y-axis formatter
      chart.options.scales.y.ticks.callback = formatYAxisTick;
      
      // Update the chart
      chart.update();
    }
  }, 1000);
});

onBeforeUnmount(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
  if (intervalId.liveUpdate) {
    clearInterval(intervalId.liveUpdate);
  }
});

// Watch for changes in initial data
watch(() => props.initialData, (newData) => {
  if (newData && newData.length) {
    hashRateData.value = newData;
    updateChartData();
  }
}, { deep: true });

// Watch for forceUpdate changes
watch(() => props.forceUpdate, () => {
  if (props.forceUpdate) {
    console.log('Force updating chart...');
    fetchData();
  }
});

// Initialize chart data
updateChartData();
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 420px; /* Increased height */
  padding: 20px;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.chart-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  border-radius: 0 0 4px 4px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-title {
  font-size: 1.2rem;
  margin: 0;
  color: #206bc4;
  font-weight: 500;
}

.chart-controls {
  display: flex;
  gap: 5px;
}

.chart-controls .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 4px;
}

.chart-wrapper {
  position: relative;
  width: 100%;
  height: 340px; /* Increased height */
  margin-bottom: 20px; /* Add margin at the bottom for labels */
}

.chart-canvas {
  max-width: 100%;
  height: 340px !important; /* Increased height */
}
</style> 