import axios from 'axios';
import { useEmitter } from '@/composables/useEmitter';
import PoolConfig from "@/config/PoolConfig";
import Pool from '@/services/api/Pool';

const emitter = useEmitter();
const API_BASE_URL = 'https://www.letshash.it';
let liveUpdateInterval = null;
let isLiveMode = false;
let errorLogged = new Set();
let lastUpdated = 0; // Track the timestamp of the last update
let updating = false; // Prevent concurrent updates

export default {
    /**
     * Main method to fetch general pool stats, starting with fallback data,
     * then attempting live updates.
     */
    async poolGeneralStats(callback, finallyCallback) {
        const fetchStats = async () => {
            if (updating) return; // Skip if already updating
            updating = true;

            try {
                console.log('🔄 Fetching stats...');

                // Start with fallback data
                const fallbackStats = await this.getFallbackGeneralStats();
                if (fallbackStats && !isLiveMode) {
                    callback({ ...fallbackStats });
                }

                // Switch to live updates
                this.startLiveUpdates(callback);
            } catch (error) {
                this.logError('poolGeneralStats', error);
            } finally {
                updating = false;
                finallyCallback?.();
                emitter.off('general-stats-event', fetchStats); // Cleanup emitter
            }
        };

        emitter.on('general-stats-event', fetchStats);
        emitter.emit('general-stats-event');
    },

    /**
     * Attempts to fetch live data at regular intervals.
     * Falls back to local data if live data is unavailable.
     */
    startLiveUpdates(callback) {
        if (liveUpdateInterval) clearInterval(liveUpdateInterval);

        const updateLiveData = async () => {
            try {
                const liveStats = await this.getGeneralStats();

                if (liveStats) {
                    if (!isLiveMode) {
                        console.log('🌐 Switched to live data.');
                        isLiveMode = true;
                        errorLogged.clear();
                    }

                    const now = Date.now();
                    if (now > lastUpdated) {
                        lastUpdated = now; // Update the timestamp
                        callback({ ...liveStats }); // Update UI with live stats
                    }
                }
            } catch (error) {
                if (isLiveMode) {
                    console.warn('📡 Live API unavailable. Falling back to local data.');
                    isLiveMode = false;
                }

                const fallbackStats = await this.getFallbackGeneralStats();
                if (fallbackStats && !isLiveMode) {
                    const now = Date.now();
                    if (now > lastUpdated) {
                        lastUpdated = now; // Update the timestamp
                        callback({ ...fallbackStats }); // Update UI with fallback stats
                    }
                }
            }
        };

        console.log('🔄 Attempting live updates...');
        liveUpdateInterval = setInterval(updateLiveData, 60000); // Refresh every 60 seconds
        updateLiveData(); // Fetch live data immediately
    },

    /**
     * Fetches fallback data from local pool configurations.
     */
    async getFallbackGeneralStats() {
        const poolConfig = PoolConfig.getConfig();
        const stats = {};
        let unreachablePools = 0;

        console.log('📊 Fetching fallback stats...');
        await Promise.all(
            Object.entries(poolConfig).map(async ([symbol, config]) => {
                if (config.active) {
                    try {
                        const poolStats = await Pool.getStatsByType(config);
                        if (poolStats) {
                            stats[symbol] = this.formatPoolStats(poolStats, config);
                        }
                    } catch (error) {
                        unreachablePools++;
                        this.logError(`${symbol}-pool`, error);
                    }
                }
            })
        );

        if (unreachablePools > 0) {
            console.warn(`⚠️ ${unreachablePools} pools unreachable.`);
        }

        return Object.keys(stats).length > 0 ? stats : null;
    },

    /**
     * Fetches live data from the API.
     */
    async getGeneralStats() {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/live`, {
                headers: { 'Accept': 'application/json', 'Cache-Control': 'no-cache' },
                timeout: 5000
            });
            return response.data;
        } catch (error) {
            this.logError('api-server', error);
            throw error;
        }
    },

    /**
     * Logs errors and ensures duplicates are not logged repeatedly.
     */
    logError(key, error) {
        if (!errorLogged.has(key)) {
            console.error(`Error (${key}):`, error.message || error);
            errorLogged.add(key);
        }
    },

    /**
     * Formats pool stats into a consistent structure.
     */
    formatPoolStats(poolStats, config) {
        return {
            status: {
                networkHeight: poolStats.network?.height || 0,
                networkDiff: poolStats.network?.difficulty || 0,
                networkHashrate: poolStats.network?.hashrate || 0,
                poolHashrate: poolStats.pool?.hashrate || 0,
                poolHashrateSolo: poolStats.pool?.hashrateSolo || 0,
                miners: poolStats.pool?.miners || 0,
                minersSolo: poolStats.pool?.minersSolo || 0,
                workers: poolStats.pool?.workers || 0,
                workersSolo: poolStats.pool?.workersSolo || 0,
                lastBlockFound: poolStats.pool?.lastBlockFound || 0,
                totalBlocks: poolStats.pool?.totalBlocks || 0,
                totalBlocksSolo: poolStats.pool?.totalBlocksSolo || 0
            },
            prices: {
                btc: poolStats.prices?.btc || 0,
                usd: poolStats.prices?.usd || 0,
                eur: poolStats.prices?.eur || 0
            },
            config: {
                coin: config.name,
                symbol: config.symbol,
                algo: config.algo,
                paymentInterval: config.pool?.payInterval || "N/A",
                minPayout: config.pool?.minPayout || "N/A",
                poolFee: config.pool?.fee || "N/A"
            }
        };
    }
};