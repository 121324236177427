import PoolApi from "@/services/api/Pool";
import Format from '@/utils/format';
import axios from 'axios';
import { useStorage } from '@/composables/useStorage';

const localStorage = useStorage();

export default {
    async getWalletInfo(pool, address) {
        try {
            const url = pool.endpoints.wallet.replace('{address}', address);
            const response = await axios.get(url);
            
            if (response.data.error === undefined) {
                return this.parseWalletInfo(response);
            }
            return { error: 'Not found' };
        } catch (error) {
            console.error('Error in getWalletInfo:', error);
            return { error: 'Failed to fetch wallet info' };
        }
    },

    async getStats(pool) {
        try {
            const url = pool.endpoints.stats;
            let response = await axios.get(url);
            response = await this.parseStats(response);
            response = await this.parsePayments(pool, response);
            response = await this.parseBlocks(pool, response);
            return await this.getTop10(pool, response);
        } catch (error) {
            console.error('Error in getStats:', error);
            throw error;
        }
    },

    parseWalletInfo(response) {
        const stats = {};
        const workers = [];
        const coin = PoolApi.coinConfig();
        const responseObj = response.data;

        stats.lastShare = responseObj.pendingShares;
        stats.balance = `${responseObj.pendingBalance} ${PoolApi.getSelectedCoin()}`;
        stats.paid = `${responseObj.totalPaid} ${PoolApi.getSelectedCoin()}`;

        if (responseObj.workers) {
            Object.values(responseObj.workers).forEach(worker => {
                workers.push({
                    name: worker.name,
                    hashrate: worker.hashrateString,
                    lastShare: worker.currRoundShares,
                });
            });
        }

        stats.workers = workers;
        stats.hashes = workers.length > 0 ? workers[0].hashrate : 0;
        stats.payments = false;

        return stats;
    },

    parseStats(response) {
        const stats = {};
        const coin = PoolApi.coinConfig();
        const selectedCoin = PoolApi.getSelectedCoin().toUpperCase();
        const responseObj = response.data.pools[0];
        const hashrateHistory = [];

        stats.miners = responseObj.poolStats.connectedMiners;
        stats.soloMiners = 0;
        stats.workers = 0;
        stats.soloWorkers = 0;
        stats.poolHashRate = responseObj.poolStats.poolHashrate;
        stats.poolSoloHashRate = 0;
        stats.poolFee = coin.pool.fee;
        stats.poolMinimumPayout = coin.pool.minPayout;
        stats.networkDifficulty = responseObj.networkStats.networkDifficulty;
        stats.blockHeight = responseObj.networkStats.blockHeight;
        stats.blockLastFound = responseObj.networkStats.blockHeight;
        stats.hashRateHistory = hashrateHistory;
        stats.blocks = [];
        stats.payments = [];
        stats.config = responseObj;
        stats.reward = 0;

        localStorage.set("config", responseObj);

        return stats;
    },

    async getTop10(pool, response) {
        if (!pool.endpoints.top10) {
            return response;
        }

        try {
            const url = pool.endpoints.top10;
            const responseTop = await axios.get(url);
            return this.parseTop10(responseTop, response);
        } catch (error) {
            console.error('Error in getTop10:', error);
            return response;
        }
    },

    parseTop10(responseTop, response) {
        const stats = responseTop.data.map(miner => ({
            miner: miner.miner,
            hashrate: Format.getReadableHashRateString(miner.hashrate),
            lastShare: 'N/A',
            hashes: 'N/A'
        }));

        response.top10 = stats;
        return response;
    },

    async parseBlocks(pool, response) {
        try {
            const blockUrl = pool.endpoints.blocks;
            const blocks = await axios.get(blockUrl);
            const coin = PoolApi.coinConfig();
            
            response.blocks = this.parseBlocksString(blocks, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parseBlocks:', error);
            throw error;
        }
    },

    parseBlocksString(blocks, response, coin) {
        const paymentsList = [];
        const responseObj = blocks.data;

        if (responseObj) {
            responseObj.forEach(parts => {
                const createdDate = new Date(parts.created);
                const payment = {
                    time: createdDate.toLocaleString(),
                    hash: parts.hash,
                    height: parts.blockHeight,
                    amount: '',
                    rawtime: createdDate.getTime(),
                    difficulty: parts.networkDifficulty,
                    shares: '',
                    orphaned: '',
                    reward: parts.reward,
                    luck: '',
                    status: parts.status,
                    solo: '',
                    effort: parts.effort ? parts.effort.toFixed(3) : 0,
                    minerAddress: `${parts.miner.substring(0, 20)}...${parts.miner.substring(parts.miner.length - 20)}`
                };

                paymentsList.push(payment);
            });

            paymentsList.sort((a, b) => b.rawtime - a.rawtime);
        }

        return paymentsList;
    },

    async parsePayments(pool, response) {
        try {
            const timestamp = Math.round(Date.now() / 1000);
            const paymentUrl = pool.endpoints.payments.replace('{time}', timestamp);
            const payments = await axios.get(paymentUrl);
            const coin = PoolApi.coinConfig();
            
            response.payments = this.parsePaymentsString(payments, response, coin);
            return response;
        } catch (error) {
            console.error('Error in parsePayments:', error);
            throw error;
        }
    },

    parsePaymentsString(payments, response, coin) {
        const paymentsList = [];
        const responseObj = payments.data;

        if (responseObj) {
            responseObj.forEach((parts, index) => {
                const createdDate = new Date(parts.created);
                const payment = {
                    time: createdDate.toLocaleString(),
                    hash: parts.transactionConfirmationData,
                    amount: `${parts.amount.toLocaleString('fullwide', { useGrouping: false })} ${PoolApi.getSelectedCoin()}`,
                    fee: coin.pool.fee,
                    mixin: '-',
                    recipients: 1,
                    payees: 1,
                    rawtime: createdDate.getTime(),
                    id: index
                };

                paymentsList.push(payment);
            });

            paymentsList.sort((a, b) => b.rawtime - a.rawtime);
        }
        return paymentsList;
    }
};