<template>
  <div class="chart-container">
    <div v-if="loading" class="chart-loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="chart-header">
        <h3 class="chart-title">{{ title }}</h3>
        <div class="chart-controls">
          <button 
            v-for="period in timePeriods" 
            :key="period.value" 
            class="btn btn-sm" 
            :class="selectedPeriod === period.value ? 'btn-primary' : 'btn-outline-secondary'"
            @click="changePeriod(period.value)"
          >
            {{ period.label }}
          </button>
        </div>
      </div>
      <BarChart
        :chart-data="chartData"
        :chart-options="chartOptions"
        :height="300"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import Format from '@/utils/format';

// Register Chart.js components
Chart.register(...registerables);

// Props
const props = defineProps({
  title: {
    type: String,
    default: 'Blocks Found'
  },
  initialData: {
    type: Array,
    default: () => []
  },
  dataFetchFunction: {
    type: Function,
    required: true
  },
  refreshInterval: {
    type: Number,
    default: 60000 // 60 seconds
  },
  primaryColor: {
    type: String,
    default: '#206bc4'
  },
  secondaryColor: {
    type: String,
    default: '#4299e1'
  }
});

// State
const loading = ref(true);
const chartData = ref(null);
const intervalId = ref(null);
const blocksData = ref(props.initialData.length ? props.initialData : []);
const selectedPeriod = ref('24h');

// Time period options
const timePeriods = [
  { label: '24H', value: '24h' },
  { label: '7D', value: '7d' },
  { label: '30D', value: '30d' },
];

// Chart options
const chartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'top'
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y;
          return `${label}: ${value}`;
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      beginAtZero: true,
      ticks: {
        precision: 0
      },
      grid: {
        color: 'rgba(200, 200, 200, 0.1)'
      }
    }
  }
}));

// Computed chart data
const updateChartData = () => {
  if (!blocksData.value || blocksData.value.length === 0) {
    chartData.value = {
      labels: ['No Data'],
      datasets: [{
        label: 'Blocks',
        data: [0],
        backgroundColor: props.primaryColor,
        borderColor: props.primaryColor,
        borderWidth: 1
      }]
    };
    return;
  }

  // Group blocks by day or hour depending on the selected period
  const groupedData = groupBlocksByTime(blocksData.value, selectedPeriod.value);
  
  chartData.value = {
    labels: Object.keys(groupedData),
    datasets: [
      {
        label: 'Confirmed Blocks',
        data: Object.values(groupedData).map(group => group.confirmed),
        backgroundColor: props.primaryColor,
        borderColor: props.primaryColor,
        borderWidth: 1
      },
      {
        label: 'Pending Blocks',
        data: Object.values(groupedData).map(group => group.pending),
        backgroundColor: props.secondaryColor,
        borderColor: props.secondaryColor,
        borderWidth: 1
      }
    ]
  };
};

// Helper function to group blocks by time period
const groupBlocksByTime = (blocks, period) => {
  const result = {};
  const now = new Date();
  
  // Determine format and time range based on period
  let format, timeAgo;
  if (period === '24h') {
    format = (date) => `${date.getHours()}:00`;
    timeAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    
    // Initialize all hours
    for (let i = 0; i < 24; i++) {
      const hour = (now.getHours() - i + 24) % 24;
      const hourStr = `${hour}:00`;
      result[hourStr] = { confirmed: 0, pending: 0 };
    }
  } else if (period === '7d') {
    format = (date) => date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    timeAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    
    // Initialize all days
    for (let i = 0; i < 7; i++) {
      const day = new Date(now.getTime() - i * 24 * 60 * 60 * 1000);
      const dayStr = format(day);
      result[dayStr] = { confirmed: 0, pending: 0 };
    }
  } else if (period === '30d') {
    format = (date) => date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    timeAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    
    // Initialize all days
    for (let i = 0; i < 30; i++) {
      const day = new Date(now.getTime() - i * 24 * 60 * 60 * 1000);
      const dayStr = format(day);
      result[dayStr] = { confirmed: 0, pending: 0 };
    }
  }
  
  // Count blocks in each time period
  blocks.forEach(block => {
    const blockTime = new Date(block.time * 1000);
    if (blockTime >= timeAgo) {
      const timeKey = format(blockTime);
      if (!result[timeKey]) {
        result[timeKey] = { confirmed: 0, pending: 0 };
      }
      
      if (block.confirmed) {
        result[timeKey].confirmed++;
      } else {
        result[timeKey].pending++;
      }
    }
  });
  
  return result;
};

// Methods
const fetchData = async () => {
  loading.value = true;
  try {
    const data = await props.dataFetchFunction(selectedPeriod.value);
    if (data && data.length) {
      blocksData.value = data;
      updateChartData();
    }
  } catch (error) {
    console.error('Error fetching blocks data:', error);
  } finally {
    loading.value = false;
  }
};

const changePeriod = (period) => {
  selectedPeriod.value = period;
  fetchData();
};

const startAutoRefresh = () => {
  // Clear any existing interval
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
  
  // Set new interval
  intervalId.value = setInterval(() => {
    fetchData();
  }, props.refreshInterval);
};

// Lifecycle hooks
onMounted(() => {
  fetchData();
  startAutoRefresh();
});

onBeforeUnmount(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
});

// Watch for changes in initial data
watch(() => props.initialData, (newData) => {
  if (newData && newData.length) {
    blocksData.value = newData;
    updateChartData();
  }
}, { deep: true });

// Initialize chart data
updateChartData();
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 350px;
  padding: 10px;
}

.chart-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.chart-title {
  font-size: 1.2rem;
  margin: 0;
  color: #206bc4;
}

.chart-controls {
  display: flex;
  gap: 5px;
}

.chart-controls .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}
</style> 