<template>
  <page :title="coinConfig.name + ` paper wallet`">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-info" role="alert">
          <center class="font-italic">
            All generation is done client side and no information is sent to the
            server.
          </center>
        </div>
      </div>

      <div class="col-md-12 mb-3 d-block">
        <a class="btn btn-success d-block btn-lg btn-block" @click="generate"
          >generate</a
        >
      </div>

      <div v-if="tbd" class="col-md-12">
        <div class="alert alert-warning" role="alert">
          <center class="font-italic">@tbd - Work in progress.</center>
        </div>
      </div>

      <div :class="wallet.address ? 'show' : 'd-none'" v-if="wallet && !tbd">
        <div class="col-md-12 mb-3">
          <div class="card">
            <div class="card-status-left"></div>
            <div class="card-body">
              <h3 class="card-title">
                Public address
                <small class="text-muted"
                  >(receive {{ coinConfig.name }} to your wallet using your
                  PUBLIC key)</small
                >
              </h3>
              <h2>
                <code class="text-primary">{{ wallet.address }}</code>
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card">
            <div class="card-status-left"></div>
            <div class="card-body">
              <h3 class="card-title">Spend key</h3>
              <code class="address">{{ wallet.spend_key }}</code>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card">
            <div class="card-status-left"></div>
            <div class="card-body">
              <h3 class="card-title">View key</h3>
              <code class="address">{{ wallet.view_key }}</code>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card">
            <div class="card-status-left bg-danger"></div>
            <div class="card-body">
              <h3 class="card-title">
                Mnemonic seed
                <small class="text-danger">
                  (Anybody else who discovers the phrase can steal the
                  {{ coinConfig.name }}, so it must be kept safe like jewels or
                  cash. For example, it must not be typed into any website.)
                </small>
              </h3>
              <code class="address">{{ wallet.mnemoric_words }}</code>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12 card">
        <div class="card-header">
          <h3 class="card-title">Frequently Asked Questions (FAQ)</h3>
        </div>
        <div class="card-body">
          <h2>Why do you need a mnemonic phrase?</h2>
          <p>
            Mnemonic seed (or seed phrase) is necessary to access your wallet
            and the data stored from a new device or in case the first gadget
            you used has been lost, or if you forgot your password to the
            previous wallet. Basically, you type your mnemonic seed on a new
            device and are able to recover the old wallet. One seed is able to
            recover all your addresses which is very convenient. However, it is
            both a useful backup to your storage and potential risk of giving
            full access to your assets if anybody gets ahold of your seed. For
            this very reason, it is important to be extra careful of where and
            how you store the recovery seed because it is the only back-up for
            your non-custodial wallet!
          </p>

          <h2>Mnemonic seed and private keys</h2>
          <p>
            To understand the connection between mnemonic seed and private keys,
            let’s refer to a metaphor of a tree. In this case, the seed is
            similar to the tree roots: it shouldn’t be visible to anyone, and it
            serves as the basis for all further information about your wallet.
            The seed phrase is able to generate private keys for every address
            you have, so you’ll be able to back up all your accounts with one
            seed.
          </p>
          <h2>How to store mnemonic seed and private keys</h2>
          <h3>Good Practice</h3>
          <p>
            Store private keys and seed offline to avoid the risk of hacking,
            information leakage or equipment fault. Stay aware of any devices
            around you that might be recording your actions, like a microphone
            or a camera.
          </p>
          <p>
            Write down the seed phrase with all the words in the right order and
            spelled properly. It goes without saying that you shouldn’t rely on
            your memory as there is only one chance to write it down.
          </p>
          <p>
            Use a paper wallet, it is a piece of paper on which you print
            information on your accounts such as its public and private keys.
            Some people prefer to laminate their paper wallet to make it
            waterproof. However, remember to store your paper wallet in a safe
            place where other people wouldn’t find it, for example, in a vault.
          </p>
          <p>
            Use hardware wallets (similar to a flash drive) designed
            specifically to keep private keys secure. Such wallets generate for
            you public and private keys.
          </p>
          <p>
            If you don’t have any hard wallet at the moment of registration of
            your account, it is also possible to store private keys in a flash
            drive itself, but this option is safe as long as the drive stays in
            your hands. Cases are known in which a flash drive was eaten by a
            dog or hidden somewhere by a child. Also, you can use a specific
            flash drive encrypting the data to enhance the level of security.
          </p>
        </div>
      </div>
    </div>
  </page>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Wallet from "@/utils/wallet"
import PoolConfig from "@/config/PoolConfig"
import PoolApi from "@/services/api/Pool"

// State
const wallet = ref({})
const coinConfig = ref({})
const tbd = ref(false)

// Methods
const generate = () => {
  if (coinConfig.value.unitPlaces > 0 && coinConfig.value.addressPrefix > 0) {
    wallet.value = Wallet.genWallet({
      coinUnitPlaces: coinConfig.value.unitPlaces,
      addressPrefix: coinConfig.value.addressPrefix,
    })
  } else {
    tbd.value = true
  }
}

// Lifecycle
onMounted(() => {
  coinConfig.value = PoolApi.coinConfig()
})
</script>