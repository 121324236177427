/**
 * Google Analytics utility functions
 */

/**
 * Track a page view in Google Analytics
 * @param {string} path - The current page path
 * @param {string} title - The page title
 */
export const trackPageView = (path, title) => {
  if (window.gtag) {
    window.gtag('config', 'G-VJKVS5TM4Y', {
      page_path: path,
      page_title: title
    });
  } else {
    // Silent fail if gtag is not available
  }
};

/**
 * Track an event in Google Analytics
 * @param {string} action - The event action
 * @param {Object} params - Additional event parameters
 */
export const trackEvent = (action, params = {}) => {
  if (window.gtag) {
    window.gtag('event', action, params);
  } else {
    // Silent fail if gtag is not available
  }
}; 