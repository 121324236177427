var CONFIG_LANG = [
    "dutch",
    "electrum",
    "english",
    "esperanto",
    "french",
    "german",
    "italian",
    "japanese",
    "lojban",
    "portuguese",
    "russian",
    "spanish"
];

var zerohex = "0000000000000000000000000000000000000000000000000000000000000000";
var ffhex = "ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export default {
    zerohex: zerohex,
    ffhex: ffhex,
    configLang: CONFIG_LANG,
    genWallet(coinConfig, lang) {
        var current_lang = lang || 'english';
    
        var _seed = cnUtil.sc_reduce32(cnUtil.rand_32());
        var _keys = cnUtil.create_address(_seed);
        var _mnemonic = mn_encode(_seed, current_lang);

        cnUtil = cnUtilGen(coinConfig);
        var address = cnUtil.pubkeys_to_string(_keys.spend.pub, _keys.view.pub);

        var wallet = {
            address: address,
            spend_key: _keys.spend.sec,
            view_key: _keys.view.sec,
            mnemoric_words: _mnemonic
        }
    
        return wallet;
    }
}
