import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import TablerVue from '../../dist/vue-tabler';
import StoragePlugin from 'vue-web-storage';
import VueApexCharts from 'vue3-apexcharts';
import mitt from 'mitt';
import '@/assets/css/tabler-1.0.css'
import '@/assets/css/demo-1.0.css'

const app = createApp(App);
const emitter = mitt();

// Suppress specific Vue warnings
app.config.warnHandler = (message, vm, trace) => {
  if (message.includes('Missing required prop: "icon"')) {
    // Suppress this specific warning
    return;
  }

  // Log all other warnings
  console.warn(message, trace);
};


// Create store
const store = createStore({
  state() {
    return {
      poolData: {}
    }
  },
  mutations: {
    setPoolData(state, data) {
      state.poolData = data
    }
  }
});

// Configure axios
axios.defaults.baseURL = '/';

// Add emitter to globalProperties
app.config.globalProperties.emitter = emitter;

// Better way to handle global utilities in Vue 3
const globals = {
  cnUtil: window.cnUtil,
  mn_encode: window.mn_encode,
  cnUtilGen: window.cnUtilGen,
  mn_swap_endian_4byte: window.mn_swap_endian_4byte,
  mn_get_checksum_index: window.mn_get_checksum_index,
  mn_words: window.mn_words
};

// Provide globals
app.provide('globals', globals);

// Use plugins
app.use(router);
app.use(store);
app.use(TablerVue);
app.use(StoragePlugin, {
  prefix: '_ltsh',
  drivers: ['local', 'session']
});
app.use(VueApexCharts);

// Mount app
app.mount('#app');